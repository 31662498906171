import {Box, Tooltip} from "@material-ui/core";
import accounting from "accounting";
import moment from "moment";
import {colorError, colorWarning} from "../../theme/colors";

const getExpirationDateColor = (date) => {
  const dateToCheck = moment(date);
  const today = moment();
  const warningDate = moment().add(30, "days");
  if (dateToCheck.isBefore(today)) {
    return colorError;
  }
  if (dateToCheck.isBefore(warningDate)) {
    return colorWarning;
  }
};

const COIExpirationDateItem = (props) => {
  const {date, ...rest} = props;
  let tooltip: string | number = moment(date).diff(moment(), "days");
  if (tooltip < 0) {
    tooltip = `Expired ${accounting.formatNumber(Math.abs(tooltip))} days ago`;
  } else {
    tooltip = `Expires in ${accounting.formatNumber(tooltip)} days`;
  }

  return (
    <Tooltip title={tooltip}>
      <span style={{color: getExpirationDateColor(date)}} {...rest}>
        {date}
      </span>
    </Tooltip>
  );
};

export const COIExpirationDateTableCell = ({value, row}) => {
  const expirationDates = row.original.expiration_dates_json;
  return (
    // <Box display="flex">
    //   {expirationDates.map((date) => (
    //     <Box mr={1} key={date}>
    //       <COIExpirationDateItem date={date} />
    //     </Box>
    //   ))}
    // </Box>
    <>
      {expirationDates.map((date) => (
        <Box key={date}>
          <COIExpirationDateItem date={date} />
        </Box>
      ))}
    </>
  );
};
