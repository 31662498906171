import {Box, Typography} from "@material-ui/core";
import {chain} from "lodash";
import {LineSegment, VictoryContainer, VictoryLabel, VictoryPie, VictoryTheme} from "victory";

export const RFPChart = (props: {rfps: any[]; property: string; label: string; [rest: string]: any}) => {
  const {rfps, property, label, ...rest} = props;

  const chartData = chain(rfps)
    .countBy(property)
    .map((count, type) => ({
      x: type,
      y: Number(((count / rfps.length) * 100).toFixed(2)),
    }))
    .value();
  return (
    <>
      <Box textAlign="center">
        <Typography variant="h6">{label}</Typography>
      </Box>
      <VictoryPie
        containerComponent={<VictoryContainer title={label} responsive={true} />}
        theme={VictoryTheme.material}
        // labelIndicator
        data={chartData}
        height={300}
        // labelIndicatorOuterOffset={15}
        // labelIndicatorInnerOffset={25}
        labelComponent={
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            text={({datum}) => [datum.x, `(${datum.y}%)`]}
            style={{fill: "black"}}
            // backgroundStyle={{fill: "rgba(255, 255, 255, 0.5)"}}
          />
        }
        sortKey={(datum) => datum.y}
        // events={[
        //   {
        //     target: "data",
        //     eventHandlers: {
        //       onClick: () => {
        //         return [
        //           {
        //             target: "data",
        //             mutation: ({style}) => {
        //               return style.fill === "#c43a31" ? null : {style: {fill: "#c43a31"}};
        //             },
        //           },
        //           {
        //             target: "labels",
        //             mutation: ({text}) => {
        //               return text === "clicked" ? null : {text: "clicked"};
        //             },
        //           },
        //         ];
        //       },
        //     },
        //   },
        // ]}
        {...rest}
      />
    </>
  );
};
