import {faCheck, faCircleExclamation} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import pluralize from "pluralize";
import FormActions from "../../components/forms/FormActions";
import {MuiNavLink} from "../../components/Links";
import {ReactTableMui} from "../../components/ReactTableMui";
import {TableStatusLabel} from "../../components/Status";
import Typography2 from "../../components/Typography2";
import {makeNovoClasses} from "../../theme";
import {colorError, colorSuccess, colorWarning} from "../../theme/colors";

export const TwilioContactCertificationTable = (props: {
  setFilterOption: (filterLabel: string, contactNumber: string, contactName: string) => void;
  certifications: any;
  getCertificationURL: (certification) => string;
  [rest: string]: any;
}) => {
  const {setFilterOption, certifications, getCertificationURL, ...rest} = props;
  const novoClasses = makeNovoClasses();
  return (
    <ReactTableMui
      size="small"
      className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
      getRowProps={(row) => ({
        // component: Link,
        component: MuiNavLink,
        href: getCertificationURL(row.original),
      })}
      columns={[
        {
          accessor: "twilio_contact.name",
          Cell: ({value, row}) => (
            <TableStatusLabel status={row.original.status_display} hint="twilioContactCertification">
              {value} {row.original.created_by_other && `(${row.original.certification_created_by.name})`}
            </TableStatusLabel>
          ),
        },
        {Header: "Company", accessor: "twilio_contact.company"},
        {Header: "Type", accessor: "certification_type_display"},
        {
          Header: "Audited By",
          accessor: "audited_by.full_name",
        },
        {
          Header: "Issue Date",
          accessor: "issue_date",
          Cell: ({value}) => (value ? moment(value).format("ddd, MMM DD, YYYY") : null),
        },
        {
          Header: "Expiration Date",
          accessor: "expiration_date",
          Cell: ({value}) => (value ? moment(value).format("ddd, MMM DD, YYYY") : null),
        },
        {
          Header: "Days Until Expiration",
          accessor: "days_until_expiration",
          Cell: ({row, value}) =>
            value ? (
              <DaysUntilExpirationLabel daysUntilExpiration={value} expirationWarning={30} icons={false} />
            ) : null,
        },
      ]}
      initialState={{
        sortBy: [
          {
            id: "compliance_date",
            desc: true,
          },
        ],
      }}
      hover
      data={certifications}
      {...rest}
    />
  );
};

const DaysUntilExpirationLabel = (props) => {
  const {daysUntilExpiration, expirationWarning = 30, icons = true, ...rest} = props;

  if (daysUntilExpiration < 1) {
    return (
      <>
        <Typography2 type={"metadata"} style={{color: colorError}} {...rest}>
          {icons && (
            <>
              <FontAwesomeIcon icon={faCircleExclamation} />{" "}
            </>
          )}
          {daysUntilExpiration === 0
            ? "Today"
            : `${Math.abs(daysUntilExpiration)} ${pluralize("Day", Math.abs(daysUntilExpiration))} Ago`}
        </Typography2>
      </>
    );
  }

  if (daysUntilExpiration < expirationWarning) {
    return (
      <>
        <Typography2 type={"metadata"} style={{color: colorWarning}} {...rest}>
          {icons && (
            <>
              <FontAwesomeIcon icon={faCircleExclamation} />
            </>
          )}
          {`${accounting.format(daysUntilExpiration)} ${pluralize("Day", daysUntilExpiration)}`}
        </Typography2>
      </>
    );
  }

  return (
    <>
      <Typography2 type={"metadata"} style={{color: colorSuccess}} {...rest}>
        {icons && (
          <>
            <FontAwesomeIcon icon={faCheck} />
          </>
        )}
        {`${accounting.format(daysUntilExpiration)} ${pluralize("Day", daysUntilExpiration)}`}
      </Typography2>
    </>
  );
};

export const Thumbnail = (props) => {
  const {thumbnail, height = 100, width = 100, onClickThumbnail, ...rest} = props;
  return (
    <Box onClick={onClickThumbnail} style={{cursor: "pointer"}}>
      <Box mb={-1}>
        <img
          src={`data:image/png;base64, ${thumbnail.thumbnail_base64}`}
          height={height}
          width={width}
          style={{objectFit: "contain"}}
        />
      </Box>
      {thumbnail.description && (
        <Typography2 type="metadata" style={{textAlign: "center"}}>
          {thumbnail.description}
        </Typography2>
      )}
    </Box>
  );
};
export const ThumbnailDetailDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  thumbnail: any;
  readOnly?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, thumbnail, readOnly, ...rest} = props;
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle id="form-dialog-title">Attachment Detail</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Link href={thumbnail.download_url} target="_blank">
                <img
                  src={`data:image/png;base64, ${thumbnail.thumbnail_base64}`}
                  style={{maxWidth: "100%", height: "auto", cursor: "pointer"}}
                />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions.CloseButton onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};
