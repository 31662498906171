import {faCalendarArrowDown, faCalendarCirclePlus, faPlusCircle, faUserPen} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {groupBy} from "lodash";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect} from "../../components/Filters";
import {MuiNavLink} from "../../components/Links";
import {Pagination} from "../../components/Pagination";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import Typography2 from "../../components/Typography2";
import officeChoices from "../../components/forms/choices/offices.json";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import usePermissions from "../../hooks/usePermissions";
import {usePostCurrentPage, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {colorSuccess} from "../../theme/colors";
import {BuildReportDialogForm, NoContactScheduleDialogForm, ScheduleDialogForm} from "./LaborScheduleForms";

const LaborSchedule = (props) => {
  const {...rest} = props;
  const [pageSize, setPageSize] = React.useState(500);
  const [page, setPage] = React.useState(1);
  const [defaultOffice, setDefaultOffice] = useLocalStorage("labor-schedule-office", {
    value: "menlo",
    label: "Menlo Park",
  });
  const [createUnassignedOnHoldItemFormIsOpen, setCreateUnassignedOnHoldItemFormIsOpen] = React.useState(false);
  const [updateUnassignedOnHoldItemFormIsOpen, setUpdateUnassignedOnHoldItemFormIsOpen] = React.useState(false);
  const [createScheduleItemFormIsOpen, setCreateScheduleItemFormIsOpen] = React.useState(false);
  const [updateScheduleItemFormIsOpen, setUpdateScheduleItemFormIsOpen] = React.useState(false);
  const [deleteItemConfirmationIsOpen, setDeleteItemConfirmationIsOpen] = React.useState(false);
  const [addScheduleItemsFromDirectoryConfirmationIsOpen, setAddScheduleItemsFromDirectoryConfirmationIsOpen] =
    React.useState(false);
  const [addScheduleItemsForOneContactConfirmationIsOpen, setAddScheduleItemsForOneContactConfirmationIsOpen] =
    React.useState(false);
  const [activeItem, setActiveItem] = React.useState(null);
  const [activeLaborContact, setActiveLaborContact] = React.useState(null);
  const [laborScheduleItemsToFlash, setLaborScheduleItemsToFlash] = React.useState(new Set());
  const [buildReportDialogIsOpen, setBuildReportDialogIsOpen] = React.useState(false);
  // const [shouldBlockOnLoading, setShouldBlockOnLoading] = React.useState(true);
  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const postCurrentPage = usePostCurrentPage();
  const {enqueueSnackbar} = useSnackbar();
  const isReadOnly = !permissions.change_laborscheduleitem;

  // const currentYear = moment().year();

  const flashTimeoutId = React.useRef(null);

  const flashItems = (newItemIds: number[]) => {
    if (flashTimeoutId.current) {
      clearTimeout(flashTimeoutId.current);
    }
    setLaborScheduleItemsToFlash(new Set(newItemIds));
    flashTimeoutId.current = setTimeout(() => {
      setLaborScheduleItemsToFlash(new Set());
    }, 3500);
  };

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Office", "From Date", "To Date", "Hide Unassigned"],
    {
      Office: {value: defaultOffice.value, label: defaultOffice.label},
      "From Date": {value: moment().format("YYYY-MM-DD")},
      // "To Date": {value: moment().year(currentYear).month(11).date(31).format("YYYY-MM-DD")},
    }
  );

  const itemFilterParams = {
    q: filterOptions.Search.value,
    office: filterOptions.Office.value,
    start_date: filterOptions["From Date"].value,
    end_date: filterOptions["To Date"].value,
  };

  const {
    query: teamQuery,
    create: createTeam,
    update: updateTeam,
    delete: deleteTeam,
  } = useSentinelListAPI(
    `labor/schedule/teams/?office=${itemFilterParams.office}&is_active=true&page=1&page_size=10000`,
    {
      initialData: {results: []},
    }
  );

  const teams = teamQuery.data.results;

  const {
    query: itemQuery,
    create: createItem,
    update: updateItem,
    delete: deleteItem,
  } = useSentinelListAPI(
    `labor/schedule/items/?page_size=${pageSize}&page=${page}&${qs.stringify(itemFilterParams)}&is_active=true`,
    {
      initialData: {
        results: [],
      },
    }
  );
  const scheduleItems = itemQuery.data.results;

  const {
    query: contactQuery,
    create: createContact,
    update: updateContact,
    delete: deleteContact,
  } = useSentinelListAPI(
    `labor/schedule/contacts/?items_in_office=${itemFilterParams.office}&page_size=${pageSize}&page=${page}&is_active=true&`, //q=${itemFilterParams.q}
    {
      initialData: {
        results: [],
      },
    }
  );
  const contactsFromQuery = contactQuery.data.results;
  const searchIsFiltered = Boolean(itemFilterParams.q);
  const scheduleItemsByContactId = groupBy(scheduleItems, "contact.id");
  const filteredItemIds = scheduleItems.map((item) => item?.contact?.id).filter((id) => id);

  const contacts = searchIsFiltered
    ? contactsFromQuery.filter((contact) => filteredItemIds.includes(contact.id))
    : contactsFromQuery;

  const displayTeams = [{id: null, name: ""}, ...teams];

  const contactsNotInATeam = contacts.filter(
    (contact) => !contact.team || (itemFilterParams.office && contact.office !== itemFilterParams.office)
  );

  const contactsInATeam = contacts.filter((contact) => contactsNotInATeam.includes(contact) === false);

  const unassignedOnHoldItems = scheduleItems.filter(
    (item) => item.type === "on_hold_unassigned_project" || !item.contact
  );

  const unassignedOnHoldItemsByTeamId = groupBy(unassignedOnHoldItems, "team.id");

  return (
    <>
      <Helmet title="Labor Schedule" />
      <Breadcrumbs>
        <Typography color="textSecondary">Labor</Typography>
        <Typography color="textPrimary">Schedule</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={contactQuery.isFetching || itemQuery.isFetching || teamQuery.isFetching}>
          <PaperPanel.Header.Title>Labor Schedule</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faUserPen} />}
                href={`/v2/labor/contacts/`}
                component={MuiNavLink}
                underline="none"
              >
                {isReadOnly ? "View" : "Manage"} Contacts and Teams
              </PaperPanel.Header.Button>
              <Box mr={1} />
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faCalendarCirclePlus} />}
                onClick={() => setCreateUnassignedOnHoldItemFormIsOpen(true)}
              >
                Add On Hold/Unassigned Project
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButton
                href={`/reports2/labor/schedule/?${qs.stringify(itemFilterParams)}&is_active=true&page_size=10000`}
                target="_blank"
              />
              <Box mr={1} />
              <PaperPanel.Header.BuildReportButton onClick={() => setBuildReportDialogIsOpen(true)} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              {/* <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={() => {
                        setCreateUnassignedOnHoldItemFormIsOpen(true);
                        popupState.close();
                      }}
                    >
                      Add On Hold/Unassigned Project
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu> */}
              <PaperPanel.Header.RefreshButton
                onClick={() => Promise.all([contactQuery.refetch(), itemQuery.refetch(), teamQuery.refetch()])}
                isFetching={contactQuery.isFetching || itemQuery.isFetching || teamQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                label="Office"
                name="office"
                options={officeChoices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  setPage(1);
                  setDefaultOffice({value: value, label: label});
                  setFilterOption("Office", value, label);
                }}
                allowNull={false}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FilterDate
                label="From Date"
                name="From Date"
                value={filterOptions["From Date"].value}
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("From Date", value, value);
                }}
                disableFuture={false}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterDate
                label="To Date"
                name="To Date"
                value={filterOptions["To Date"].value}
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("To Date", value, value);
                }}
                disableFuture={false}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={{
                  Search: filterOptions.Search,
                  Office: filterOptions.Office,
                }}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={contactQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          {contactsNotInATeam.map((contact) => {
            return (
              <ContactItem
                contact={contact}
                // name={contact.contact.full_name}
                onCreate={() => {
                  setActiveLaborContact(contact);
                  setCreateScheduleItemFormIsOpen(true);
                }}
                onRefresh={() => {
                  setActiveLaborContact(contact);
                  setAddScheduleItemsForOneContactConfirmationIsOpen(true);
                }}
                isReadOnly={isReadOnly}
                key={contact.id}
              >
                {scheduleItemsByContactId[contact.id]?.map((item) => {
                  return (
                    <ScheduleItemPaperItem
                      isReadOnly={isReadOnly}
                      key={item.id}
                      item={item}
                      onClickEdit={() => {
                        setActiveItem(item);
                        setActiveLaborContact(contact);
                        setUpdateScheduleItemFormIsOpen(true);
                      }}
                      flash={laborScheduleItemsToFlash.has(item.id)}
                      onClickDelete={() => {
                        setActiveItem(item);
                        setActiveLaborContact(contact);
                        setDeleteItemConfirmationIsOpen(true);
                      }}
                    />
                  );
                })}
              </ContactItem>
            );
          })}

          {displayTeams.map((team) => {
            return (
              <Box key={team.id}>
                {team?.id && (!searchIsFiltered || (searchIsFiltered && contactsInATeam.length > 0)) && (
                  <PaperItem.HeaderLabel label={team.name} bgcolor="grey.400" />
                )}
                {contactsInATeam
                  .filter((contact) => contact.team?.id === team.id)
                  .map((contact) => {
                    return (
                      <ContactItem
                        contact={contact} // name={contact.contact.full_name}
                        onCreate={() => {
                          setActiveLaborContact(contact);
                          setCreateScheduleItemFormIsOpen(true);
                        }}
                        onRefresh={() => {
                          setActiveLaborContact(contact);
                          setAddScheduleItemsForOneContactConfirmationIsOpen(true);
                        }}
                        isReadOnly={isReadOnly}
                        key={contact.id}
                      >
                        {scheduleItemsByContactId[contact.id]?.map((item) => {
                          return (
                            <ScheduleItemPaperItem
                              isReadOnly={isReadOnly}
                              key={item.id}
                              item={item}
                              onClickEdit={() => {
                                setActiveItem(item);
                                setActiveLaborContact(contact);
                                setUpdateScheduleItemFormIsOpen(true);
                              }}
                              flash={laborScheduleItemsToFlash.has(item.id)}
                              onClickDelete={() => {
                                setActiveItem(item);
                                setActiveLaborContact(contact);
                                setDeleteItemConfirmationIsOpen(true);
                              }}
                            />
                          );
                        })}
                      </ContactItem>
                    );
                  })}
                {unassignedOnHoldItemsByTeamId[team.id]?.length > 0 && (
                  <>
                    <PaperItem.HeaderLabel label={`${team.name} Unassigned or On Hold Projects`} labelVariant="h5" />
                    {unassignedOnHoldItemsByTeamId[team.id]?.map((item) => {
                      return (
                        <ScheduleItemPaperItem
                          isReadOnly={isReadOnly}
                          key={item.id}
                          item={item}
                          onClickEdit={() => {
                            setActiveItem(item);
                            setActiveLaborContact(null);
                            setUpdateUnassignedOnHoldItemFormIsOpen(true);
                          }}
                          flash={laborScheduleItemsToFlash.has(item.id)}
                          onClickDelete={() => {
                            setActiveItem(item);
                            setActiveLaborContact(null);
                            setDeleteItemConfirmationIsOpen(true);
                          }}
                        />
                      );
                    })}
                  </>
                )}
              </Box>
            );
          })}
          {unassignedOnHoldItemsByTeamId["undefined"]?.length > 0 && (
            <>
              <PaperItem.HeaderLabel label={`Unassigned or On Hold Projects`} labelVariant="h3" />
              {unassignedOnHoldItemsByTeamId["undefined"]?.map((item) => {
                return (
                  <ScheduleItemPaperItem
                    item={item}
                    key={item.id}
                    isReadOnly={isReadOnly}
                    onClickEdit={() => {
                      setActiveItem(item);
                      setUpdateUnassignedOnHoldItemFormIsOpen(true);
                    }}
                    onClickDelete={() => {
                      setActiveItem(item);
                      setDeleteItemConfirmationIsOpen(true);
                    }}
                    flash={laborScheduleItemsToFlash.has(item.id)}
                  />
                );
              })}
            </>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <ConfirmationDialog
        isOpen={deleteItemConfirmationIsOpen}
        onDeny={() => setDeleteItemConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteItem.mutateAsync(activeItem.id).then(() => {
            setDeleteItemConfirmationIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        title="Delete This Schedule Item?"
      >
        You want to delete this schedule item?{" "}
        {activeItem?.type === "project" && (
          <>
            This will remove the item from the schedule, but not remove {activeLaborContact?.contact?.full_name} from
            the {activeItem?.project?.display} directory.
          </>
        )}
      </ConfirmationDialog>
      <ScheduleDialogForm
        isNew
        isOpen={createScheduleItemFormIsOpen}
        handleClose={() => setCreateScheduleItemFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createItem.mutateAsync(values).then((newItem) => {
            setCreateScheduleItemFormIsOpen(false);
            blockUI.unblockUI();
            flashItems([newItem.id]);
          });
        }}
        office={itemFilterParams.office}
        initialValues={{contact: activeLaborContact, data: {}}}
        contact={activeLaborContact}
      />
      <ScheduleDialogForm
        isOpen={updateScheduleItemFormIsOpen}
        handleClose={() => setUpdateScheduleItemFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateItem.mutateAsync(values).then(() => {
            setUpdateScheduleItemFormIsOpen(false);
            blockUI.unblockUI();
          });
          setUpdateScheduleItemFormIsOpen(false);
        }}
        initialValues={{...activeItem, contact: activeLaborContact}}
        contact={activeLaborContact}
      />
      <NoContactScheduleDialogForm
        isNew
        isOpen={createUnassignedOnHoldItemFormIsOpen}
        handleClose={() => setCreateUnassignedOnHoldItemFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");

          createItem.mutateAsync(values).then((newItem) => {
            setCreateUnassignedOnHoldItemFormIsOpen(false);
            blockUI.unblockUI();
            flashItems([newItem.id]);
          });
        }}
        office={String(itemFilterParams.office)}
        initialValues={{type: "on_hold_unassigned_project", data: {}, office: {id: itemFilterParams.office}}}
      />
      <NoContactScheduleDialogForm
        isOpen={updateUnassignedOnHoldItemFormIsOpen}
        handleClose={() => setUpdateUnassignedOnHoldItemFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");

          updateItem.mutateAsync(values).then(() => {
            setUpdateUnassignedOnHoldItemFormIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        initialValues={{...activeItem}}
        office={String(itemFilterParams.office)}
      />

      <BuildReportDialogForm
        isOpen={buildReportDialogIsOpen}
        handleClose={() => setBuildReportDialogIsOpen(false)}
        initialValues={{
          office: itemFilterParams.office,
          start_date: itemFilterParams.start_date,
          end_date: itemFilterParams.end_date,
        }}
        onSubmit={(values) => {
          const {id: team_id} = values?.team ?? {};
          const {office, start_date, end_date} = values;
          window.open(
            `/reports2/labor/schedule/?page_size=${pageSize}&page=${page}&${qs.stringify({team_id, office})}`,
            "_blank"
          );
          setBuildReportDialogIsOpen(false);
        }}
      />

      <ConfirmationDialog
        isOpen={addScheduleItemsFromDirectoryConfirmationIsOpen}
        onDeny={() => {
          setAddScheduleItemsFromDirectoryConfirmationIsOpen(false);
        }}
        onApprove={() => {
          blockUI.blockUI("Creating...");
          postCurrentPage
            .mutateAsync({action: "create-items-from-directory"})
            .then((response: {new_item_ids: number[]; message: string; office_id: string}) => {
              Promise.all([contactQuery.refetch(), itemQuery.refetch(), teamQuery.refetch()]).then(() => {
                setAddScheduleItemsFromDirectoryConfirmationIsOpen(false);
                blockUI.unblockUI();
                const newItemIds = response?.new_item_ids ?? [];
                // enqueueSnackbar(response.message, {variant: newItemIds.length > 0 ? "success" : "info"});
                flashItems(newItemIds);
              });
            });
        }}
        title="Add Schedule Items From Directory?"
      >
        This will import any missing schedule items for all contacts, including ones that were deleted previously. Are
        you sure you want to do this?
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={addScheduleItemsForOneContactConfirmationIsOpen}
        onDeny={() => {
          setAddScheduleItemsForOneContactConfirmationIsOpen(false);
        }}
        onApprove={() => {
          blockUI.blockUI("Creating...");
          postCurrentPage
            .mutateAsync({action: "create-items-for-labor-contact", labor_contact_id: activeLaborContact.id})
            .then((response: {new_item_ids: number[]; message: string; office_id: string}) => {
              Promise.all([contactQuery.refetch(), itemQuery.refetch(), teamQuery.refetch()]).then(() => {
                setAddScheduleItemsForOneContactConfirmationIsOpen(false);
                blockUI.unblockUI();
                const newItemIds = response?.new_item_ids ?? [];
                // enqueueSnackbar(response.message, {variant: newItemIds.length > 0 ? "success" : "info"});
                flashItems(newItemIds);
              });
            });
        }}
      >
        This will import any missing schedule items for {activeLaborContact?.contact?.full_name.trim()}, including ones
        that were deleted previously. Are you sure you want to do this?
      </ConfirmationDialog>
    </>
  );
};

export default LaborSchedule;

const ContactItem = (props: {
  contact: {
    contact: {
      full_name: string;
    };
    availability_display: string;
  };
  onCreate: () => void;
  onRefresh: () => void;
  isReadOnly: boolean;
  children: React.ReactNode;
  [rest: string]: any;
}) => {
  const {onCreate, isReadOnly, children, onRefresh, contact, ...rest} = props;

  return (
    <>
      <PaperItem bgcolor="grey.100" noHover {...rest}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="h5" component="span">
              {contact.contact.full_name}
            </Typography>{" "}
            <Typography2 type="metadata" component="span">
              {contact.availability_display}
            </Typography2>
          </Grid>
          {!isReadOnly && (
            <Grid item xs={8}>
              <Box textAlign="right">
                <Tooltip title="Get Schedule Items">
                  <IconButton size="small" onClick={onRefresh}>
                    <FontAwesomeIcon icon={faCalendarArrowDown} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Schedule Item">
                  <IconButton size="small" onClick={onCreate}>
                    <FontAwesomeIcon icon={faPlusCircle} color={colorSuccess} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          )}
        </Grid>
      </PaperItem>
      {children}
    </>
  );
};

const ScheduleItemPaperItem = (props: {
  item: any;
  isReadOnly: boolean;
  onClickEdit: () => void;
  onClickDelete: () => void;
  [rest: string]: any;
}) => {
  const {item, onClickEdit, isReadOnly, onClickDelete, ...rest} = props;
  const onHoldUnassigned = item.type === "on_hold_unassigned_project";
  return (
    <PaperItem {...rest}>
      <PaperItem.Body>
        <Grid container>
          <Grid item xs={12}>
            <strong>
              {item.type === "project" || onHoldUnassigned ? (
                <>
                  <MuiNavLink href={`/v2/projects/${item.project.id}/directory/`} target="_blank" underline="always">
                    {item.project.display}
                  </MuiNavLink>
                </>
              ) : (
                <>{item.type_display}</>
              )}
            </strong>
          </Grid>

          <Grid item sm={2} xs={6}>
            <ScheduleItemLabelValue label="Start: " value={item.start_date} />
          </Grid>
          <Grid item sm={2} xs={6}>
            <ScheduleItemLabelValue label="Days: " value={item.days} />
          </Grid>
          <Grid item sm={2} xs={6}>
            <ScheduleItemLabelValue label="Finish: " value={item.end_date} />
          </Grid>
          <Grid item sm={6} xs={6}>
            {item.project && item.project.full_address}
          </Grid>
          <Grid item xs={4}>
            <ScheduleItemLabelValue
              label="Role: "
              value={item.contact_role_display}
              hide={!item?.contact_role_display}
            />
          </Grid>
          <Grid item sm={2} xs={6}>
            <ScheduleItemLabelValue
              label="PX: "
              value={item?.project?.project_executive?.full_name}
              hide={!item.project}
            />
          </Grid>
          <Grid item sm={2} xs={6}>
            <ScheduleItemLabelValue
              label="PM: "
              value={item?.project?.senior_project_manager?.full_name ?? item?.project?.project_manager?.full_name}
              hide={!item.project}
            />
          </Grid>

          <Grid item sm={4} xs={6}>
            <ScheduleItemLabelValue label="Carpenters: " value={item.data?.carpenters} hide={!item.data?.carpenters} />
          </Grid>
          <Grid item xs={12}>
            <ScheduleItemLabelValue label="Notes: " value={item.notes} hide={!item.notes} />
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.RightHover>
        {!isReadOnly && <PaperItem.RightHover.IconButton icon={EditIcon} title="Edit" onClick={onClickEdit} />}
        {!isReadOnly && <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onClickDelete} />}
      </PaperItem.RightHover>
    </PaperItem>
  );
};

const ScheduleItemLabelValue = (props) => {
  const {label, value, hide = false, ...rest} = props;
  return !hide ? (
    <>
      {value !== null && <Typography2 type="metadata">{label}</Typography2>}
      {value}
    </>
  ) : null;
};
