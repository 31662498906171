import qs from "query-string";

const getQueryStringFromIds = (selectedItemIds: Set<any> = new Set(), filterParams?: object) => {
  if (!filterParams) {
    return `&pk=${Array.from(selectedItemIds).join("&pk=")}`;
  }
  if (!selectedItemIds.size) {
    return `&${qs.stringify(filterParams)}`;
  } else {
    return `&pk=${Array.from(selectedItemIds).join("&pk=")}&${qs.stringify(filterParams)}`;
  }
};

export default getQueryStringFromIds;
