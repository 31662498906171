import {faClipboardCheck} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, FormControlLabel, Grid, Link, Switch} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {VictoryAxis, VictoryChart, VictoryContainer, VictoryLine} from "victory";
import {FilterDate} from "../../components/Filters";
import {PageHeader} from "../../components/PageHeader";
import {PageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";

const CheckInDashboard = (props) => {
  const [useProjectTimezone, setUseProjectTimezone] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(100);
  const [date, setDate] = useQueryState("date", moment().format("YYYY-MM-DD"));
  const novoClasses = makeNovoClasses();
  const refetchInterval = 1000 * 60 * 10;
  const {query: checkInsForDateQuery} = useSentinelListAPI(`check-ins/stats/for-date/?compliance_date=${date}`, {
    initialData: [],
    refetchOnWindowFocus: true,
    // refetchInterval: refetchInterval,
  });
  const {query: checkInsByDateQuery} = useSentinelListAPI(
    `check-ins/stats/by-date/?year=${moment(date).format("YYYY")}&month=${moment(date).format("MM")}`,
    {
      initialData: [],
      refetchOnWindowFocus: true,
      // refetchInterval: refetchInterval,
    }
  );
  const {query: checkInsByProjectQuery} = useSentinelListAPI(`check-ins/stats/by-project/?compliance_date=${date}`, {
    initialData: [],
    refetchOnWindowFocus: true,
    // refetchInterval: refetchInterval,
  });
  const {query: recentCheckInsQuery} = useSentinelListAPI(
    `twilioapp/wellness-check-results/?compliance_date=${date}&page_size=${pageSize}`,
    {
      initialData: {results: []},
      refetchOnWindowFocus: pageSize <= 100,
      // refetchInterval: refetchInterval,
    }
  );

  const byTimeCount = checkInsForDateQuery.data.reduce((accumulator, currentValue) => {
    const momentCreated = moment(currentValue.created).endOf("hour");
    const dateKey = `${momentCreated.format("hh:mm")}`;
    const count = accumulator[dateKey] || 0;
    accumulator[dateKey] = count + 1;
    return accumulator;
  }, {});
  const chartData = Object.entries(byTimeCount).map(([key, value]) => {
    return {
      x: key,
      y: value,
      label: `${key}\n${value}`,
    };
  });

  // const {enqueueSnackbar} = useSnackbar();
  return (
    <>
      <Helmet title="Check In Dashboard" />
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Check In Dashboard</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      {/* <pre>{JSON.stringify(chartData, null, 2)}</pre> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FilterDate
            label="Date"
            value={date}
            required
            onChange={(date) => {
              setDate(date);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PaperPanel>
            <PaperPanel.Header isLoading={checkInsForDateQuery.isFetching}>
              <PaperPanel.Header.Title>
                {date}
                {/* {`${date} (${accounting.formatNumber(selectedDateCount)})`} */}
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => checkInsForDateQuery.refetch()}
                    isFetching={checkInsForDateQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <VictoryChart padding={{top: 15, bottom: 20, left: 30, right: 20}} height={100}>
                <VictoryAxis tickCount={5} style={{tickLabels: {fontSize: 4}}} />
                <VictoryAxis style={{tickLabels: {fontSize: 5}}} dependentAxis />
                <VictoryLine
                  height={5000}
                  data={chartData}
                  style={{data: {stroke: "#FFBF3F", strokeWidth: 1}, labels: {fontSize: 5, padding: 3}}}
                  containerComponent={<VictoryContainer responsive={true} />}
                />
              </VictoryChart>
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PaperPanel>
            <PaperPanel.Header isLoading={checkInsByDateQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faClipboardCheck} /> By Date {date}
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => checkInsByDateQuery.refetch()}
                    isFetching={checkInsByDateQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body maxHeight={250} overflow="auto">
              {/* <pre>{JSON.stringify(checkInsByDateQuery, null, 2)}</pre> */}
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                columns={[
                  {
                    Header: "Date",
                    accessor: "compliance_date",
                    Cell: ({value}) => moment(value).format("ddd, MMM DD, YYYY"),
                  },
                  {
                    Header: "Manual",
                    accessor: "manual_count",
                    Cell: ({value}) => accounting.formatNumber(value),
                  },
                  {
                    Header: "SMS",
                    accessor: "sms_count",
                    Cell: ({value}) => accounting.formatNumber(value),
                  },
                  {
                    Header: "Total",
                    accessor: "count",
                    Cell: ({value}) => accounting.formatNumber(value),
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "compliance_date",
                      desc: true,
                    },
                  ],
                }}
                data={checkInsByDateQuery.data}
              />
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PaperPanel>
            <PaperPanel.Header isLoading={checkInsByProjectQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faClipboardCheck} /> By Project {date}
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => checkInsByProjectQuery.refetch()}
                    isFetching={checkInsByProjectQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body maxHeight={250} overflow="auto">
              {/* <pre>{JSON.stringify(checkInsByProjectQuery, null, 2)}</pre> */}
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                columns={[
                  {
                    Header: "Project",
                    accessor: "project_code",
                    Cell: ({row}) => {
                      const stat = row.original;
                      return (
                        <Link href={`/v2/projects/${stat.project_id}/check-ins/#date=${date}`} underline="always">
                          {stat.project_code && <>{stat.project_code} - </>}
                          {stat.project_name}
                        </Link>
                      );
                    },
                  },
                  {
                    Header: "Manual",
                    accessor: "manual_count",
                    Cell: ({value}) => accounting.formatNumber(value),
                  },
                  {
                    Header: "SMS",
                    accessor: "sms_count",
                    Cell: ({value}) => accounting.formatNumber(value),
                  },
                  {
                    Header: "Total",
                    accessor: "count",
                    Cell: ({value}) => accounting.formatNumber(value),
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "project_code",
                      desc: true,
                    },
                  ],
                }}
                data={checkInsByProjectQuery.data}
              />
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
        <Grid item xs={12}>
          <PaperPanel>
            <PaperPanel.Header isLoading={recentCheckInsQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faClipboardCheck} /> Recent Check Ins{" "}
                {recentCheckInsQuery.data?.count &&
                  `(${recentCheckInsQuery.data.results.length} of ${accounting.formatNumber(
                    recentCheckInsQuery.data?.count
                  )})`}
              </PaperPanel.Header.Title>

              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <FormControlLabel
                    control={
                      <Switch checked={useProjectTimezone} onChange={() => setUseProjectTimezone((prev) => !prev)} />
                    }
                    label="Use Project Timezone"
                  />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PageSize
                    pageSize={pageSize}
                    pageSizes={[100, 250, 500, 1000]}
                    onChange={(pageSize) => setPageSize(pageSize)}
                  />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => recentCheckInsQuery.refetch()}
                    isFetching={recentCheckInsQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                columns={React.useMemo(
                  () => [
                    {
                      Header: "Created",
                      accessor: "created",
                      Cell: ({value, row}) => (
                        <span style={{whiteSpace: "nowrap"}}>
                          {useProjectTimezone
                            ? moment.tz(value, row.original.project.timezone).format("YYYY-MM-DD hh:mm A z")
                            : moment.tz(value, moment.tz.guess()).format("YYYY-MM-DD hh:mm A z")}
                        </span>
                      ),
                    },
                    {
                      Header: "Project",
                      accessor: "project.display",
                      style: {whiteSpace: "hidden"},
                      Cell: ({value, row}) => (
                        <Box whiteSpace="nowrap" overflow="hidden" maxWidth={275} textOverflow="ellipsis">
                          <Link
                            href={`/v2/projects/${row.original.project_id}/check-ins/#date=${moment(
                              row.original.created
                            ).format("YYYY-MM-DD")}`}
                            underline="always"
                          >
                            {value}
                          </Link>
                        </Box>
                      ),
                    },
                    {
                      Header: "Name",
                      accessor: "twilio_contact.name",
                    },
                    {
                      Header: "Company",
                      accessor: "twilio_contact.company",
                    },
                    // {
                    //   Header: "Vax Status",
                    //   // accessor: "covid19_vaccine_status_verified_by.uuid",
                    //   disableSortBy: true,
                    //   Cell: ({row, value}) => {
                    //     return (
                    //       <>
                    //         {row.original?.twilio_contact?.covid19_vaccine_status ? (
                    //           <Tooltip title={row.original?.twilio_contact?.covid19_vaccine_status_display}>
                    //             <span>
                    //               <FontAwesomeIcon
                    //                 icon={faShieldVirus}
                    //                 color={
                    //                   row.original?.twilio_contact?.covid19_vaccine_status === "fully_vaccinated"
                    //                     ? colorSuccess
                    //                     : colorError
                    //                 }
                    //               />
                    //             </span>
                    //           </Tooltip>
                    //         ) : (
                    //           <Tooltip title="Vaccination Status Unknown">
                    //             <span>
                    //               <FontAwesomeIcon icon={faShieldVirus} color={colorMutedText} />
                    //             </span>
                    //           </Tooltip>
                    //         )}{" "}
                    //         <Tooltip
                    //           title={
                    //             row.original?.twilio_contact?.covid19_vaccine_status_is_verified
                    //               ? `Verified by ${row.original?.twilio_contact?.covid19_vaccine_status_verified_by?.full_name} on ${row.original?.twilio_contact?.covid19_vaccine_status_verified_date}`
                    //               : `Not Verified`
                    //           }
                    //         >
                    //           <span>
                    //             <FontAwesomeIcon
                    //               icon={faShieldVirus}
                    //               color={
                    //                 row.original?.twilio_contact?.covid19_vaccine_status_is_verified
                    //                   ? colorInfo
                    //                   : colorMutedText
                    //               }
                    //             />
                    //           </span>
                    //         </Tooltip>
                    //       </>
                    //     );
                    //   },
                    // },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                  ],
                  [recentCheckInsQuery.dataUpdatedAt, useProjectTimezone]
                )}
                initialState={{
                  sortBy: [
                    {
                      id: "created",
                      desc: true,
                    },
                  ],
                }}
                data={recentCheckInsQuery.data.results}
              />
            </PaperPanel.Body>
          </PaperPanel>
        </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(recentCheckInsQuery, null, 2)}</pre> */}
    </>
  );
};

export default CheckInDashboard;
