import {faBullhorn} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Helmet} from "react-helmet";
import {PageHeader} from "../../components/PageHeader";
import RFPs from "./RFPs/RFPs";

const MarketingDashboard = (props) => {
  const {...rest} = props;
  return (
    <>
      <Helmet title="Marketing" />
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faBullhorn} /> Marketing Dashboard
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      {/* <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">Marketing Dashboard</Typography>
      </Breadcrumbs> */}
      <RFPs />
    </>
  );
};

export default MarketingDashboard;
