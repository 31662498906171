import {faExclamationCircle, faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PaperPanel from "../../../components/PaperPanel";
import {colorError, colorWarning} from "../../../theme/colors";
import {Tooltip} from "@material-ui/core";

const getCOIButtonIcon = (coi) => {
  if (!coi) {
    return <FontAwesomeIcon icon={faPaperclip} fixedWidth />;
  }
  if (coi?.has_expired_policies) {
    return <FontAwesomeIcon icon={faExclamationCircle} color={colorError} fixedWidth />;
  }
  if (coi?.has_policies_expiring_soon) {
    return <FontAwesomeIcon icon={faExclamationCircle} color={colorWarning} fixedWidth />;
  }
  return <FontAwesomeIcon icon={faPaperclip} fixedWidth />;
};
const getCOIButtonTooltip = (coi) => {
  if (!coi) {
    return "";
  }
  if (coi?.all_policies_expired) {
    return "All policies have expired";
  }
  if (coi?.has_expired_policies) {
    return "Some policies have expired";
  }
  if (coi?.has_policies_expiring_soon) {
    return "Some policies are expiring soon";
  }
  return "";
};

export const COIButton = (props) => {
  const {coi, ...rest} = props;
  return (
    <Tooltip title={getCOIButtonTooltip(coi)}>
      <span>
        <PaperPanel.Header.Button href={coi?.url} disabled={!coi}>
          {getCOIButtonIcon(coi)}
          COI
        </PaperPanel.Header.Button>
      </span>
    </Tooltip>
  );
};
