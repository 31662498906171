import {Cell, CellTemplate, Compatible, getCellProperty, Uncertain} from "@silevis/reactgrid";
import {StatusIcon} from "../Icons";

export interface StatusCell extends Cell {
  statusType: "submittal" | "rfp";
  status: string;
}

export class StatusCellTemplate implements CellTemplate<StatusCell> {
  getCompatibleCell(uncertainCell: Uncertain<StatusCell>): Compatible<StatusCell> {
    const text = getCellProperty(uncertainCell, "status", "string");
    const type = getCellProperty(uncertainCell, "statusType", "string") as StatusCell["statusType"];
    const value = parseFloat(text);
    return {
      ...uncertainCell,
      text,
      statusType: type,
      value,
      style: {
        paddingLeft: "20px",
      },
    };
  }
  render(
    cell: Compatible<StatusCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<StatusCell>, commit: boolean) => void
  ): React.ReactNode {
    return <StatusIcon status={cell.status} type={cell.statusType} showTooltip />;
  }
}
