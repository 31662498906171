import {faLink, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import {isMobile} from "react-device-detect";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {CameraCaptureButton, YesNoNAButtons, UploadFileButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import {AutoSubmitSection} from "../../../components/forms/AutoSubmitSection";
import severityChoices from "../../../components/forms/choices/safetyReviewSeverityChoices.json";
import {
  CheckboxMui,
  DatePickerMui,
  DateTimePickerMui,
  NumberFieldMui,
  ProjectCompanyAutoselectMui,
  SimpleSelect,
  TextFieldMui,
  UserAutoselectMui,
  YesNoNARadios,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import BlockUI from "../../../components/GlobalLoaders";
import {LabelValue} from "../../../components/LabelValue";
import {MuiNavLink} from "../../../components/Links";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {colorNovo} from "../../../theme/colors";
import {SectionHeader} from "../ProjectReviews/ProjectReviewForms";
import {QuestionDetailReadOnly, Thumbnail, ThumbnailDetailDialog} from "./SafetyReviewComponents";

const MOMENTFORMAT = "YYYY-MM-DD h:mm a";

const saveAttachments = async (file, projectId, type, question) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  const response = await axiosAPI.post(
    `/projects/${projectId}/safety/project-safety-reviews/${question.safety_review}/questions/${question.id}/attachments/upload/`,
    formData
  );
  return response;
};

export const AllSafetyReviewForms = (props) => {
  const {
    questions,
    onReviewSubmit,
    onQuestionSubmit,
    projectId,
    review,
    initialFormValues,
    safetyReview,
    readOnly,
    superintendent,
    setSectionErrors,
    attachmentsWithThumbnails,
    onAttachmentSubmit,
    onDeleteAttachment,
    refreshAttachments,
    refreshQuestions,
    // setSafetyScores,
    ...rest
  } = props;
  const [activeAttachment, setActiveAttachment] = React.useState(undefined as {thumbnail?: {}});
  // const [activeThumbnail, setActiveThumbnail] = React.useState(undefined as {});
  const [thumbnailDetailIsOpen, setThumbnailDetailIsOpen] = React.useState(false);
  const [deleteAttachmentConfirmationIsOpen, setDeleteAttachmentConfirmationIsOpen] = React.useState(false);
  const blockUI = useBlockUI();

  const permissions = usePermissions();
  return (
    <>
      <SafetyReviewForm
        questions={questions}
        onSubmit={onReviewSubmit}
        projectId={projectId}
        initialValues={initialFormValues}
        review={safetyReview}
        readOnly={readOnly || safetyReview.is_closed}
        setSectionErrors={setSectionErrors}
      />
      {Object.keys(questions).map((category) => {
        return (
          <React.Fragment key={category}>
            <SectionHeader isLoading={false} mx={2} bgcolor={colorNovo} borderColor={colorNovo}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Box>
                  <Typography variant="h3">{category}</Typography>
                </Box>
              </Box>
            </SectionHeader>
            {questions[category] &&
              questions[category].map((question) => {
                const attachmentsForQuestion = attachmentsWithThumbnails[question.id] || [];
                const questionAttachments = attachmentsForQuestion.filter(
                  (attachment) => attachment.safety_review_question === question.id && attachment.type === "question"
                );

                const answerAttachments = attachmentsForQuestion.filter(
                  (attachment) => attachment.safety_review_question === question.id && attachment.type === "action"
                );

                return (
                  <React.Fragment key={question.id}>
                    <Box mt={1} mb={2}>
                      {!permissions.can_change_safety_reviews ||
                      safetyReview.is_closed ||
                      question.status === "noncompliant_corrected" ? (
                        <QuestionDetailReadOnly
                          question={question}
                          questionAttachments={questionAttachments}
                          answerAttachments={answerAttachments}
                          refreshAttachments={refreshAttachments}
                          refreshQuestions={refreshQuestions}
                          onClickThumbnail={(attachment) => {
                            setActiveAttachment(attachment);
                            // setActiveThumbnail(attachment.thumbnail);
                            setThumbnailDetailIsOpen(true);
                          }}
                          detailLink={`${safetyReview.url}questions/${question.id}/`}
                          // detailLink={`/v2/projects/${projectId}/safety/project-safety-reviews/${safetyReview.id}/questions/${question.id}/`}
                          review={safetyReview}
                        />
                      ) : (
                        <>
                          <SafetyReviewQuestionForm
                            question={question}
                            onSubmit={onQuestionSubmit}
                            initialValues={{
                              ...question,
                              assigned_to: question?.assigned_to ?? superintendent,
                              due_date: question?.due_date ?? moment().add(3, "days").format("YYYY-MM-DD"),
                            }}
                            review={safetyReview}
                            projectId={projectId}
                            refreshAttachments={refreshAttachments}
                            readOnly={readOnly}
                            setSectionErrors={setSectionErrors}
                          />
                          <Grid container spacing={2}>
                            {questionAttachments.length > 0 && (
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  {pluralize("Attachment", questionAttachments.length)}:
                                </Typography>
                              </Grid>
                            )}
                            {questionAttachments.map((attachment) => {
                              const {thumbnail} = attachment;
                              return (
                                thumbnail && (
                                  <Grid item key={attachment.id}>
                                    <Thumbnail
                                      thumbnail={thumbnail}
                                      attachment={attachment}
                                      onClickThumbnail={(event) => {
                                        setActiveAttachment(attachment);
                                        setThumbnailDetailIsOpen(true);
                                      }}
                                    />
                                  </Grid>
                                )
                              );
                            })}
                          </Grid>
                        </>
                      )}
                    </Box>

                    {/* <Divider /> */}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
      <ThumbnailDetailDialog
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          onAttachmentSubmit(values).then(() => {
            blockUI.unblockUI();
            setThumbnailDetailIsOpen(false);
          });
        }}
        onDelete={() => {
          setDeleteAttachmentConfirmationIsOpen(true);
        }}
        thumbnail={activeAttachment?.thumbnail ?? {}}
        isOpen={thumbnailDetailIsOpen}
        handleClose={() => setThumbnailDetailIsOpen(false)}
        initialValues={activeAttachment}
        readOnly={readOnly || safetyReview.is_closed}
      />
      <ConfirmationDialog
        isOpen={deleteAttachmentConfirmationIsOpen}
        onDeny={() => setDeleteAttachmentConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setDeleteAttachmentConfirmationIsOpen(false);
          setThumbnailDetailIsOpen(false);
          onDeleteAttachment(activeAttachment).then(() => blockUI.unblockUI());
        }}
        title="Delete Attachment?"
      >
        Are you sure you want to delete this attachment?
      </ConfirmationDialog>
    </>
  );
};

export const SafetyReviewQuestionForm = (props) => {
  const {
    question,
    onSubmit,
    projectId,
    review,
    refreshAttachments,
    readOnly,
    setSectionErrors,
    // setSafetyScores,
    ...rest
  } = props;
  const blockUI = useBlockUI();

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, hasValidationErrors, dirty} = props;

        React.useEffect(() => {
          if (hasValidationErrors) {
            setSectionErrors({type: "addSection", data: `${question.question}`});
          } else {
            setSectionErrors({type: "removeSection", data: `${question.question}`});
          }
        }, [hasValidationErrors]);

        const validateDatePickerSubmission = (form) => {
          const {hasValidationErrors} = form.getState();
          if (!hasValidationErrors) return form.submit();
        };

        return (
          <Box pb={2}>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <AutoSubmitSection
                readOnly={readOnly}
                form={form}
                title={
                  <>
                    <StatusLabel status={question.status_display} width={120} />
                    <Typography variant="h6" component="span">
                      {question.question}
                    </Typography>
                    <Box ml={1}>
                      {question.regulations.length > 0 && `${pluralize("Regulation", question.regulations.length)}:`}
                      {question.regulations.map((regulation) => (
                        <Box key={regulation.regulation} component="span" mx={1}>
                          <Link href={regulation?.link} underline="always" target="_blank">
                            {regulation.regulation}
                          </Link>
                        </Box>
                      ))}
                    </Box>
                  </>
                }
              >
                <AutoSubmitSection.Action>
                  <MuiNavLink
                    href={`/v2/projects/${projectId}/safety/project-safety-reviews/${review.id}/questions/${question.id}/`}
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </MuiNavLink>
                </AutoSubmitSection.Action>
              </AutoSubmitSection>

              <Grid container spacing={2} key={question.question}>
                <Grid item xs={12} sm={5}>
                  <YesNoNAButtons
                    name="answer"
                    disabled={readOnly}
                    required
                    nonCompliantAnswer="no"
                    yesLabel="Compliant"
                    noLabel="Not Compliant"
                    fullWidth={isMobile}
                    onChange={(event, value) => {
                      form.batch(() => {
                        form.change("answer", value);
                        if (value === "no") {
                          form.change("remediated_datetime", null);
                        } else {
                          form.change("severity", "");
                          form.change("assigned_to", "");
                        }
                      });
                      form.submit();
                    }}
                  />
                  {/* <YesNoNARadios
                    name={`answer`}
                    label="Was this in compliance during the review?"
                    onChange={(event, value) => {
                      form.batch(() => {
                        form.change("answer", value);
                        if (value === "no") {
                          form.change("remediated_datetime", null);
                        } else {
                          form.change("severity", "");
                          form.change("assigned_to", "");
                        }
                      });

                      form.submit();
                    }}
                    required
                    disabled={readOnly}
                    autoFocus
                  /> */}
                </Grid>

                {values.answer === "no" ? (
                  <>
                    <Grid item xs={12} sm={3}>
                      <CheckboxMui
                        name="corrected"
                        label="Corrected during review"
                        onChange={(event, value) => {
                          if (!value) {
                            form.change("remediated_datetime", undefined);
                          }
                          form.submit();
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DateTimePickerMui
                        name="remediated_datetime"
                        label="Date/Time Corrected"
                        onClose={() => validateDatePickerSubmission(form)}
                        required={values.corrected}
                        disabled={!values.corrected}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <SimpleSelect
                        options={severityChoices}
                        name="severity"
                        label="Severity"
                        disabled={readOnly}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {/* <CheckboxMui name="is_repeat" label="Repeat" disabled={readOnly} /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectCompanyAutoselectMui
                        projectId={projectId}
                        name="responsible_company"
                        label="Company Responsible"
                        disabled={readOnly}
                        required
                      />
                    </Grid>
                    {values.corrected === false && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <UserAutoselectMui name="assigned_to" label="Assigned To" disabled={readOnly} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePickerMui
                            name="due_date"
                            label="Due Date"
                            onClose={() => validateDatePickerSubmission(form)}
                            disabled={readOnly}
                            required
                          />
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={4}>
                      <ProjectCompanyAutoselectMui
                        projectId={projectId}
                        name="responsible_company"
                        label="Company Responsible"
                        disabled={readOnly}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextFieldMui
                    name="comment"
                    label="Comment"
                    // onBlur={submitOnBlur}
                    disabled={readOnly}
                    multiline
                    // required
                  />
                </Grid>
                <Grid item xs={12}>
                  {!readOnly && (
                    <UploadFileButton
                      startIcon={<FontAwesomeIcon icon={faUpload} />}
                      name="file"
                      accept="image/jpeg,image/jpg,image/heic,image/png"
                      onUpload={(file) => {
                        blockUI.blockUI("Uploading");
                        let formData = new FormData();
                        formData.append("file", file);
                        blockUI.blockUI("Uploading");
                        saveAttachments(file, projectId, "question", question).then(() =>
                          refreshAttachments().then(() => blockUI.unblockUI())
                        );
                      }}
                      fullWidth={isMobile}
                    >
                      Upload Attachment
                    </UploadFileButton>
                  )}
                </Grid>
                {isMobile && (
                  <Grid item xs={12}>
                    <CameraCaptureButton
                      name="camera"
                      uploadURL={`/projects/${projectId}/safety/project-safety-reviews/${question.safety_review}/questions/${question.id}/attachments/upload/`}
                      additionalFormData={{type: "question"}}
                      beforeUpload={() => blockUI.blockUI("Uploading")}
                      afterUpload={() => refreshAttachments().then(() => blockUI.unblockUI())}
                      fullWidth={isMobile}
                    />
                  </Grid>
                )}
              </Grid>
              <BeforeUnload
                block={!pristine && hasValidationErrors}
                message={`${question.question} has errors preventing saving. Do you wish to close? (changes will be lost)`}
              />

              <ActiveFieldsFormSpy
                onFocusChange={({active}) => {
                  const ignoreFields = ["answer", "severity"];
                  if (active === undefined && !pristine && !hasValidationErrors && !ignoreFields.includes(active)) {
                    handleSubmit();
                  }
                }}
              />
            </form>
          </Box>
        );
      }}
    </FinalForm>
  );
};
const SafetyReviewForm = (props) => {
  const {questions, onSubmitFiles, onSubmit, projectId, review, readOnly, setSectionErrors, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, hasValidationErrors} = props;

        React.useEffect(() => {
          if (hasValidationErrors) {
            setSectionErrors({type: "addSection", data: "general"});
          } else {
            setSectionErrors({type: "removeSection", data: "general"});
          }
        }, [hasValidationErrors]);

        const validateDatePickerSubmission = () => {
          const {hasValidationErrors} = form.getState();
          if (!hasValidationErrors && !pristine) return form.submit();
        };

        return (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <UserAutoselectMui name="created_by" label="Created By" disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePickerMui
                    name="review_datetime"
                    label="Review Time"
                    onClose={validateDatePickerSubmission}
                    // onBlur={submitOnBlur}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UserAutoselectMui
                    name="review_conducted_with"
                    label="Review Conducted With"
                    disabled={readOnly}
                    helperText="Assign to yourself if no one else is present"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <NumberFieldMui
                    name="novo_personnel_onsite_count"
                    label="Novo Personnel Onsite"
                    // onBlur={submitOnBlur}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldMui
                    name="comments"
                    label="Overall Comments"
                    multiline
                    minRows={3}
                    // onBlur={submitOnBlur}
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
              <Box mb={1} />
              <ActiveFieldsFormSpy
                onFocusChange={({active}) => {
                  const ignoreFields = [""];
                  if (active === undefined && !pristine && !ignoreFields.includes(active)) {
                    handleSubmit();
                  }
                }}
              />
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const CreateSafetyReviewDialogForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      isOpen: boolean;
      handleClose: () => void;
      // All other props
      [rest: string]: any;
    },
    ref: React.RefObject<HTMLFormElement>
  ) => {
    const {onSubmit, isOpen, handleClose, ...rest} = props;
    return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <>
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                  <DialogTitle id="form-dialog-title">Create New Safety Review</DialogTitle>
                  <DialogContent>
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <DateTimePickerMui name="review_datetime" label="Safety Review Date and Time" required />
                        </Grid>
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton disabled={submitting} />
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              </>
            );
          }}
        </FinalForm>
      </Dialog>
    );
  }
);

export const RemediationDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  projectId: number;
  activeQuestion: any;
  questionAttachments?: any;
  answerAttachments?: any;
  refreshAttachments?: () => Promise<[any, any]>;
  // All other props
  [rest: string]: any;
}) => {
  const {
    onSubmit,
    isOpen,
    handleClose,
    readOnly,
    projectId,
    activeQuestion,

    ...rest
  } = props;
  const blockUI = useBlockUI();
  const [deleteAttachmentConfirmationIsOpen, setDeleteAttachmentConfirmationIsOpen] = React.useState(false);
  const [thumbnailDetailIsOpen, setThumbnailDetailIsOpen] = React.useState(false);
  const [activeAttachment, setActiveAttachment] = React.useState(undefined as {});

  const refreshAttachments = () => {
    if (props.refreshAttachments) {
      return props.refreshAttachments();
    }
    return Promise.all([attachmentQuery.refetch(), thumbnailQuery.refetch()]);
  };

  const shouldQuery =
    isOpen &&
    activeQuestion?.id &&
    Boolean(props.questionAttachments === undefined && props.answerAttachments === undefined);

  const {
    query: attachmentQuery,
    delete: deleteAttachment,
    update: updateAttachment,
  } = useSentinelListAPI(
    `projects/${projectId}/safety/project-safety-reviews/${activeQuestion?.safety_review}/questions/${activeQuestion?.id}/attachments/`,
    {
      initialData: {results: []},
      enabled: shouldQuery,
    }
  );

  const attachments = attachmentQuery.data.results;

  const {query: thumbnailQuery} = useSentinelListAPI(
    `projects/${projectId}/safety/project-safety-reviews/${activeQuestion?.safety_review}/questions/${activeQuestion?.id}/attachments/thumbnails/`,
    {
      initialData: [],
      enabled: shouldQuery,
    }
  );

  const thumbnails = thumbnailQuery.data;

  const attachmentsWithThumbnails = attachments.map((attachment) => ({
    ...attachment,
    thumbnail: thumbnails.find((thumbnail) => thumbnail.id === attachment.file),
  }));

  const questionAttachments =
    props.questionAttachments ?? attachmentsWithThumbnails.filter((attachment) => attachment.type === "question");
  const answerAttachments =
    props.answerAttachments ?? attachmentsWithThumbnails.filter((attachment) => attachment.type === "action");

  if (shouldQuery && (!attachmentQuery.isFetchedAfterMount || !thumbnailQuery.isFetchedAfterMount)) {
    return <BlockUI show={true} message="Fetching Attachments..." />;
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {(props) => {
            const {handleSubmit, form, submitting, pristine, values} = props;
            return (
              <>
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <DialogTitle id="form-dialog-title">
                    Project Safety Review - {moment(activeQuestion.safety_review_datetime).format(MOMENTFORMAT)}
                  </DialogTitle>
                  <DialogContent>
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <LabelValue label={`Safety Review Question`} value={values.question} />
                        </Grid>
                        <Grid item xs={12}>
                          <LabelValue label="Reviewer Comments" value={values.comment} />
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                          {questionAttachments.length > 0 && (
                            <>
                              <Grid item xs={12}>
                                <LabelValue
                                  label={`Reviewer ${pluralize("Attachment", questionAttachments.length)}`}
                                  value=""
                                />
                              </Grid>
                              {true
                                ? questionAttachments.map((attachment) => {
                                    const {thumbnail} = attachment;
                                    return (
                                      thumbnail && (
                                        <Grid item key={attachment.id}>
                                          <Box my={1}>
                                            <Thumbnail
                                              thumbnail={thumbnail}
                                              onClickThumbnail={(event) => {
                                                setActiveAttachment(attachment);
                                                setThumbnailDetailIsOpen(true);
                                              }}
                                              attachment={attachment}
                                            />
                                          </Box>
                                        </Grid>
                                      )
                                    );
                                  })
                                : Array.from(Array(questionAttachments.length).keys()).map((attachment) => (
                                    <Grid item key={attachment}>
                                      <Skeleton height={100} width={100} variant="rect" />
                                    </Grid>
                                  ))}
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <DateTimePickerMui
                            name={"remediated_datetime"}
                            label="Date/Time Corrected"
                            required
                            autoFocus
                            disabled={readOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="action_taken" label="Action Taken" disabled={readOnly} required />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldMui
                            name="action_description"
                            label="Action Description"
                            minRows={3}
                            multiline
                            disabled={readOnly}
                            required
                          />
                        </Grid>

                        <Grid item xs={12}>
                          {!readOnly && (
                            <UploadFileButton
                              label="Upload Attachment"
                              name="file"
                              accept="image/jpeg,image/jpg,image/heic,image/png"
                              onUpload={(file) => {
                                blockUI.blockUI("Uploading");
                                let formData = new FormData();
                                formData.append("file", file);
                                blockUI.blockUI("Uploading");
                                saveAttachments(file, projectId, "action", values).then(() =>
                                  refreshAttachments().then(() => blockUI.unblockUI())
                                );
                              }}
                            >
                              Upload Attachment
                            </UploadFileButton>
                          )}
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                          {answerAttachments.length > 0 && (
                            <>
                              <Grid item xs={12}>
                                <LabelValue
                                  label={`Your ${pluralize("Attachment", answerAttachments.length)}`}
                                  value=""
                                />
                              </Grid>
                              {true
                                ? answerAttachments.map((attachment) => {
                                    const {thumbnail} = attachment;

                                    return (
                                      thumbnail && (
                                        <Grid item key={attachment.id}>
                                          <Thumbnail
                                            thumbnail={thumbnail}
                                            onClickThumbnail={(event) => {
                                              setActiveAttachment(attachment);
                                              // setActiveThumbnail(thumbnail);
                                              setThumbnailDetailIsOpen(true);
                                            }}
                                            attachment={attachment}
                                          />
                                        </Grid>
                                      )
                                    );
                                  })
                                : Array.from(Array(answerAttachments.length).keys()).map((attachment) => (
                                    <Grid item key={attachment}>
                                      <Skeleton height={100} width={100} variant="rect" />
                                    </Grid>
                                  ))}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                  </DialogContent>
                  <DialogActions>
                    {!readOnly && <FormActions.SaveButton disabled={submitting || readOnly || pristine} />}
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              </>
            );
          }}
        </FinalForm>
      </Dialog>
      <ThumbnailDetailDialog
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateAttachment.mutateAsync(values).then(() => {
            thumbnailQuery.refetch().then(() => {
              setThumbnailDetailIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
        onDelete={() => {
          setDeleteAttachmentConfirmationIsOpen(true);
        }}
        thumbnail={activeAttachment?.thumbnail ?? {}}
        attachment={activeAttachment}
        isOpen={thumbnailDetailIsOpen}
        handleClose={() => setThumbnailDetailIsOpen(false)}
        initialValues={activeAttachment}
        readOnly={activeAttachment?.type === "question" || readOnly}
      />
      <ConfirmationDialog
        isOpen={deleteAttachmentConfirmationIsOpen}
        onDeny={() => setDeleteAttachmentConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setDeleteAttachmentConfirmationIsOpen(false);
          setThumbnailDetailIsOpen(false);
          deleteAttachment.mutateAsync(activeAttachment.id).then(() => {
            thumbnailQuery.refetch().then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        title="Delete Attachment?"
      >
        Are you sure you want to delete this attachment?
      </ConfirmationDialog>
    </>
  );
};
