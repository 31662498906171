import {faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography} from "@material-ui/core";
import pluralize from "pluralize";
import React from "react";
import {isMobile} from "react-device-detect";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {CameraCaptureButton, UploadFileButton, YesNoNAButtons} from "../../../components/Buttons";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import {AutoSubmitSection} from "../../../components/forms/AutoSubmitSection";
import {
  DatePickerMui,
  DateTimePickerMui,
  SimpleSelect,
  TextFieldMui,
  UserAutoselectMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import useBlockUI from "../../../hooks/useBlockUI";
import {colorNovo} from "../../../theme/colors";
import {Thumbnail} from "../ProjectSafetyReview/SafetyReviewComponents";
import {SectionHeader} from "./DailySafetyInspectionComponents";

const saveAttachments = async (file, projectId, type, question) => {
  let formData = new FormData();
  formData.append("file", file);
  // formData.append("type", type);
  const response = await axiosAPI.post(
    `/projects/${projectId}/safety/inspections/${question.safety_inspection}/questions/${question.id}/attachments/upload/`,
    formData
  );
  return response;
};

export const AllDailySafetyInspections = (props) => {
  const {
    questions,
    onInspectionSubmit,
    onQuestionSubmit,
    projectId,
    initialFormValues,
    readOnly,
    setSectionErrors,
    attachmentsWithThumbnails,
    onAttachmentSubmit,
    onDeleteAttachment,
    refreshAttachments,
    refreshQuestions,
    activeAttachment,
    setActiveAttachment,
    setThumbnailDetailIsOpen,
    submitFormRef,
    weather,
    ...rest
  } = props;

  return (
    <>
      <DailySafetyInspectionForm
        questions={questions}
        onSubmit={onInspectionSubmit}
        projectId={projectId}
        initialValues={initialFormValues}
        readOnly={readOnly}
        setSectionErrors={setSectionErrors}
        submitFormRef={submitFormRef}
        weather={weather}
      />
      {Object.keys(questions).map((section) => {
        return (
          <React.Fragment key={section}>
            <SectionHeader isLoading={false} mx={2} bgcolor={colorNovo} borderColor={colorNovo}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Box>
                  <Typography variant="h3">{section}</Typography>
                </Box>
              </Box>
            </SectionHeader>
            {questions[section] &&
              questions[section].map((question) => {
                const attachmentsForQuestion = attachmentsWithThumbnails[question.id] || [];
                const questionAttachments = attachmentsForQuestion.filter(
                  (attachment) => attachment.safety_inspection_question === question.id
                );

                return (
                  <React.Fragment key={question.id}>
                    <DailyInspectionQuestionForm
                      question={question}
                      setSectionErrors={setSectionErrors}
                      onSubmit={onQuestionSubmit}
                      initialValues={{
                        ...question,
                      }}
                      readOnly={readOnly}
                      projectId={projectId}
                      refreshAttachments={refreshAttachments}
                      refreshQuestions={refreshQuestions}
                      submitFormRef={submitFormRef}
                    />

                    <Grid container spacing={2}>
                      {questionAttachments.length > 0 && (
                        <Grid item xs={12}>
                          <Typography variant="h6">{pluralize("Attachment", questionAttachments.length)}:</Typography>
                        </Grid>
                      )}
                      {questionAttachments.map((attachment) => {
                        const {thumbnail} = attachment;

                        return (
                          thumbnail && (
                            <Grid item key={attachment.id}>
                              <Thumbnail
                                thumbnail={thumbnail}
                                attachment={attachment}
                                onClickThumbnail={(event) => {
                                  setActiveAttachment(attachment);
                                  setThumbnailDetailIsOpen(true);
                                }}
                                onDeleteAttachment={onDeleteAttachment}
                              />
                            </Grid>
                          )
                        );
                      })}
                    </Grid>
                    <Box mb={3} />
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export const DailyInspectionQuestionForm = (props) => {
  const {
    question,
    onSubmit,
    projectId,
    inspection,
    refreshAttachments,
    readOnly,
    setSectionErrors,
    submitFormRef,
    ...rest
  } = props;
  const blockUI = useBlockUI();

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, hasValidationErrors, dirty} = props;

        React.useEffect(() => {
          if (hasValidationErrors) {
            setSectionErrors({type: "addSection", data: `${question.question}`});
            submitFormRef.current.add(form);
          } else {
            setSectionErrors({type: "removeSection", data: `${question.question}`});
            submitFormRef.current.delete(form);
          }
        }, [hasValidationErrors]);

        // const validateDatePickerSubmission = (form) => {
        //   const {hasValidationErrors} = form.getState();
        //   if (!hasValidationErrors) return form.submit();
        // };

        const validateNotes = (value, values, form) => {
          if (values.answer === "no" && !value) {
            return "Record the corrective action taken";
          }
        };

        return (
          <Box pb={2}>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <AutoSubmitSection
                readOnly={readOnly}
                form={form}
                title={
                  <>
                    {/* <StatusLabel status={question.status_display} width={120} /> */}
                    <Typography variant="h6" component="span">
                      {question.question}
                    </Typography>
                  </>
                }
              />

              <Grid container spacing={2} key={question.question}>
                <Grid item xs={12} sm={4}>
                  <YesNoNAButtons
                    name={`answer`}
                    // label="Was this in compliance during the inspection?"
                    disabled={readOnly}
                    required
                    nonCompliantAnswer="no"
                    yesLabel="Compliant"
                    noLabel="Not Compliant"
                    fullWidth={isMobile}
                    // onChange={(event, value) => {
                    //   /// Safari does not fire the focus event on radio buttons, so we need to manually update the form state
                    //   if (isSafari) {
                    //     form.change("answer", value);
                    //     handleSubmit();
                    //   }
                    // }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldMui
                    name="notes"
                    label="Notes"
                    disabled={readOnly}
                    multiline
                    helperText={values.answer === "no" ? "Record the corrective action taken" : ""}
                    fieldProps={{validate: validateNotes}}
                    required={values.answer === "no"}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!readOnly && (
                    <>
                      <UploadFileButton
                        startIcon={<FontAwesomeIcon icon={faUpload} />}
                        name="file"
                        accept="image/jpeg,image/jpg,image/heic,image/png"
                        onUpload={(file) => {
                          blockUI.blockUI("Uploading");
                          let formData = new FormData();
                          formData.append("file", file);
                          blockUI.blockUI("Uploading");
                          saveAttachments(file, projectId, "question", question).then(() =>
                            refreshAttachments().then(() => blockUI.unblockUI())
                          );
                        }}
                        fullWidth={isMobile}
                      >
                        Upload Attachment
                      </UploadFileButton>
                    </>
                  )}
                </Grid>
                {isMobile && (
                  <Grid item xs={12}>
                    {/* <UploadFileButton
                      startIcon={<FontAwesomeIcon icon={faCamera} />}
                      capture="environment"
                      name="camera"
                      accept="image/*"
                      onUpload={(file) => {
                        blockUI.blockUI("Uploading");
                        let formData = new FormData();
                        formData.append("file", file);
                        blockUI.blockUI("Uploading");
                        saveAttachments(file, projectId, "question", question).then(() =>
                          refreshAttachments().then(() => blockUI.unblockUI())
                        );
                      }}
                      fullWidth={isMobile}
                    >
                      Capture Picture
                    </UploadFileButton> */}
                    <CameraCaptureButton
                      name="camera"
                      uploadURL={`/projects/${projectId}/safety/inspections/${question.safety_inspection}/questions/${question.id}/attachments/upload/`}
                      beforeUpload={() => blockUI.blockUI("Uploading")}
                      afterUpload={() => refreshAttachments().then(() => blockUI.unblockUI())}
                      fullWidth={isMobile}
                    />
                  </Grid>
                )}
              </Grid>
              <BeforeUnload
                block={!pristine && hasValidationErrors}
                message={`${question.question} has errors preventing saving. Do you wish to close? (changes will be lost)`}
              />

              <ActiveFieldsFormSpy
                onFocusChange={({active}) => {
                  const ignoreFields = ["answer"];
                  if (active === undefined && !pristine && !hasValidationErrors && !ignoreFields.includes(active)) {
                    handleSubmit();
                  }
                }}
              />
            </form>
          </Box>
        );
      }}
    </FinalForm>
  );
};

const DailySafetyInspectionForm = (props) => {
  const {
    questions,
    onSubmitFiles,
    onSubmit,
    projectId,
    inspection,
    readOnly,
    setSectionErrors,
    submitFormRef,
    weather,
    ...rest
  } = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, hasValidationErrors} = props;
        React.useEffect(() => {
          if (hasValidationErrors) {
            setSectionErrors({type: "addSection", data: `General Info`});
            submitFormRef.current.add(form);
          } else {
            setSectionErrors({type: "removeSection", data: `General Info`});
            submitFormRef.current.delete(form);
          }
        }, [hasValidationErrors]);

        const validateDatePickerSubmission = () => {
          const {hasValidationErrors} = form.getState();
          if (!hasValidationErrors && !pristine) return form.submit();
        };

        return (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <UserAutoselectMui name="created_by" label="Created By" disabled />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DateTimePickerMui
                    name="inspection_datetime"
                    label="Inspection Time"
                    onClose={validateDatePickerSubmission}
                    disabled={readOnly}
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SimpleSelect
                    name="shift"
                    label="Shift"
                    options={[
                      {label: "AM", value: "am"},
                      {label: "PM", value: "pm"},
                    ]}
                    disabled={readOnly}
                    required
                  />
                </Grid>

                {/* <Grid item xs={12} sm={3}>
                  <Box display="flex" alignItems={"center"} height={"100%"}>
                    <LabelValue
                      label={"Weather:"}
                      value={
                        <Box display="flex" ml={1}>
                          <Box mr={1} component="span">
                            <img src={weather.summary_icon} alt={weather.summary} style={{height: "20px"}} />
                          </Box>
                          {weather.summary}
                        </Box>
                      }
                      display="flex"
                      alignItems={"center"}
                    />{" "}
                  </Box>
                </Grid> */}
                <Grid item xs={12} sm={3}>
                  <Tooltip title="The date of the Daily Report on which this Inspection should appear">
                    <div>
                      <DatePickerMui
                        name="daily_report_date"
                        label="Daily Report Date"
                        onClose={validateDatePickerSubmission}
                        onBlur={() => {
                          if (values.is_closed) {
                            validateDatePickerSubmission();
                          }
                        }}
                        // helperText="The Daily Report on which this Inspection should appear"
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldMui
                    name="notes"
                    label="Inspection Notes"
                    multiline
                    minRows={3}
                    // onBlur={submitOnBlur}
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
              <Box mb={1} />
              <ActiveFieldsFormSpy
                onFocusChange={({active}) => {
                  const ignoreFields = [""];
                  if (active === undefined && !pristine && !hasValidationErrors && !ignoreFields.includes(active)) {
                    handleSubmit();
                  }
                }}
              />
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const CreateInspectionDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, invalid}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Create Safety Inspection</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <DateTimePickerMui
                          name="inspection_datetime"
                          label="Inspection Time"
                          // onClose={validateDatePickerSubmission}
                          // disabled={readOnly}
                          required
                          autoFocus
                          disableFuture
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SimpleSelect
                          name="shift"
                          label="Shift"
                          options={[
                            {label: "AM", value: "am"},
                            {label: "PM", value: "pm"},
                          ]}
                          // disabled={readOnly}
                          required
                        />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.CreateButton disabled={submitting || invalid} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
