import {faFileSignature} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Link, Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import PaperPanel from "../../../components/PaperPanel";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {MasterSubcontractAgreementUpdateDialogForm} from "./MasterSubcontractAgreementForms";
import MasterSubcontractAgreementPaperItem from "./MasterSubcontractPaperItem";
import useBlockUI from "../../../hooks/useBlockUI";
import moment from "moment";
import useFilterOptions from "../../../hooks/useFilterOptions";
import qs from "query-string";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {PaginationWithPageSize} from "../../../components/Pagination";
import masterSubcontractAgreementStatuses from "../../../components/forms/choices/masterSubcontractAgreementStatuses.json";
import {ConfirmationDialog} from "../../../components/Dialogs";

const MasterSubcontractAgreementList = (props) => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [activeMSA, setActiveMSA] = React.useState({} as any);
  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Year",
    "Search",
    "Status",
  ]);

  const filterParams = {
    year: filterOptions.Year.value,
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
  };

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      years: [
        {
          value: moment().year() % 100,
          label: moment().year().toString(),
        },
      ],
    },
  });
  const pageData = pageDataQuery.data;

  const {
    query: msaQuery,
    create: createMSA,
    update: updateMSA,
    delete: deleteMSA,
  } = useSentinelListAPI(`/accounting-tools/msas/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`, {
    initialData: {
      results: [],
    },
  });

  const msas = msaQuery.data.results;

  return (
    <>
      <Helmet title="Master Subcontract Agreements" />

      <Breadcrumbs>
        <Link color="inherit" href="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <Typography color="textPrimary">Master Subcontract Agreements</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={msaQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faFileSignature} /> Master Subcontract Agreements
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                Create MSA
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton isFetching={msaQuery.isFetching} onClick={() => msaQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Year"
                name="year"
                options={pageData.years.map((year) => {
                  return {value: year, label: year.toString()};
                })}
                value={filterOptions.Year.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Year", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Status"
                name="status"
                options={masterSubcontractAgreementStatuses}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid item xs={12} container>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={msaQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={[100, 250, 500]}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          {msas.map((msa) => {
            return (
              <MasterSubcontractAgreementPaperItem
                key={msa.id}
                msa={msa}
                onEdit={() => {
                  setActiveMSA(msa);
                  setShowUpdateDialog(true);
                }}
                onDelete={() => {
                  setActiveMSA(msa);
                  setDeleteConfirmationIsOpen(true);
                }}
              />
            );
          })}
          <Box p={1}>
            {/* <pre>{filterOptions.Year.value}</pre>
            <pre>{JSON.stringify(pageData, null, 2)}</pre>
            <pre>{JSON.stringify(msaQuery, null, 2)}</pre> */}
          </Box>
        </PaperPanel.Body>
        {msaQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={msaQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <MasterSubcontractAgreementUpdateDialogForm
        isNew
        isOpen={showCreateDialog}
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={
          {
            // from_contact: props.userContact,
          }
        }
        onSubmit={(values) => {
          const editAfterSubmit = values.editAfterSubmit;
          if (editAfterSubmit) {
            delete values.editAfterSubmit;
            blockUI.blockUI();
          }
          createMSA.mutateAsync(values).then((newMSA) => {
            setShowCreateDialog(false);
            if (editAfterSubmit) {
              // navigate(`/contacts/${newContact.id}/`);
              window.location.href = newMSA.url;
              blockUI.unblockUI();
            } else {
              msaQuery.refetch();
            }
          });
        }}
      />
      <MasterSubcontractAgreementUpdateDialogForm
        isOpen={showUpdateDialog}
        handleClose={() => {
          setShowUpdateDialog(false);
        }}
        initialValues={activeMSA}
        onSubmit={(values) => {
          updateMSA.mutateAsync(values).then((newMSA) => {
            setShowUpdateDialog(false);
            blockUI.unblockUI();
          });
        }}
      />

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteMSA
            .mutateAsync(activeMSA.id)
            .then(() => {
              setDeleteConfirmationIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title={`Delete ${activeMSA?.display}`}
      >
        Do you wish to delete this master subcontract agreement? This can not be undone.
      </ConfirmationDialog>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default MasterSubcontractAgreementList;
