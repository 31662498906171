import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import PaperPanel from "../../components/PaperPanel";
import {TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import {FormLabel} from "@material-ui/core";
import moment from "moment";
import React from "react";
import {Field} from "react-final-form";
import {ConfirmationDialog} from "../../components/Dialogs";
import DropzoneMui from "../../components/DropzoneMui";
import {DatePickerMui, SimpleSelect} from "../../components/forms/Fields";
import certificationTypeChoices from "../../components/forms/choices/twilioContactCertificationTypes.json";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import Cookies from "js-cookie";

export const RejectCertificationDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values) => void;
  [rest: string]: any;
}) => {
  const {isOpen, handleClose, onSubmit, ...rest} = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values, invalid}) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle id="form-dialog-title">Mark Certification Revise Resubmit</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        You want to mark this certification <strong>Revise Resubmit</strong>. The following people will
                        receive an email with your comments and will be instructed to revise this certification and
                        resubmit it:
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          {values.twilio_contact?.name} - {values.twilio_contact?.email || "No Email On File"}
                        </Box>
                        <Box>
                          {values.created_by_other && (
                            <>
                              {values.certification_created_by.name} -{" "}
                              {values.certification_created_by?.email || "No Email On File"}
                            </>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="auditor_comments" label="Comments" multiline rows={4} required />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine || invalid} label="OK" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export const AuditCertificationDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values) => void;
  [rest: string]: any;
}) => {
  const {isOpen, handleClose, onSubmit, ...rest} = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values, invalid}) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle id="form-dialog-title">Audit Certification</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        Have you viewed the uploaded certification and verified that it matches the information entered?
                        <ul>
                          <li>Issue Date</li>
                          <li>Expiration Date (if applicable)</li>
                          <li>Type of Equipment</li>
                          <li>Name of Person Certified</li>
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="auditor_comments" label="Comments" multiline rows={4} required />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine || invalid} label="Yes" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export const TwilioContactCertificationDetailForm = (props: {
  onSubmit: (values: any) => void;
  onUploadThumbnail?: (file: File) => void;
  onClickThumbnail?: (thumbnail: any) => void;
  onDelete?: () => void;
  isReadOnly?: boolean;
  thumbnails: any[];
  certifications?: any[];
  thumbnailsFetched?: boolean;
  projectId: number;
  [rest: string]: any;
}) => {
  const {
    onSubmit,
    isReadOnly,
    thumbnails,
    onUploadThumbnail,
    isSpanish,
    certifications,
    thumbnailsFetched,
    onDelete,
    projectId,
    onClickThumbnail,
    ...rest
  } = props;
  const [hasUploadedToDropzone, setHasUploadedToDropzone] = React.useState(false);
  const [submitConfirmationDialogIsOpen, setSubmitConfirmationDialogIsOpen] = React.useState(false);
  const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] = React.useState(false);

  const includesThumbnail = thumbnailsFetched && (hasUploadedToDropzone || thumbnails.length > 0);

  return (
    <FinalForm onSubmit={onSubmit} mutators={{validate: () => {}}} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, hasValidationErrors, errors, submitFailed} = props;
        const {query: certificationQuery} = useSentinelListAPI(
          `safety/certifications/?page_size=10000&valid=true&certification_type=${values?.certification_type}&twilio_contact=${values?.twilio_contact?.id}`,
          {
            initialData: {
              results: [],
            },
            enabled: Boolean(values.certification_type) && Boolean(values.twilio_contact),
          }
        );
        const currentCertifications = certificationQuery.data.results || [];

        const replacesCertificationChoices = currentCertifications
          .filter((certification) => certification.uuid !== values.uuid)
          .map((certification) => ({
            value: certification.uuid,
            label: `${certification.display_with_expiration}`,
          }));

        React.useEffect(() => {
          form.mutators.validate();
        }, [includesThumbnail]);

        return (
          <>
            {/* <BeforeUnload block={!pristine} /> */}
            <form onSubmit={handleSubmit} autoComplete="off" noValidate={true}>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={values.created_by_other ? 6 : 12}>
                    <TextFieldMui name="twilio_contact.name" label="Name" disabled />
                  </Grid>
                  {values.created_by_other && (
                    <Grid item xs={6}>
                      <TextFieldMui name="certification_created_by.name" label="Certification Created By" disabled />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <SimpleSelect
                      name="certification_type"
                      label={"Certification Type"}
                      options={certificationTypeChoices}
                      disabled={isReadOnly}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerMui name="issue_date" label={"Issue Date"} disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerMui
                      name="expiration_date"
                      label={"Expiration Date"}
                      disablePast
                      disabled={isReadOnly}
                      helperText="Leave blank if this certification does not expire"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="notes" label={"Notes"} multiline rows={4} disabled={isReadOnly} />
                  </Grid>
                  {replacesCertificationChoices.length > 0 && !isReadOnly && values.status !== "revise_resubmit" && (
                    <Grid item xs={12}>
                      <SimpleSelect
                        name="replaces_certification_uuid"
                        label={"Replaces Certification"}
                        options={replacesCertificationChoices}
                        disabled={isReadOnly}
                        helperText={"Select the certification that this one replaces (Optional)"}
                      />
                    </Grid>
                  )}

                  <Field
                    name="thumbnail_was_uploaded"
                    component="input"
                    type="hidden"
                    validate={(value, allValues, meta) => {
                      if (!includesThumbnail) {
                        return "Please Upload a Copy of the Certification";
                      }
                    }}
                  />

                  {thumbnails.length === 0 ? (
                    <Grid item xs={12}>
                      No Attachments Found
                    </Grid>
                  ) : (
                    <>
                      {thumbnails.map((thumbnail) => {
                        return (
                          <Grid item xs={12} sm={4} key={thumbnail.id}>
                            <Thumbnail thumbnail={thumbnail} onClickThumbnail={() => onClickThumbnail(thumbnail)} />
                          </Grid>
                        );
                      })}
                    </>
                  )}
                  {!isReadOnly && (
                    <Grid item xs={12}>
                      <DropzoneMui
                        getUploadParams={() => {
                          return {
                            url: `.`,
                            headers: {"X-CSRFToken": Cookies.get("csrftoken")},
                          };
                        }}
                        timeout={1000 * 60 * 5}
                        accept="image/*,application/pdf"
                        onChangeStatus={(event) => {
                          if (["done"].includes(event.meta.status)) {
                            setHasUploadedToDropzone(true);
                            form.mutators.validate();
                          }
                        }}
                      />
                      {submitFailed && hasValidationErrors && !includesThumbnail && (
                        <FormLabel error={true}>{errors.thumbnail_was_uploaded}</FormLabel>
                      )}
                    </Grid>
                  )}
                </Grid>
                <FormActions>
                  {!isReadOnly && (
                    <FormActions.Right>
                      <FormActions.DeleteButton
                        type="button"
                        onClick={() => {
                          setDeleteConfirmationDialogIsOpen(true);
                        }}
                      >
                        Delete Certification
                      </FormActions.DeleteButton>
                      <Box mr={1} />
                      <FormActions.SaveButton
                        disabled={submitting || isReadOnly}
                        label={"Submit Certification"}
                        type="button"
                        onClick={() => {
                          if (hasValidationErrors) {
                            form.submit();
                          } else {
                            setSubmitConfirmationDialogIsOpen(true);
                          }
                        }}
                      />
                    </FormActions.Right>
                  )}
                </FormActions>
              </FinalFormKeyboardShortcuts>
            </form>
            <ConfirmationDialog
              title={"Submit Certification"}
              isOpen={submitConfirmationDialogIsOpen}
              onApprove={() => {
                form.change("project_id", projectId);
                handleSubmit();
                setSubmitConfirmationDialogIsOpen(false);
              }}
              onDeny={() => setSubmitConfirmationDialogIsOpen(false)}
            >
              You want to submit this certification on behalf of {values?.twilio_contact?.name ?? "the subcontractor"}.
              By submitting, you are confirming that:
              <ul>
                <li>The certification type is correct</li>
                {values.issue_date && (
                  <>
                    <li>
                      The expiration date is correct (
                      {Math.ceil(moment.duration(moment(values.expiration_date).diff(moment())).asDays())} days from
                      now).
                    </li>
                  </>
                )}
                <li>You have included a legible copy of the relevant certification paperwork</li>
                <li>This certification is issued to {values?.twilio_contact?.name ?? "the subcontractor"}</li>
                {values.replaces_certification_uuid && (
                  <li>
                    You have indicated that this certification replaces another certification. The previous
                    certification will be marked expired immediately upon submission of this certification.
                  </li>
                )}
              </ul>
            </ConfirmationDialog>
            <ConfirmationDialog
              title={"Delete Certification"}
              isOpen={deleteConfirmationDialogIsOpen}
              onApprove={() => {
                setDeleteConfirmationDialogIsOpen(false);
                onDelete();
              }}
              onDeny={() => setDeleteConfirmationDialogIsOpen(false)}
            >
              Are you sure you want to delete this certification? This action cannot be undone.
            </ConfirmationDialog>
          </>
        );
      }}
    </FinalForm>
  );
};

const Thumbnail = (props) => {
  const {thumbnail, height = 100, width = 100, onClickThumbnail, ...rest} = props;
  return (
    <Box onClick={onClickThumbnail} style={{cursor: "pointer"}}>
      <Box mb={-1}>
        <img
          src={`data:image/png;base64, ${thumbnail.thumbnail_base64}`}
          height={height}
          width={width}
          style={{objectFit: "contain"}}
        />
      </Box>
    </Box>
  );
};
