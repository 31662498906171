import {faLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Tooltip, Typography} from "@material-ui/core";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {Breadcrumbs, ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useSentinelDetailAPI} from "../../hooks/useSentinelAPI";
import {ThumbnailDetailDialog} from "./TwilioContactCertificationComponents";
import {
  AuditCertificationDialog,
  RejectCertificationDialog,
  TwilioContactCertificationDetailForm,
} from "./TwilioContactCertificationForms";
import {LabelValue} from "../../components/LabelValue";

const TwilioContactCertificationDetail = (props) => {
  const {project, ...rest} = props;
  const {certificationId} = useParams();
  const blockUI = useBlockUI();
  const [auditCertificationDialogIsOpen, setAuditCertificationDialogIsOpen] = React.useState(false);
  const [rejectCertificationDialogIsOpen, setRejectCertificationDialogIsOpen] = React.useState(false);
  const [expireCertificationDialogIsOpen, setExpireCertificationDialogIsOpen] = React.useState(false);
  const [thumbnailDetailDialogIsOpen, setThumbnailDetailDialogIsOpen] = React.useState(false);
  const [activeThumbnail, setActiveThumbnail] = React.useState({});

  const {
    query: certificationQuery,
    create: createCertification,
    update: updateCertification,
    delete: deleteCertification,
    rpc: certificationRPC,
  } = useSentinelDetailAPI(`safety/certifications/${certificationId}/`, {
    initialData: {},
  });

  const certification = certificationQuery.data;

  const {query: thumbnailQuery} = useSentinelDetailAPI(`safety/certifications/${certificationId}/images/thumbnails/`, {
    initialData: [],
  });

  const thumbnails = thumbnailQuery.data;

  if (!certificationQuery.isFetchedAfterMount || !thumbnailQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Certification info..." />;
  }

  const expirationDate = moment(certification.expiration_date);

  return (
    <>
      <Helmet title={`Tradesman Certification Detail - ${certification.display}`} />
      {project ? (
        <ProjectBreadcrumbs project={project}>
          <Typography color="textSecondary">Safety</Typography>
          <MuiNavLink to={`/v2/projects/${project.id}/safety/certifications/`}>
            <Typography color="textSecondary">Certifications</Typography>
          </MuiNavLink>
          <Typography color="textPrimary">{certification.display}</Typography>
        </ProjectBreadcrumbs>
      ) : (
        <Breadcrumbs>
          <Typography color="textSecondary">Safety</Typography>
          <MuiNavLink to="/v2/safety/certifications/">
            <Typography color="textSecondary">Certifications</Typography>
          </MuiNavLink>
          <Typography color="textPrimary">{certification.display}</Typography>
        </Breadcrumbs>
      )}

      <PaperPanel>
        <PaperPanel.Header isLoading={certificationQuery.isFetching || thumbnailQuery.isFetching}>
          <PaperPanel.Header.Title>
            <StatusLabel status={certification.status_display} hint="twilioContactCertification" />{" "}
            {certification.display}
            {certification.expiration_date && (
              <>
                {" "}
                - {expirationDate.isAfter(moment()) ? "Expires" : "Expired"} {expirationDate.format("YYYY-MM-DD")}
              </>
            )}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {project && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  startIcon={<FontAwesomeIcon icon={faLink} />}
                  href={`/projects/${project?.uuid}/twilio-contact/${certification.twilio_contact.uuid}/certifications/${certification.uuid}/`}
                  target="_blank"
                >
                  View External
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border={project}>
              <Tooltip
                title={
                  certification.status === "submitted"
                    ? "Verify Certification"
                    : certification.status === "audited"
                    ? "Already Audited"
                    : "Must be Submitted to Audit"
                }
              >
                <div>
                  <PaperPanel.Header.Button
                    onClick={() => {
                      setAuditCertificationDialogIsOpen(true);
                    }}
                    disabled={certification.status !== "submitted"}
                  >
                    Audit
                  </PaperPanel.Header.Button>
                </div>
              </Tooltip>
              <Box mr={1} />
              <Tooltip
                title={
                  certification.status === "submitted"
                    ? ""
                    : certification.status === "audited"
                    ? "Already Audited"
                    : "Must be Submitted"
                }
              >
                <div>
                  <PaperPanel.Header.Button
                    onClick={() => {
                      setRejectCertificationDialogIsOpen(true);
                    }}
                    disabled={certification.status !== "submitted"}
                  >
                    Mark Revise Resubmit
                  </PaperPanel.Header.Button>
                </div>
              </Tooltip>
              {["audited"].includes(certification.status) && (
                <>
                  <Box mr={1} />
                  <Tooltip title={"Mark Expired Now"}>
                    <div>
                      <PaperPanel.Header.Button
                        onClick={() => {
                          setExpireCertificationDialogIsOpen(true);
                        }}
                      >
                        Mark Expired
                      </PaperPanel.Header.Button>
                    </div>
                  </Tooltip>
                </>
              )}
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => Promise.all([certificationQuery.refetch(), thumbnailQuery.refetch()])}
                isFetching={certificationQuery.isFetching || thumbnailQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body px={1} py={2}>
          <Box mb={1}>
            <Grid container spacing={2}>
              {(certification?.audited_by || certification?.auditor_comments) && (
                <>
                  <Grid item xs={12} sm={6}>
                    <LabelValue label="Audited By" value={certification?.audited_by?.full_name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {certification.auditor_comments && (
                      <LabelValue label="Comments" value={certification?.auditor_comments} />
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4}>
                <LabelValue label="Name" value={certification.twilio_contact.name} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LabelValue label="Company" value={certification.twilio_contact.company} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LabelValue label="Created" value={moment(certification.created).format("YYYY-MM-DD")} />
              </Grid>
            </Grid>
          </Box>
          <TwilioContactCertificationDetailForm
            initialValues={certification}
            onSubmit={(values) => {
              blockUI.blockUI("Saving...");
              certificationRPC.mutateAsync({action: "submit", data: values}).then(() => {
                Promise.all([certificationQuery.refetch(), thumbnailQuery.refetch()]).then(() => {
                  blockUI.unblockUI();
                });
              });
            }}
            onDelete={() => {
              blockUI.blockUI("Deleting...");
              deleteCertification.mutateAsync(certification).then(() => {
                blockUI.unblockUI();
                const redirectURL = project?.id
                  ? `/v2/projects/${project.id}/safety/certifications/`
                  : "/v2/safety/certifications/";
                window.location.href = redirectURL;
              });
            }}
            isReadOnly={!["pending"].includes(certification.status)}
            projectId={project?.id}
            thumbnailsFetched={thumbnailQuery.isFetchedAfterMount}
            thumbnails={thumbnails}
            onClickThumbnail={(thumbnail) => {
              setActiveThumbnail(thumbnail);
              setThumbnailDetailDialogIsOpen(true);
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <AuditCertificationDialog
        isOpen={auditCertificationDialogIsOpen}
        handleClose={() => setAuditCertificationDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          certificationRPC.mutateAsync({action: "audit", data: values}).then(() => {
            setAuditCertificationDialogIsOpen(false);
            certificationQuery.refetch().then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        initialValues={certification}
      />
      <RejectCertificationDialog
        isOpen={rejectCertificationDialogIsOpen}
        initialValues={certification}
        handleClose={() => setRejectCertificationDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          certificationRPC.mutateAsync({action: "revise-resubmit", data: values}).then(() => {
            setRejectCertificationDialogIsOpen(false);
            certificationQuery.refetch().then(() => {
              blockUI.unblockUI();
            });
          });
        }}
      />

      <ConfirmationDialog
        isOpen={expireCertificationDialogIsOpen}
        title="Expire Certification"
        onApprove={() => {
          setExpireCertificationDialogIsOpen(false);
          blockUI.blockUI("Saving...");
          certificationRPC.mutateAsync({action: "expire"}).then(() => {
            certificationQuery.refetch().then(() => {
              blockUI.unblockUI();
            });
          });
        }}
        onDeny={() => setExpireCertificationDialogIsOpen(false)}
      >
        You want to expire this certification immediately? This will change the expiration date to today and mark the
        certification as expired.
      </ConfirmationDialog>

      <ThumbnailDetailDialog
        isOpen={thumbnailDetailDialogIsOpen}
        handleClose={() => {
          setThumbnailDetailDialogIsOpen(false);
        }}
        thumbnail={activeThumbnail}
      />
    </>
  );
};
export default TwilioContactCertificationDetail;
