import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, Link} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import InnerFooter from "../../../components/InnerFooter";
import {StatusLabel} from "../../../components/Status";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import {
  CompanyAutoselectMui,
  CompanyContactAutoselectMui,
  ContactAutoselectMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";

const validateExisting = async (value, values, field) => {
  if (!value?.id) return "Required";
  const response = await axiosAPI.get(
    `/accounting-tools/msas/validate-existing/?to_company=${value?.id || ""}${values?.id ? `&id=${values.id}` : ""}`
  );
  if (response.data.error) {
    return response.data.error;
  }
};

const checkExisting = async (id, to_company) => {
  if (!to_company?.id) return null;
  const response = await axiosAPI.get(
    `/accounting-tools/msas/validate-existing/?to_company=${to_company?.id || ""}${id ? `&id=${id}` : ""}`
  );
  let msa = null;
  if (response.data.msa) {
    msa = response.data.msa;
  }
  return msa;
};

export const MasterSubcontractAgreementCommonFields = (props) => {
  const {values, isReadOnly, isNew} = props;
  const [existingMSA, setExistingMSA] = React.useState(null);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CompanyAutoselectMui
          autoFocus
          required
          name="to_company"
          label="To Company"
          disabled={isReadOnly}
          // fieldProps={{
          //   validate: validateExisting,
          // }}
          onChange={(e, value) => {
            checkExisting(values?.id, value).then((msa) => {
              setExistingMSA(msa);
            });
          }}
        />
        {existingMSA && (
          <FormHelperText error>
            There is already a MSA ({existingMSA.status_display}) for {existingMSA.to_company?.name}{" "}
            <Link href={existingMSA.url} underline="always">
              here
            </Link>
            {/* <pre>{JSON.stringify(existingMSA, null, 2)}</pre> */}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        {/* <CompanyContactAutoselectMui
          required
          name="to_contact"
          label="To Contact"
          companyId={values?.to_company?.id}
          disabled={Boolean(!values?.to_company?.id) || isReadOnly}
        /> */}
        <ContactAutoselectMui required name="to_contact" label="To Contact" disabled={isReadOnly} />
        {values.to_company && !values.to_contact && (
          <FormHelperText>
            Add missing contact for {values.to_company.name}{" "}
            <Link href={`${values.to_company.url}#tab=contacts`} underline="always" target="_blank">
              here
            </Link>
          </FormHelperText>
        )}
      </Grid>
      {!isNew && (
        <Grid item xs={12}>
          <CompanyContactAutoselectMui
            required
            name="from_contact"
            label="From Contact"
            companyId={1547}
            disabled={true}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const MasterSubcontractAgreementUpdateForm = (props: {
  onSubmit: (values: any) => void;
  onDelete: () => void;
  isReadOnly?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isReadOnly, ...rest} = props;

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <MasterSubcontractAgreementCommonFields {...props} />
                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <Box ml={1} />
                    <FormActions.DeleteButton onClick={() => onDelete()} disabled={isReadOnly} />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const MasterSubcontractAgreementUpdateDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {(props) => {
            const {handleSubmit, form, submitting, pristine, values} = props;
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      {!isNew && <StatusLabel status={values.status_display} />} {isNew ? "Create MSA" : "Update MSA"}
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <MasterSubcontractAgreementCommonFields isNew={isNew} {...props} />
                  </FinalFormKeyboardShortcuts>
                  {!isNew && (
                    <SuperuserInfo
                      objects={[
                        {
                          contentType: "accounting_tools",
                          model: "mastersubcontractagreement",
                          id: values.id,
                          title: "MSA",
                        },
                      ]}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {isNew && (
                    <FormActions.SaveAndEditButton
                      disabled={submitting || pristine}
                      onClick={() => form.change("editAfterSubmit", true)}
                    />
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
