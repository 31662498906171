import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {
  CompanyAutoselectMui,
  CompanyContactAutoselectMui,
  DatePickerMui,
  GoogleMapsAddressAutocompleteMui,
  MultipleAutocompleteMui,
  NumberFieldMui,
  SelectStatus,
  SimpleAutoselectMui,
  SimpleSelect,
  TextFieldMui,
} from "../../../components/forms/Fields";
import offices from "../../../components/forms/choices/offices.json";
import bidTypeOptions from "../../../components/forms/choices/rfpBidTypes.json";
import projectTypeChoices from "../../../components/forms/choices/rfpProjectTypes.json";
import requestTypeChoices from "../../../components/forms/choices/rfpRequestTypes.json";
import sectorTypeChoices from "../../../components/forms/choices/rfpSectors.json";
import statusChoices from "../../../components/forms/choices/rfpStatusTypes.json";

import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import InnerFooter from "../../../components/InnerFooter";
import FormActions from "../../../components/forms/FormActions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";

const RFPOnboardingCommonFields = (props) => {
  const {form, tagOptions = [], isFetching} = props;

  const validateNumber = async (value, values, field) => {
    if (!value) return "Required";
    if (value < 1) return "Must use a positive number";
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {/* <SimpleSelect name="status" label="Status" options={statusChoices} required autoFocus /> */}
          <SelectStatus name="status" label="Status" options={statusChoices} required autoFocus />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyAutoselectMui name="client" label="Client" required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name="office" label="Office" required options={offices} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldMui name="project_name" label="Project Name" required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name="project_type" label="Project Type" options={projectTypeChoices} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleAutoselectMui name="request_type" label="Request Type" options={requestTypeChoices} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleAutoselectMui name="sector" label="Sector" options={sectorTypeChoices} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name="bid_type" label="Bid Type" options={bidTypeOptions} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui name="due_date" label="Due Date" required disablePast />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldMui name="due_time" label="Due Time" required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui name="job_walk_date" label="Job Walk Date" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui name="rfi_deadline_date" label="RFI Deadline" />
        </Grid>
        <Grid item xs={12}>
          <CompanyAutoselectMui name="competitors" label="Competitors" multiple />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocompleteMui options={tagOptions} loading={isFetching} multiple name="tags" label="Tags" />
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <GoogleMapsAddressAutocompleteMui
              name="address1"
              label="Address 1"
              onPlaceSelected={(place) => {
                form.change("address1", place.address1);
                form.change("address2", place.address2);
                form.change("city", place.locality_long_name);
                form.change("state", place.administrative_area_level_1_short_name);
                form.change("postal_code", place.postal_code);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldMui name="address2" label="Address 2" />
          </Grid>
          <Grid item xs={6}>
            <TextFieldMui name="city" label="City" />
          </Grid>
          <Grid item xs={3}>
            <TextFieldMui name="state" label="State" />
          </Grid>
          <Grid item xs={3}>
            <TextFieldMui name="postal_code" label="Postal Code" />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CompanyAutoselectMui name="architect" label="Architect" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyAutoselectMui name="construction_manager" label="Construction Manager" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <NumberFieldMui
            name="square_footage"
            label="Anticipated Square Footage"
            fieldProps={{
              validate: validateNumber,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFieldMui
            name="anticipated_budget"
            label="Anticipated Budget"
            required
            fieldProps={{
              validate: validateNumber,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyContactAutoselectMui name="project_executive" label="Project Executive" companyId={1547} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyContactAutoselectMui name="project_manager" label="Project Manager" companyId={1547} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyContactAutoselectMui name="project_engineer" label="Project Engineer" companyId={1547} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyContactAutoselectMui name="superintendent" label="Superintendent" companyId={1547} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerMui name="construction_start_date" label="Construction Start Date" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerMui name="construction_finish_date" label="Construction Finish Date" />
        </Grid>
        <Grid item xs={12}>
          <TextFieldMui name="notes" label="Notes" multiline rows={4} fullWidth />
        </Grid>
      </Grid>
    </>
  );
};

export const RFPOnboardingForm = (props: {
  onSubmit: (values: any) => void;
  isFetching: boolean;
  tagOptions: string[];
  [rest: string]: any;
}) => {
  const {onSubmit, isFetching, tagOptions = [], ...rest} = props;

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
              <RFPOnboardingCommonFields form={form} isFetching={isFetching} tagOptions={tagOptions} />
              <InnerFooter>
                <Box display="flex">
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <Box ml={1} />
                </Box>
              </InnerFooter>
            </FinalFormKeyboardShortcuts>
          </form>
        )}
      </FinalForm>
    </>
  );
};

export const RFPOnboardingDialogForm = (props: {
  onSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  isNew?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, open, onClose, isNew, ...rest} = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{isNew ? "New" : "Edit"} Request For Proposal</DialogTitle>

      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <DialogContent>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <RFPOnboardingCommonFields form={form} />
              </FinalFormKeyboardShortcuts>
            </DialogContent>
            <DialogActions>
              <FormActions.SaveButton disabled={submitting || pristine} />
              <FormActions.CancelButton onClick={onClose} />
            </DialogActions>
          </form>
        )}
      </FinalForm>
    </Dialog>
  );
};
