import {faCalendar, faUpRightFromSquare} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {groupBy} from "lodash";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import {MuiNavLink} from "../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import officeChoices from "../../components/forms/choices/offices.json";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import usePermissions from "../../hooks/usePermissions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {ContactDialogForm, TeamDialogForm} from "./LaborContactsForms";

const LaborContacts = (props) => {
  const {...rest} = props;
  const [pageSize, setPageSize] = React.useState(500);
  const [page, setPage] = React.useState(1);
  const [addContactDialogIsOpen, setAddContactDialogIsOpen] = React.useState(false);
  const [editContactDialogIsOpen, setEditContactDialogIsOpen] = React.useState(false);
  const [createTeamDialogIsOpen, setCreateTeamDialogIsOpen] = React.useState(false);
  const [updateTeamDialogIsOpen, setUpdateTeamDialogIsOpen] = React.useState(false);
  const [deleteContactConfirmationIsOpen, setDeleteContactConfirmationIsOpen] = React.useState(false);
  const [deleteTeamConfirmationIsOpen, setDeleteTeamConfirmationIsOpen] = React.useState(false);
  const [activeContact, setActiveContact] = React.useState(null);
  const [activeTeam, setActiveTeam] = React.useState(null);
  const [defaultOffice, setDefaultOffice] = useLocalStorage("labor-schedule-office", {value: "", label: ""});
  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const isReadOnly = !permissions.change_laborscheduleitem;

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Office"],
    {Office: {value: defaultOffice.value, label: defaultOffice.label}}
  );

  const filterParams = {
    q: filterOptions.Search.value,
    office: filterOptions.Office.value,
  };

  const {
    query: contactQuery,
    create: createContact,
    update: updateContact,
    delete: deleteContact,
  } = useSentinelListAPI(
    `labor/schedule/contacts/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}&is_active=true`,
    {
      initialData: {
        results: [],
      },
    }
  );
  const contacts = contactQuery.data.results;

  const {
    query: teamQuery,
    create: createTeam,
    update: updateTeam,
    delete: deleteTeam,
  } = useSentinelListAPI(
    `labor/schedule/teams/?is_active=true&page=1&page_size=10000`, //&office=${filterOptions.Office.value}
    {
      initialData: {results: []},
    }
  );

  const teams = teamQuery.data.results;

  const teamOptions = teams.map((team) => ({name: team.name, id: team.id, office: team.office}));

  const contactsByOffice = groupBy(contacts, "office");

  return (
    <>
      <Helmet title="Labor Schedule Contacts" />
      <Breadcrumbs>
        <Typography color="textSecondary">Labor</Typography>
        <Typography color="textPrimary">Contacts</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={contactQuery.isFetching || teamQuery.isFetching}>
          <PaperPanel.Header.Title>Labor Schedule Contacts</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faCalendar} />}
                href={`/v2/labor/schedule/`}
                component={MuiNavLink}
                underline="none"
              >
                {isReadOnly ? "View" : "Manage"} Labor Schedule
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            {!isReadOnly && (
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.CreateButton onClick={() => setCreateTeamDialogIsOpen(true)}>
                  Create Team
                </PaperPanel.Header.CreateButton>
                <Box mr={1} />
                <PaperPanel.Header.CreateButton onClick={() => setAddContactDialogIsOpen(true)}>
                  Add Contact
                </PaperPanel.Header.CreateButton>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <Box mr={-1}>
                <PaperPanel.Header.RefreshButton
                  onClick={() => Promise.all([contactQuery.refetch(), teamQuery.refetch()])}
                  isFetching={contactQuery.isFetching || teamQuery.isFetching}
                />
              </Box>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Office"
                name="office"
                options={officeChoices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  setPage(1);
                  setDefaultOffice({value: value, label: label});
                  setFilterOption("Office", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                // filterOptions={filterOptions}
                filterOptions={{Search: filterOptions.Search}}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={contactQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          {officeChoices.map((office) => {
            const teamsInThisOffice = teams.filter((team) => team.office.id === office.value);
            const contactsForOffice = contactsByOffice[office.value] || [];
            const noTeamContacts = contactsForOffice.filter((contact) => !contact.team);
            const teamContacts = groupBy(
              contactsForOffice.filter((contact) => contact.team),
              "team.id"
            );

            const teamsWithNoContacts = teamsInThisOffice
              .filter((team) => !Object.keys(teamContacts).includes(String(team.id)))
              .sort((a, b) => a.name.localeCompare(b.name));

            return (
              <React.Fragment key={office.value}>
                {contactsForOffice.length > 0 && <PaperItem.HeaderLabel label={office.label} />}

                {noTeamContacts.map((contact) => {
                  return (
                    <ContactPaperItem
                      key={contact.id}
                      scheduleContact={contact}
                      onEditContact={() => {
                        setActiveContact(contact);
                        setEditContactDialogIsOpen(true);
                      }}
                      isReadOnly={isReadOnly}
                      onClickDelete={() => {
                        setActiveContact(contact);
                        setDeleteContactConfirmationIsOpen(true);
                      }}
                    />
                  );
                })}
                {Object.keys(teamContacts).map((teamId) => {
                  const team = teams.find((team) => team.id === parseInt(teamId));
                  if (team)
                    return (
                      <React.Fragment key={team.id}>
                        <TeamHeader
                          team={team}
                          onEditTeam={() => {
                            setActiveTeam(team);
                            setUpdateTeamDialogIsOpen(true);
                          }}
                          onDeleteTeam={() => {
                            setActiveTeam(team);
                            setDeleteTeamConfirmationIsOpen(true);
                          }}
                        />
                        {teamContacts[team.id].map((contact) => {
                          return (
                            <ContactPaperItem
                              key={contact.id}
                              scheduleContact={contact}
                              onEditContact={() => {
                                setActiveContact(contact);
                                setEditContactDialogIsOpen(true);
                              }}
                              onClickDelete={() => {
                                setActiveContact(contact);
                                setDeleteContactConfirmationIsOpen(true);
                              }}
                              isReadOnly={isReadOnly}
                            />
                          );
                        })}
                      </React.Fragment>
                    );
                })}
                {teamsWithNoContacts.map((team) => {
                  return (
                    <React.Fragment key={team.id}>
                      <TeamHeader
                        team={team}
                        onEditTeam={() => {
                          setActiveTeam(team);
                          setUpdateTeamDialogIsOpen(true);
                        }}
                        onDeleteTeam={() => {
                          setActiveTeam(team);
                          setDeleteTeamConfirmationIsOpen(true);
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </PaperPanel.Body>
        {contactQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={contactQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <ContactDialogForm
        isOpen={addContactDialogIsOpen}
        handleClose={() => {
          setAddContactDialogIsOpen(false);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          createContact.mutateAsync({...values}).then(() => {
            contactQuery.refetch().then(() => {
              blockUI.unblockUI();
              setAddContactDialogIsOpen(false);
            });
          });
        }}
        isNew
        teamOptions={teamOptions}
        initialValues={{office: defaultOffice.value}}
      />
      <ContactDialogForm
        isOpen={editContactDialogIsOpen}
        handleClose={() => {
          setEditContactDialogIsOpen(false);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");

          updateContact.mutateAsync({...values}).then(() => {
            contactQuery.refetch().then(() => {
              blockUI.unblockUI();
              setEditContactDialogIsOpen(false);
            });
          });
        }}
        initialValues={activeContact}
        teamOptions={teamOptions}
      />
      <TeamDialogForm
        isOpen={createTeamDialogIsOpen}
        isNew
        handleClose={() => {
          setCreateTeamDialogIsOpen(false);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");

          createTeam.mutateAsync(values).then(() => {
            blockUI.unblockUI();
            setCreateTeamDialogIsOpen(false);
          });
        }}
        offices={officeChoices}
        initialValues={{office: {id: defaultOffice.value}}}
      />
      <TeamDialogForm
        isOpen={updateTeamDialogIsOpen}
        handleClose={() => {
          setUpdateTeamDialogIsOpen(false);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");

          updateTeam.mutateAsync(values).then(() => {
            blockUI.unblockUI();
            setUpdateTeamDialogIsOpen(false);
          });
        }}
        offices={officeChoices}
        initialValues={activeTeam}
      />
      <ConfirmationDialog
        isOpen={deleteContactConfirmationIsOpen}
        onDeny={() => setDeleteContactConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteContact.mutateAsync(activeContact?.id).then(() => {
            contactQuery.refetch().then(() => {
              setDeleteContactConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
        title="You want to delete this Labor Schedule Contact?"
      >
        This will delete the labor schedule contact for {activeContact?.contact?.full_name}. This person will no longer
        appear on the labor schedule. Their Sentinel contact will not be deleted and they will not be removed from any
        project directories. All of their labor schedule items will be deleted.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={deleteTeamConfirmationIsOpen}
        onDeny={() => setDeleteTeamConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteTeam.mutateAsync(activeTeam?.id).then(() => {
            contactQuery.refetch().then(() => {
              setDeleteTeamConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
        title="You want to delete this Team?"
      >
        This will delete the labor schedule team for {activeTeam?.name}. All contacts associated with this team will be
        added to the general contacts list.
      </ConfirmationDialog>
    </>
  );
};

export default LaborContacts;

const ContactPaperItem = (props: {
  scheduleContact: any;
  onEditContact: (contact: any) => void;
  onClickDelete: (contact: any) => void;
  isReadOnly: boolean;
}) => {
  const {scheduleContact, onEditContact, onClickDelete, isReadOnly} = props;
  return (
    <PaperItem key={scheduleContact.id}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {scheduleContact.contact.full_name}{" "}
          <MuiNavLink href={scheduleContact.contact.url}>
            <FontAwesomeIcon icon={faUpRightFromSquare} />
          </MuiNavLink>
        </Grid>
        <Grid item xs={4}>
          {scheduleContact.contact.email}
        </Grid>
        <Grid item xs={4}>
          {/* {scheduleContact.type_display} */}
        </Grid>
      </Grid>
      {!isReadOnly && (
        <PaperItem.RightHover>
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditContact} />
          <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onClickDelete} />
        </PaperItem.RightHover>
      )}
    </PaperItem>
  );
};

const TeamHeader = (props) => {
  const {team, onEditTeam, onDeleteTeam} = props;
  return (
    <PaperItem bgcolor="grey.100">
      <PaperItem.Body>
        <Typography variant={"h5"} style={{margin: 0}}>
          {team.name}
        </Typography>
      </PaperItem.Body>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditTeam} />
        <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDeleteTeam} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
