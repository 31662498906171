import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Link, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import accounting from "accounting";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {textToHTML} from "../../../../js/common/utils";
import HtmlRender from "../../../../js/components/HtmlRender";
import {Currency} from "../../../components/Accounting";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import officeChoices from "../../../components/forms/choices/offices.json";
import {LabelValue} from "../../../components/LabelValue";
import {MuiNavLink} from "../../../components/Links";
import {PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import VirtualizedList from "../../../components/VirtualizedList";
import useFilterOptions from "../../../hooks/useFilterOptions";
import useOrderingOptions from "../../../hooks/useOrderingOptions";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";

const MasterProjectList = (props) => {
  const {currentFiscalYear, ...rest} = props;
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(500);

  const [getQueryString, getIcon, cycleOrdering] = useOrderingOptions([
    "square_feet",
    "denorm_current_budget",
    "start_date",
    "finish_date",
    "job_start_gmp",
    "suggested_timberline_job_number",
  ]);

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Year", "Office", "Search", "Status", "Location", "Has Job #"],
    {
      Year: {
        value: currentFiscalYear % 100,
        label: currentFiscalYear.toString(),
      },
      "Has Job #": {
        value: "true",
        label: "Yes",
      },
    }
  );

  const filterParams = {
    year: filterOptions.Year.value,
    office: filterOptions.Office.value,
    status: filterOptions.Status.value,
    location: filterOptions.Location.value,
    q: filterOptions.Search.value,
    ordering: getQueryString(),
    has_job: filterOptions["Has Job #"].value,
  };

  const paginationParams = {
    page_size: pageSize,
    page: page,
  };

  const excelParams = {
    page_size: 10000,
    page: 1,
  };

  const yearChoices = Array.from({length: currentFiscalYear - 2010 + 1}, (_, index) => ({
    label: (2010 + index).toString(),
    value: (2010 + index) % 100,
  })).reverse();
  const pageDataQuery = useFetchCurrentPage(
    {
      // refetchOnWindowFocus: false,
      cacheTime: 0,
      initialData: {
        projects: {
          results: [],
        },
      },
    },
    {...filterParams, ...paginationParams}
  );
  const pageData = pageDataQuery.data;
  const projects = pageData.projects.results;
  const totalPages = pageData.projects.total_pages;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Helmet title={`Master Project List`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>

        <Typography color="textPrimary">Master Project List</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            Master Project List{" "}
            {!pageDataQuery.isFetching && (
              <>
                ({accounting.formatNumber(projects.length)}/{accounting.formatNumber(pageData.projects?.count)})
              </>
            )}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                href={`/v2/projects/master-project-list/?export_type=excel&${qs.stringify({
                  ...filterParams,
                  ...excelParams,
                })}`}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Timberline Job Status"
                name="status"
                options={[
                  {label: "In Progress", value: "In Progress"},
                  {label: "Closed", value: "Closed"},
                ]}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Office"
                name="office"
                options={officeChoices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Office", value, label);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Year"
                name="year"
                options={yearChoices}
                value={filterOptions.Year.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Year", value, label);
                }}
                allowNull={false}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>

            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Location"
                value={filterOptions.Location.value}
                name="Location"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Location", value, value);
                }}
                helperText="Address or City or Zip"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Has Job #"
                name="Has Job #"
                options={[
                  {label: "Yes", value: "true"},
                  {label: "No", value: "false"},
                ]}
                allowNull
                value={filterOptions["Has Job #"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Has Job #", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid item xs={12} container>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => clearAllFilterOptions()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={totalPages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={[100, 250, 500]}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <small>
            {!isMobile && (
              <PaperItem noHover bgcolor={grey[50]}>
                <PaperItem.Left minWidth={80}></PaperItem.Left>
                <PaperItem.Body>
                  <Grid container spacing={1}>
                    <Grid item lg={2}>
                      <Box onClick={() => cycleOrdering("suggested_timberline_job_number")} style={{cursor: "pointer"}}>
                        <strong>Project</strong> {getIcon("suggested_timberline_job_number")}
                      </Box>
                    </Grid>
                    <Grid item lg={2}>
                      <strong>Address</strong>
                    </Grid>
                    <Grid item lg={2}>
                      <strong>Team</strong>
                    </Grid>
                    <Grid item lg={1}>
                      <Box onClick={() => cycleOrdering("denorm_current_budget")} style={{cursor: "pointer"}}>
                        <strong>Current Budget</strong> {getIcon("denorm_current_budget")}
                      </Box>
                    </Grid>
                    <Grid item lg={1}>
                      <Box onClick={() => cycleOrdering("square_feet")} style={{cursor: "pointer"}}>
                        <strong>Square Feet</strong> {getIcon("square_feet")}
                      </Box>
                    </Grid>
                    <Grid item lg={1}>
                      <Box onClick={() => cycleOrdering("job_start_gmp")} style={{cursor: "pointer"}}>
                        <strong>Waivers</strong> {getIcon("job_start_gmp")}
                      </Box>
                    </Grid>
                    <Grid item lg={1}>
                      <Box onClick={() => cycleOrdering("start_date")} style={{cursor: "pointer"}}>
                        <strong>Start Date</strong> {getIcon("start_date")}
                      </Box>
                    </Grid>
                    <Grid item lg={1}>
                      <Box onClick={() => cycleOrdering("finish_date")} style={{cursor: "pointer"}}>
                        <strong>Finish Date</strong> {getIcon("finish_date")}
                      </Box>
                    </Grid>
                    <Grid item lg={1}>
                      <strong>Client</strong>
                    </Grid>
                  </Grid>
                </PaperItem.Body>
              </PaperItem>
            )}
            <VirtualizedList itemList={projects} isVirtualized={projects.length > 50} disableDragAndDrop>
              {(props) => {
                const {listItem, ...rest} = props;

                return <ProjectPaperItem project={listItem} isMobile={isMobile} />;
              }}
            </VirtualizedList>
          </small>
        </PaperPanel.Body>
      </PaperPanel>
      {/* <Box mt={1} />
      <LegacyUILink href={`/projects/master-project-list/`} /> */}
    </>
  );
};

export default MasterProjectList;

const ProjectPaperItem = (props) => {
  const {project, isMobile, ...rest} = props;
  return (
    <PaperItem>
      <PaperItem.Left minWidth={80}>
        <StatusLabel status={project.status || "Unknown"} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            <Link underline="always" href={`/v2/projects/${project.id}/`}>
              {project.name}
            </Link>
          </Grid>
          <Grid item xs={6} lg={2}>
            <HtmlRender html={textToHTML(project.address)} />
          </Grid>
          <Grid item xs={6} lg={2}>
            <Box style={{whiteSpace: "nowrap"}}>
              {/* {isMobile && <Typography2 type="metadata">Team</Typography2>} */}
              {project.project_executive && <LabelValue label="PX: " value={project.project_executive} />}
              {project.senior_project_manager && <LabelValue label="Sr. PM: " value={project.senior_project_manager} />}
              {project.project_manager && <LabelValue label="PM: " value={project.project_manager} />}
              {project.superintendent && <LabelValue label="Super: " value={project.superintendent} />}
              {project.project_admin && <LabelValue label="Admin: " value={project.project_admin} />}
            </Box>
          </Grid>
          <Grid item xs={6} lg={1}>
            {isMobile && (
              <Box>
                <Typography2 type="metadata">Current Budget</Typography2>
              </Box>
            )}

            <Currency number={project.current_budget} precision={0} />
          </Grid>
          <Grid item xs={6} lg={1}>
            {isMobile && (
              <Box>
                <Typography2 type="metadata">Square Feet</Typography2>
              </Box>
            )}
            {accounting.formatNumber(project.square_feet)}
          </Grid>
          <Grid item xs={12} lg={1}>
            {isMobile && (
              <Box>
                <Typography2 type="metadata">Waivers</Typography2>
              </Box>
            )}
            {project.waivers}
          </Grid>
          <Grid item xs={6} lg={1}>
            {isMobile && (
              <Box>
                <Typography2 type="metadata">Start Date</Typography2>
              </Box>
            )}
            {project.start_date && moment(project.start_date).format("YYYY-MM-DD")}
          </Grid>
          <Grid item xs={6} lg={1}>
            {isMobile && (
              <Box>
                <Typography2 type="metadata">Finish Date</Typography2>
              </Box>
            )}
            {project.finish_date && moment(project.finish_date).format("YYYY-MM-DD")}
          </Grid>
          <Grid item xs={12} lg={1}>
            {project.client && (
              <>
                {isMobile && (
                  <Box>
                    <Typography2 type="metadata">Client</Typography2>
                  </Box>
                )}

                <MuiNavLink href={`/v2/companies/${project.client_id}/`} underline="always">
                  {project.client}
                </MuiNavLink>
              </>
            )}
          </Grid>
        </Grid>
      </PaperItem.Body>
    </PaperItem>
  );
};
