import MomentUtils from "@date-io/moment";
import {Button, CssBaseline, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {StylesProvider} from "@material-ui/styles";
import Layout, {Root, useSidebarTrigger} from "@mui-treasury/layout";
import * as Sentry from "@sentry/browser";
import {SnackbarProvider} from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useRouteError,
} from "react-router-dom";
import {ThemeProvider, createGlobalStyle} from "styled-components";
import BuggyComponent from "../js/components/BuggyComponent";
import {AppContext} from "./Contexts";
import {DjangoMessages} from "./components/DjangoMessages";
import GlobalFallback from "./components/GlobalFallback";
import BlockUI, {ReactQueryGlobalLoader} from "./components/GlobalLoaders";
import RouteNotFound from "./components/RouteNotFound";
import AuthenticatedLayout from "./layouts/AuthenticatedLayout";
import ProjectLayout from "./layouts/ProjectLayout";
import UnauthenticatedLayout from "./layouts/UnauthenticatedLayout";
import ExternalSafetyOrientation from "./pages/ExternalSafetyOrientation";
import ExternalTwilioContactCertificationDetail from "./pages/ExternalTwilioContactCertifications/ExternalTwilioContactCertificationDetail";
import ExternalTwilioContactCertifications from "./pages/ExternalTwilioContactCertifications/ExternalTwilioContactCertifications";
import JobsiteFiles from "./pages/JobsiteDocuments";
import Login2 from "./pages/Login2";
import Notifications from "./pages/Notifications";
import PretaskPlanExternalSurvey from "./pages/PretaskPlanExternalSurvey";
import TwilioContactUpdate from "./pages/TwilioContactUpdate";
import UnauthenticatedTest from "./pages/UnauthenticatedTest";
import WellnessCheckQuestionnaire from "./pages/WellnessCheckExternalQuestionnaire";
import ExternalForms from "./pages/WellnessCheckExternalQuestionnaire/JobsiteForms/ExternalForms";
import JobsiteSafetyForms from "./pages/WellnessCheckExternalQuestionnaire/JobsiteForms/JobsiteSafetyForms";
import {getAuthenticatedLayoutRoutes, getProjectLayoutRoutes} from "./routes";
import externalRoutes from "./routes/external";
import "./style.scss";
import {novoTheme} from "./theme";

let globalProps = {...window?.Sentinel, ...window?.Sentinel?.preloadedState};
globalProps["waffle"] = window?.waffle;
globalProps["userContact"] = window?.Sentinel?.userContact;
globalProps["userEmployee"] = window?.Sentinel?.userEmployee;
globalProps["adminObjects"] = window?.Sentinel?.adminObjects;
globalProps["isStaging"] = window?.Sentinel?.isStaging;
globalProps["messages"] = window?.Sentinel?.messages;
globalProps["sessionExpireDate"] = window?.Sentinel?.sessionExpireDate;
globalProps["apiKeys"] = {};
globalProps["apiKeys"]["googleMapsAPIKey"] = window?.Sentinel?.googleMapsAPIKey;
const {user, messages} = globalProps;
delete globalProps.preloadedState;

if (!globalProps.isDebug) {
  Sentry.init({
    dsn: "https://df41c2d0cae647d3bdc17abd93b6fc64@sentry.io/43916",
    release: __COMMIT_HASH__,
    // sendDefaultPii: true,
    beforeSend: (error) => {
      console.log(error);
      return error;
    },
  });
  if (user) {
    Sentry.getCurrentScope().setUser({
      email: user.email,
      id: user.id,
      name: user.full_name,
    });
  }
}

const GlobalStyle = createGlobalStyle`

`;

// https://github.com/siriwatknp/mui-treasury/blob/master/packages/mui-layout/src/presets/muiTreasury.ts
const scheme = Layout();
const queryClient = new QueryClient();

scheme.configureHeader((builder) => {
  builder
    .registerConfig("xs", {
      position: "sticky",
      initialHeight: 56,
    })
    .registerConfig("sm", {
      position: "sticky",
      initialHeight: 64,
      clipped: true,
    });
});

scheme.configureEdgeSidebar((builder) => {
  builder
    .create("primarySidebar", {
      anchor: "left",
    })
    .registerTemporaryConfig("xs", {
      width: 256,
    })
    .registerPermanentConfig("md", {
      width: 200,
      collapsible: true,
      collapsedWidth: 0,
    })
    .registerPermanentConfig("lg", {
      width: 256,
      collapsible: true,
      collapsedWidth: 0,
    });
});

const childRoutes = (Layout, routes) => {
  const result = routes.map(({children, path, component: Element}, index) => {
    return children ? (
      // Route item with children

      children.map(({path, component: Element}, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={<Layout {...globalProps}>{Element && <Element {...globalProps} />}</Layout>}
          />
        );
      })
    ) : (
      // Route item without children

      <Route
        key={index}
        path={path}
        element={<Layout {...globalProps}>{Element && <Element {...globalProps} />}</Layout>}
      />
    );
  });
  return result;
};

const ScrollToTop = () => {
  const {pathname} = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const CloseSidebar = () => {
  const {pathname} = useLocation();
  const {state, setOpen} = useSidebarTrigger("primarySidebar");
  React.useEffect(() => {
    setOpen("primarySidebar", false);
  }, [pathname]);
  return null;
};

// const UnblockUI = () => {
//   const {pathname} = useLocation();
//   const blockUI = useBlockUI();
//   React.useEffect(() => {
//     blockUI.unblockUI();
//   }, [pathname]);
//   return null;
// };

const RouterError = () => {
  const routerError = useRouteError();
  Sentry.withScope((scope) => {
    // scope.setExtra("componentStack", routerError.stack);
    Sentry.captureException(routerError);
  });

  return <GlobalFallback error={routerError} />;
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route element={<CloseSidebar />} />,
    <Route element={<ScrollToTop />} />,

    <Route errorElement={<RouterError />}>
      {childRoutes(
        AuthenticatedLayout,
        getAuthenticatedLayoutRoutes(user, globalProps.permissions, globalProps.waffle)
      )}
      {childRoutes(
        ProjectLayout,
        getProjectLayoutRoutes(user, globalProps.permissions, globalProps.waffle, globalProps.project || {})
      )}
      {childRoutes(
        UnauthenticatedLayout,
        getAuthenticatedLayoutRoutes(user, globalProps.permissions, globalProps.waffle)
      )}
      {childRoutes(UnauthenticatedLayout, externalRoutes)}
      <Route path="/v2/notifications/" element={<Notifications {...globalProps} />} />
      <Route path="/v2/login2/" element={<Login2 {...globalProps} />} />
      <Route path="/v2/unauthenticated/" element={<UnauthenticatedTest />} />
      <Route path="/pretask-plan/:pretaskPlanUUID/" element={<PretaskPlanExternalSurvey {...globalProps} />} />
      <Route
        path="/projects/:projectUUID/twilio-contact/:twilioContactUUID/certifications/"
        element={<ExternalTwilioContactCertifications {...globalProps} />}
      />
      <Route
        path="/projects/:projectUUID/twilio-contact/:twilioContactUUID/certifications/:certificationUUID/"
        element={<ExternalTwilioContactCertificationDetail {...globalProps} />}
      />
      <Route path="/twilio-contact/:twilioContactUUID/" element={<TwilioContactUpdate {...globalProps} />} />
      {/* <Route
        path="/projects/:projectUUID/twilio-contact/:twilioContactUUID/"
        element={<TwilioContactUpdate {...globalProps} />}
      /> */}
      <Route
        path="/projects/:projectUUID/twilio-contact/:twilioContactUUID/jobsite-documents/"
        element={<JobsiteFiles {...globalProps} />}
      />
      <Route
        path="/projects/:projectUUID/twilio-contact/:twilioContactUUID/safety/orientation/"
        element={<ExternalSafetyOrientation {...globalProps} />}
      />

      <Route
        path="/projects/:projectUUID/safety/permits/:formOrigin/:formUUID/"
        element={
          <UnauthenticatedLayout>
            <JobsiteSafetyForms {...globalProps} />
          </UnauthenticatedLayout>
        }
      />
      <Route
        path="/projects/:projectUUID/twilio-contact/:twilioContactUUID/safety/permits/"
        element={
          <UnauthenticatedLayout>
            <ExternalForms {...globalProps} />
          </UnauthenticatedLayout>
        }
      />

      <Route path="/wellness-check/:wellnessCheckUUID/" element={<WellnessCheckQuestionnaire {...globalProps} />} />
      <Route path="/v2/react/error/" element={<BuggyComponent />} />
      <Route
        path="*"
        element={
          <AuthenticatedLayout>
            <RouteNotFound />
          </AuthenticatedLayout>
        }
      />
    </Route>,
  ])
);

const App = () => {
  const [showBlockUI, setShowBlockUI] = React.useState(false);
  const [blockUIMessage, setBlockUIMessage] = React.useState("");
  return (
    <>
      <StylesProvider injectFirst>
        <ThemeProvider theme={novoTheme}>
          <AppContext.Provider
            value={{
              blockUI: (message?: string) => {
                setShowBlockUI(true);
                setBlockUIMessage(message || "");
              },
              unblockUI: () => setShowBlockUI(false),
              toggleBlockUI: () => setShowBlockUI((blockUI) => !blockUI),
              showBlockUI: showBlockUI,
              ...globalProps,
            }}
          >
            <SnackbarProvider maxSnack={3} dense anchorOrigin={{horizontal: "right", vertical: "top"}}>
              <QueryClientProvider client={queryClient}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Root scheme={scheme} theme={novoTheme}>
                    {(props) => {
                      // props.setOpen("primarySidebar", false);
                      return (
                        <>
                          <BlockUI show={showBlockUI} message={blockUIMessage} />
                          <CssBaseline />
                          <GlobalStyle />

                          <>
                            <ReactQueryGlobalLoader />
                            <RouterProvider router={router} />

                            {user?.is_superuser && <ReactQueryDevtools />}
                            {messages && <DjangoMessages messages={messages} />}
                            {user?.is_impersonate && (
                              <Snackbar open anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
                                <Alert
                                  severity="warning"
                                  // variant="filled"
                                  action={
                                    <Button color="inherit" size="small" href="/impersonate/stop/">
                                      Stop Impersonation
                                    </Button>
                                  }
                                >
                                  You are impersonating {user.full_name}.
                                </Alert>
                              </Snackbar>
                            )}
                          </>
                        </>
                      );
                    }}
                  </Root>
                </MuiPickersUtilsProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </AppContext.Provider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("react-root")!);
root.render(<App />);

if (module.hot) {
  module.hot.accept();
}
