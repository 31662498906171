import {faElevator, faUpRightFromSquare} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Chip, Grid, IconButton, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {isEmpty} from "lodash";
import React from "react";
import {FieldArray} from "react-final-form-arrays";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import PaperPanel from "../../../components/PaperPanel";
import {
  MultipleAutocompleteMui,
  NumberFieldMui,
  SimpleSelect,
  SingleAutocompleteMui,
  TextFieldMui,
  YesNoRadios,
} from "../../../components/forms/Fields";
import JobsiteSafetyForm, {EquipmentFailureCounts} from "./JobsiteFormComponents";
import useBuildField from "./useBuildField";
import {buildQuestionObjects} from "./utils";

export const ForkliftInspection = (props) => {
  const {
    safetyForm,
    internalDetailView,
    postCurrentPage,
    recentEquipmentCount,
    operatorOptions = [],
    certifications = [],
  } = props;
  const certificationsEnabled = safetyForm.certifications_enabled;
  const isSpanish = !internalDetailView && safetyForm?.twilio_contact?.language === "SP";
  const isReadOnly = internalDetailView || safetyForm.is_closed;
  const [copyRecentConfirmationIsOpen, setCopyRecentConfirmationIsOpen] = React.useState(false);

  const addEquipmentCallback = React.useRef(() => {});

  const buildField = useBuildField(isSpanish, isReadOnly, {
    yesLabel: isSpanish ? `Aprobar` : `Pass`,
    noLabel: isSpanish ? `Suspender` : `Fail`,
  });

  const [preStartItems, startUpItems, functionCheckItems] = ["pre_start", "start_up", "function_check"].map((name) =>
    buildQuestionObjects(safetyForm, name, YesNoRadios)
  );

  const generalFields = buildQuestionObjects(safetyForm, "general", YesNoRadios, (field) => {
    field.yesLabel = isSpanish ? `Si` : `Yes`;
    field.noLabel = "No";
  });

  const equipmentInspectionValidation = (value, values, meta) => {
    const [nameSpace, fieldName] = meta.name.split(".");
    if (value === undefined) return "Required";
    if (value === false && isEmpty(values.failures[fieldName]))
      return isSpanish
        ? "Debe seleccionar al menos una pieza de equipo"
        : "Must select at least one piece of equipment";
    if (value === true) {
      values.failures[fieldName] = [];
    }
  };

  if (!isReadOnly && operatorOptions?.length === 0 && safetyForm.twilio_contact?.fuzzy_company) {
    return <BlockUI show={true} message="Fetching Data..." />;
  }

  return (
    <JobsiteSafetyForm
      title={isSpanish ? "Inspección previa al uso del montacargas" : "Forklift Permit"}
      initialValues={{equipment: [{}], failures: {}}}
      {...props}
    >
      {(form, values) => {
        [preStartItems, startUpItems, functionCheckItems].forEach((itemList) => {
          itemList.forEach((field) => {
            if (values.failures[field.name] === undefined) {
              form.change(`failures.${field.name}`, []);
            }
          });
        });

        const failedItemOptions = values.equipment
          .map((equipment, index) => equipment?.equipment_number)
          .filter((number) => number);
        const failingEquipment = new Set(Object.values(values.failures).flat());
        return (
          <>
            {!internalDetailView && (
              <Box textAlign="center">
                <EquipmentFailureCounts
                  equipmentCount={values.equipment.length}
                  failureCount={failingEquipment.size}
                  formType={safetyForm.form_type}
                />
              </Box>
            )}
            {recentEquipmentCount > 0 && !isReadOnly && (
              <Box my={1}>
                <Button
                  size="small"
                  onClick={() => {
                    setCopyRecentConfirmationIsOpen(true);
                  }}
                  variant="outlined"
                  fullWidth
                  color="primary"
                  disabled={isReadOnly}
                >
                  <Box mr={1}>
                    <Chip size="small" label={recentEquipmentCount} />
                  </Box>
                  {isSpanish ? `Copiar el equipo de la última inspección` : `Copy Equipment From Last Inspection`}
                </Button>
              </Box>
            )}
            <Grid item xs={12}>
              <PaperPanel py={1}>
                <PaperPanel.Header>
                  <PaperPanel.Header.Title>
                    <FontAwesomeIcon icon={faElevator} /> {isSpanish ? `Equipo` : `Equipment`} (
                    {values.equipment.length})
                  </PaperPanel.Header.Title>
                  <PaperPanel.Header.Actions>
                    {!internalDetailView && (
                      <PaperPanel.Header.Action>
                        <PaperPanel.Header.CreateButton
                          onClick={() => {
                            addEquipmentCallback.current();
                          }}
                          disabled={isReadOnly}
                        >
                          {isSpanish ? `Agregar Equipo` : `Add Equipment`}
                        </PaperPanel.Header.CreateButton>
                      </PaperPanel.Header.Action>
                    )}
                  </PaperPanel.Header.Actions>
                </PaperPanel.Header>
                <PaperPanel.Body>
                  <FieldArray name={`equipment`}>
                    {({fields}) => {
                      addEquipmentCallback.current = () => fields.push(undefined);
                      return (
                        <div>
                          {fields.map((name, i) => {
                            const operatorsInUse = fields.value
                              .map((value) => value?.operator)
                              .filter((value) => value);
                            const selectedOperatorName = fields.value[i]?.operator;

                            const certificationOptions = certifications
                              .map((certification) => ({
                                label: `${certification.twilio_contact.name} - ${certification.display}`,
                                value: certification.id,
                                name:
                                  certification.twilio_contact.name.trim() === selectedOperatorName
                                    ? selectedOperatorName
                                    : "Other",
                              }))
                              .filter((certification) => certification.name === selectedOperatorName)
                              .sort((a, b) => {
                                if (a.name === selectedOperatorName && b.name !== selectedOperatorName) {
                                  return -1;
                                }
                                if (a.name !== selectedOperatorName && b.name === selectedOperatorName) {
                                  return 1;
                                }
                                return 0;
                              });

                            return (
                              <React.Fragment key={name}>
                                {i !== 0 && <Box mt={1} />}
                                <LabeledGroup
                                  label={`# ${i + 1}`}
                                  pt={1}
                                  error={failingEquipment.has(fields.value[i]?.equipment_number)}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={certificationsEnabled ? 2 : 3}>
                                      <TextFieldMui
                                        name={`${name}.model`}
                                        label={`${isSpanish ? `Modelo` : `Model`}`}
                                        size="small"
                                        required
                                        disabled={isReadOnly}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={certificationsEnabled ? 2 : 3}>
                                      <TextFieldMui
                                        name={`${name}.equipment_number`}
                                        label={`${isSpanish ? `Número de Equipo` : `Equipment number`}`}
                                        size="small"
                                        required
                                        disabled={isReadOnly}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={certificationsEnabled ? 2 : 3}>
                                      <NumberFieldMui
                                        name={`${name}.hour_meter`}
                                        label={isSpanish ? `Contador de Horas` : `Hour Meter`}
                                        disabled={isReadOnly}
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      {isReadOnly ? (
                                        <>
                                          <TextFieldMui
                                            name={`${name}.operator`}
                                            label={isSpanish ? `Operador` : `Operator`}
                                            disabled={isReadOnly}
                                            required
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <SingleAutocompleteMui
                                            name={`${name}.operator`}
                                            label={isSpanish ? `Operador` : `Operator`}
                                            options={operatorOptions}
                                            required
                                            disabled={isReadOnly}
                                            renderOption={(option) => {
                                              return (
                                                <Box display="flex" alignItems="center">
                                                  <Box mr={1}>{option.label}</Box>
                                                  {operatorsInUse.includes(option.value) && (
                                                    <span>{isSpanish ? `(Ya Asignado)` : `(Already Assigned)`}</span>
                                                  )}
                                                </Box>
                                              );
                                            }}
                                            getOptionDisabled={(option) => {
                                              return operatorsInUse.includes(option.value);
                                            }}
                                            freeSolo
                                            onSelect={() => {
                                              if (!certificationsEnabled) return;
                                              const certificationValues = form.getFieldState(
                                                `${name}.certification`
                                              ).value;
                                              if (certificationOptions.length === 0) {
                                                form.change(`${name}.certification`, undefined);
                                              } else {
                                                if (certificationValues === undefined) {
                                                  form.change(`${name}.certification`, certificationOptions[0].value);
                                                }
                                              }
                                            }}
                                          />
                                        </>
                                      )}
                                    </Grid>
                                    {certificationsEnabled && (
                                      <>
                                        <Grid item xs={12} sm={3}>
                                          {internalDetailView ? (
                                            <>
                                              {values["equipment"][i]?.certification ? (
                                                <Button
                                                  href={`/v2/safety/certifications/${values["equipment"][i]?.certification}/`}
                                                  target="_blank"
                                                  underline="always"
                                                  variant="contained"
                                                  endIcon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                                                  size="small"
                                                >
                                                  View Certification
                                                </Button>
                                              ) : (
                                                <Button disabled variant="contained">
                                                  No Certification Selected
                                                </Button>
                                              )}
                                            </>
                                          ) : (
                                            <SimpleSelect
                                              name={`${name}.certification`}
                                              label={isSpanish ? `Certificación` : `Certification`}
                                              options={certificationOptions}
                                              disabled={certificationOptions.length === 0 || isReadOnly}
                                            />
                                          )}
                                        </Grid>
                                      </>
                                    )}

                                    <Grid item xs={12}>
                                      <Box textAlign={"right"}>
                                        <IconButton
                                          aria-label="delete"
                                          size="small"
                                          onClick={() => fields.remove(i)}
                                          disabled={values.equipment.length < 2 || isReadOnly}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </LabeledGroup>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      );
                    }}
                  </FieldArray>
                </PaperPanel.Body>
              </PaperPanel>
              <Box mb={3} />
            </Grid>
            <Grid container spacing={2}>
              {generalFields.map((field) => (
                <Grid item xs={12} sm={6} key={field.name}>
                  {/* {buildField("general", field)} */}
                  {buildField("general", {
                    ...field,
                    fieldProps: {
                      validate: equipmentInspectionValidation,
                    },
                  })}
                  {values?.general?.[field.name] === false && (
                    <MultipleAutocompleteMui
                      name={`failures.${field.name}`}
                      label={isSpanish ? `Notas` : `Equipment`}
                      multiline
                      disabled={isReadOnly}
                      options={failedItemOptions}
                      fieldProps={{validateFields: [`general.${field.name}`]}}
                      required
                    />
                  )}
                </Grid>
              ))}

              <Grid item xs={12}>
                <TextFieldMui
                  name="general.comments"
                  label={isSpanish ? "Comentarios:" : "Comments:"}
                  multiline
                  minRows={2}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">{isSpanish ? `Pre inicio` : `Pre-Start`}</Typography>
                </Box>
              </Grid>

              {preStartItems.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {/* {buildField("pre_start", field)} */}
                  {buildField("pre_start", {
                    ...field,
                    fieldProps: {
                      validate: equipmentInspectionValidation,
                    },
                  })}
                  {values?.pre_start?.[field.name] === false && (
                    <MultipleAutocompleteMui
                      name={`failures.${field.name}`}
                      label={isSpanish ? `Notas` : `Equipment`}
                      multiline
                      disabled={isReadOnly}
                      options={failedItemOptions}
                      fieldProps={{validateFields: [`pre_start.${field.name}`]}}
                      required
                    />
                  )}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">{isSpanish ? `Puesta en Marcha` : `Start-Up`}</Typography>
                </Box>
              </Grid>

              {startUpItems.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {/* {buildField("start_up", field)}
                  {field.showComment} */}
                  {buildField("start_up", {
                    ...field,
                    fieldProps: {
                      validate: equipmentInspectionValidation,
                    },
                  })}
                  {values?.start_up?.[field.name] === false && (
                    <MultipleAutocompleteMui
                      name={`failures.${field.name}`}
                      label={isSpanish ? `Notas` : `Equipment`}
                      multiline
                      disabled={isReadOnly}
                      options={failedItemOptions}
                      fieldProps={{validateFields: [`start_up.${field.name}`]}}
                      required
                    />
                  )}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">{isSpanish ? `Control de Funcionamiento` : `Function Check`}</Typography>
                </Box>
              </Grid>

              {functionCheckItems.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {/* {buildField("function_check", field)}
                  {field.showComment} */}
                  {buildField("function_check", {
                    ...field,
                    fieldProps: {
                      validate: equipmentInspectionValidation,
                    },
                  })}
                  {values?.function_check?.[field.name] === false && (
                    <MultipleAutocompleteMui
                      name={`failures.${field.name}`}
                      label={isSpanish ? `Notas` : `Equipment`}
                      multiline
                      disabled={isReadOnly}
                      options={failedItemOptions}
                      fieldProps={{validateFields: [`function_check.${field.name}`]}}
                      required
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <ConfirmationDialog
              isOpen={copyRecentConfirmationIsOpen}
              onDeny={() => {
                setCopyRecentConfirmationIsOpen(false);
              }}
              onApprove={() => {
                form.pauseValidation();
                postCurrentPage
                  .mutateAsync({answers: values, action: "copy_recent_equipment"})
                  .then(() => {
                    setCopyRecentConfirmationIsOpen(false);
                  })
                  .then(() => {
                    form.resumeValidation();
                    setCopyRecentConfirmationIsOpen(false);
                    window.location.reload();
                  });
              }}
              title={isSpanish ? `Copiar equipo de la última inspección` : `Copy equipment from last inspection`}
            >
              {isSpanish ? (
                <>
                  ¿Copiar información del equipo de su última inspección? Esto borrará cualquier equipo que haya
                  ingresado.
                </>
              ) : (
                <>
                  Copy equipment information from your last inspection? This will erase any equipment you have entered.
                </>
              )}
            </ConfirmationDialog>
          </>
        );
      }}
    </JobsiteSafetyForm>
  );
};
