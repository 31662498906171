import {faCheck, faClock, faComment, faFilePdf, faLink, faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {ImportanceLabel} from "../../../components/ImportanceLabel";
import {LabelValue} from "../../../components/LabelValue";
import {MuiNavLink} from "../../../components/Links";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {StatusLabel} from "../../../components/Status";
import {TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import safetyReviewQuestionStatusChoices from "../../../components/forms/choices/safetyReviewQuestionStatusChoices.json";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {makeNovoClasses} from "../../../theme";
import {colorError, colorInfo, colorNovo, colorSuccess, getStatusColor} from "../../../theme/colors";
import {SectionHeader} from "../ProjectReviews/ProjectReviewForms";
import {RemediationDialogForm} from "./SafetyReviewForms";

const MOMENTFORMAT = "YYYY-MM-DD h:mm a";

export const Thumbnail = (props) => {
  const {thumbnail, attachment, height = 100, width = 100, onClickThumbnail, ...rest} = props;
  return (
    <Box onClick={onClickThumbnail} style={{cursor: "pointer"}}>
      <Box mb={-1}>
        <img
          src={
            Boolean(thumbnail.thumbnail_base64)
              ? `data:image/png;base64, ${thumbnail.thumbnail_base64}`
              : "/static/img/image_not_found.jpg"
          }
          height={height}
          width={width}
          style={{objectFit: "contain"}}
        />
      </Box>
      {attachment.description && (
        <Box mt={-3} display="flex">
          <Tooltip title={attachment?.description ?? ""}>
            <Box style={{background: colorNovo, borderRadius: "50%", padding: "2px", marginTop: "-2px"}} display="flex">
              <FontAwesomeIcon icon={faComment} />
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export const ThumbnailDetailDialog = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  thumbnail: any;
  readOnly?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, thumbnail, readOnly, ...rest} = props;
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;

          return (
            <>
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Attachment Detail</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box textAlign="center">
                          <Link href={thumbnail.download_url} target="_blank">
                            <img
                              src={
                                Boolean(thumbnail.thumbnail_base64)
                                  ? `data:image/png;base64, ${thumbnail.thumbnail_base64}`
                                  : "/static/img/image_not_found.jpg"
                              }
                              style={{maxWidth: "100%", height: "auto", cursor: "pointer"}}
                            />
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="name" label="Name" autoFocus disabled={readOnly} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Description" disabled={readOnly} />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {!readOnly && (
                    <>
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <FormActions.DeleteButton onClick={onDelete} />
                    </>
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            </>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export const QuestionDetailReadOnly = (props) => {
  const {
    question,
    answerAttachments,
    questionAttachments,
    onClickThumbnail,
    detailLink,
    review,
    refreshAttachments,
    refreshQuestions,
    ...rest
  } = props;

  const inCompliance = question.answer == "yes";
  const [showRemediationDialog, setShowRemediationDialog] = React.useState(false);
  const [showEmailConfirmationDialog, setShowEmailConfirmationDialog] = React.useState(false);
  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const {enqueueSnackbar} = useSnackbar();

  return (
    <>
      <SectionHeader mx={2} mt={-2}>
        <Box>
          <StatusLabel status={question.status_display} width={120} />
          <Box mr={1} component="span" />
          <Typography variant="h6" component="span">
            {question.question}
          </Typography>
          <Box ml={1}>
            {question.regulations.length > 0 && `${pluralize("Regulation", question.regulations.length)}:`}
            {question.regulations.map((regulation) => (
              <Box key={regulation.regulation} component="span" mx={1}>
                <Link href={regulation?.link} underline="always">
                  {regulation.regulation}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          {detailLink && (
            <MuiNavLink href={detailLink}>
              <FontAwesomeIcon icon={faLink} />
            </MuiNavLink>
          )}
          <Box mr={1} component="span" />
          {permissions.can_change_safety_reviews && question.can_respond && question?.assigned_to?.full_name && (
            <Tooltip title="Send Reminder Email">
              <Link onClick={() => setShowEmailConfirmationDialog(true)} style={{cursor: "pointer"}}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </Link>
            </Tooltip>
          )}
        </Box>
      </SectionHeader>

      {question.can_respond && (
        <Box textAlign="right">
          <Button variant="contained" onClick={() => setShowRemediationDialog(true)} color="secondary">
            Respond
          </Button>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Reviewer Findings:</Typography>
        </Grid>
        <Grid item xs={12} lg={3} sm={4}>
          <LabelValue label="Answer" value={question.answer_display} />
        </Grid>

        {!inCompliance && (
          <>
            <Grid item xs={12} lg={3} sm={4}>
              <LabelValue label="Responsible Company" value={question?.responsible_company?.name} />
            </Grid>
            {!question.corrected && (
              <>
                <Grid item xs={12} lg={3} sm={4}>
                  <LabelValue label="Assigned to" value={question?.assigned_to?.full_name} />
                </Grid>

                <Grid item xs={12} lg={3} sm={4}>
                  <LabelValue label="Due Date" value={question?.due_date} />
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={3} sm={4}>
              {question.answer === "no" && (
                <LabelValue label="Severity" value={<ImportanceLabel importance={question?.severity_display} />} />
              )}
            </Grid>

            <Grid item xs={12} lg={3} sm={4}>
              {/* <LabelValue
                label="Repeat"
                value={question.is_repeat ? <CheckBox /> : <CheckBoxOutlineBlank />}
                display="flex"
              /> */}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <LabelValue label="Comment" value={question.comment} />
        </Grid>
        {questionAttachments.length > 0 && (
          <>
            <Grid item xs={12}>
              <strong>{pluralize("Attachment", questionAttachments.length)}:</strong>
            </Grid>
            {questionAttachments.map((attachment) => {
              const {thumbnail} = attachment;
              return (
                thumbnail && (
                  <Grid item key={attachment.id}>
                    <Box mb={2}>
                      <Thumbnail
                        thumbnail={thumbnail}
                        attachment={attachment}
                        onClickThumbnail={(event) => {
                          onClickThumbnail(attachment);
                        }}
                      />
                    </Box>
                  </Grid>
                )
              );
            })}
          </>
        )}
      </Grid>

      {question.status === "noncompliant_corrected" && (
        <>
          <Box my={1}>
            <Divider />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Response:</Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <LabelValue
                label="Action Type"
                value={
                  question.value === "preventative_improvement_opportunity"
                    ? "Preventative / Improvement Opportunity"
                    : "Corrective / Nonconformity"
                }
              /> */}
              <LabelValue
                value={moment(question.remediated_datetime).format(MOMENTFORMAT)}
                label="Date/Time Corrected"
              />
            </Grid>
            <Grid item xs={12}>
              <LabelValue value={question.action_taken} label="Action Taken" />
            </Grid>

            <Grid item xs={12}>
              <LabelValue value={question.action_description} label="Action Description" />
            </Grid>
            {answerAttachments.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">{pluralize("Attachment", answerAttachments.length)}</Typography>
                </Grid>
                {answerAttachments.map((attachment) => {
                  const {thumbnail} = attachment;

                  return (
                    thumbnail && (
                      <Grid item key={attachment.id}>
                        <Thumbnail
                          thumbnail={thumbnail}
                          attachment={attachment}
                          onClickThumbnail={(event) => {
                            onClickThumbnail(attachment);
                          }}
                        />
                        <Box mb={1} />
                      </Grid>
                    )
                  );
                })}
              </>
            )}
          </Grid>
        </>
      )}
      <RemediationDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          axiosAPI
            .post(
              `/projects/${review?.project?.id}/safety/project-safety-reviews/${review?.id}/questions/${question?.id}/remediate/`,
              values
            )
            .then(() =>
              refreshQuestions().then(() => {
                setShowRemediationDialog(false);
                blockUI.unblockUI();
              })
            );
        }}
        isOpen={showRemediationDialog}
        handleClose={() => setShowRemediationDialog(false)}
        review={review}
        activeQuestion={question}
        projectId={review?.project?.id}
        initialValues={question}
        questionAttachments={questionAttachments}
        answerAttachments={answerAttachments}
        refreshAttachments={refreshAttachments}
      />
      <ConfirmationDialog
        title="Send Reminder Email"
        isOpen={showEmailConfirmationDialog}
        onDeny={() => setShowEmailConfirmationDialog(false)}
        onApprove={() => {
          axiosAPI
            .post(
              `projects/${review.project.id}/safety/project-safety-reviews/${review.id}/questions/${question.id}/send-reminder/`
            )
            .then((response) => {
              enqueueSnackbar(response.data, {variant: "success"});
              setShowEmailConfirmationDialog(false);
            });
        }}
      >
        Do you want to send a reminder email to {question?.assigned_to?.full_name}?
      </ConfirmationDialog>
    </>
  );
};

export const NonCompliantItemCountLabel = (props: {nonCompliantItems: number; status: "pending" | "complete"}) => {
  const {nonCompliantItems, status} = props;
  if (status === "pending") {
    return (
      <Tooltip title="Safety Review Incomplete">
        <FontAwesomeIcon icon={faClock} color={colorInfo} />
      </Tooltip>
    );
  }
  return nonCompliantItems === 0 ? (
    <Tooltip title="All observations in compliance">
      <FontAwesomeIcon icon={faCheck} color={colorSuccess} />
    </Tooltip>
  ) : (
    <span style={{color: colorError}}>{nonCompliantItems}</span>
  );
};

const StatusCountLabel = (props: {statusCounts: {}}) => {
  const questionStatusLookup = safetyReviewQuestionStatusChoices.reduce((result, item) => {
    result[item.value] = item.label;
    return result;
  }, {});

  const {statusCounts = {}} = props;
  const filteredStatuses = Object.fromEntries(Object.entries(statusCounts).filter(([_, value]) => value !== 0));
  const renderedContent = Object.entries(filteredStatuses).map(([_key, value]) => {
    const key = _key.split("_count")[0];
    return (
      <Box color={getStatusColor(questionStatusLookup[key])} key={key}>
        {questionStatusLookup[key]}: {value}
      </Box>
    );
  });
  return renderedContent;
};

export const PSRTable = (props: {safetyReviews: {}; showComments?: boolean}) => {
  const {safetyReviews = {}, showComments = false, ...rest} = props;
  const [showSendReminderDialog, setShowSendReminderDialog] = React.useState(false);
  const [activeSafetyReview, setActiveSafetyReview] = React.useState({noncompliant_count: 0} as {
    id: number;
    noncompliant_count: number;
    project: {id: number};
  });
  const novoClasses = makeNovoClasses();
  const {enqueueSnackbar} = useSnackbar();
  const MOMENTFORMAT = "YYYY-MM-DD h:mm a";
  const permissions = usePermissions();

  return (
    <>
      <ReactTableMui
        size="small"
        className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
        // getHeaderProps={(column) => {
        //   console.log(column);
        //   if (["project.display"].includes(column.id)) {
        //     return {
        //       style: {
        //         width: "200px",
        //       },
        //     };
        //   }
        // }}
        columns={[
          {
            Header: "Status",
            accessor: "status_display",
            Cell: ({row, value}) => <StatusLabel status={row.original.status_display} hint="projectSafetyReview" />,
          },
          {
            Header: "Review Date/Time",
            accessor: "review_datetime",
            Cell: ({row, value}) => (
              <Link href={row.original.url} underline="always">
                {moment(value).format(MOMENTFORMAT)}
              </Link>
            ),
          },
          {Header: "Project", accessor: "project.display"},
          {
            Header: "Reviewed By",
            accessor: "created_by.full_name",
          },
          {Header: "Super", accessor: "superintendent.full_name"},
          {Header: "Score", accessor: "score"},

          {
            Header: "Noncompliant Items",
            accessor: "noncompliant_count",
            Cell: ({row, value}) => {
              return (
                <Box textAlign="center" maxWidth={120}>
                  <NonCompliantItemCountLabel nonCompliantItems={value} status={row.original.status} />
                </Box>
              );
            },
          },
          {
            Header: "Counts",
            accessor: "status_counts",
            Cell: ({row, value}) => {
              return <StatusCountLabel statusCounts={value} />;
            },
          },
          {Header: "Comments", accessor: "comments"},
          {
            Header: "",
            id: "pdf",
            Cell: ({row}) => {
              const noncompliant = row.original.noncompliant_count && row.original.status !== "pending";
              return (
                <Box display="flex">
                  <span>
                    <Link target="_blank" href={row.original.pdf_url}>
                      <FontAwesomeIcon icon={faFilePdf} fixedWidth />
                    </Link>
                  </span>
                  {!!noncompliant && permissions.can_change_safety_reviews && (
                    <Tooltip title={"Send Reminders"}>
                      <span>
                        <Link
                          onClick={() => {
                            setActiveSafetyReview(row.original);
                            setShowSendReminderDialog(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
                        </Link>
                      </span>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
        ]}
        data={safetyReviews}
        initialState={{
          hiddenColumns: showComments ? [] : ["comments"],
          sortBy: [
            {
              id: "review_datetime",
              desc: true,
            },
          ],
        }}
      />
      <ConfirmationDialog
        title="Send Reminder Emails"
        isOpen={showSendReminderDialog}
        onDeny={() => setShowSendReminderDialog(false)}
        onApprove={() => {
          axiosAPI
            .post(
              `projects/${activeSafetyReview.project.id}/safety/project-safety-reviews/${activeSafetyReview.id}/send-reminders/`
            )
            .then((response) => {
              enqueueSnackbar(response.data, {variant: "success"});
              setShowSendReminderDialog(false);
            });
        }}
      >
        You want to send email reminders for {activeSafetyReview?.noncompliant_count} noncompliant{" "}
        {pluralize("item", activeSafetyReview?.noncompliant_count)}?
      </ConfirmationDialog>
    </>
  );
};
