import {faLock} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, makeStyles, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React from "react";
import {useQuery} from "react-query";
import {useDebounce} from "use-debounce";
import useBlockUI from "../hooks/useBlockUI";
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate";
import {ProjectStatusIcon} from "./Icons";

const useGlobalSearchStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    paper: {
      fontSize: 11,
    },
    groupLabel: {
      paddingTop: 3,
      paddingBottom: 3,
      lineHeight: "inherit",
    },
    option: {
      display: "block",
      paddingTop: 3,
      paddingBottom: 3,
    },
    // secondaryText: {
    //   color: palette.text.hint,
    // },
  };
});

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    root: {},
    secondaryText: {
      color: palette.text.hint,
    },
  };
});

const GlobalSearch = (props) => {
  useWhyDidYouUpdate("GlobalSearch", props);
  // const {textFieldProps, ...rest} = props;
  const [query, setQuery] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [debouncedQuery] = useDebounce(query, 300);
  const shouldQuery = debouncedQuery.length >= 3;
  const classes = useStyles();
  const blockUI = useBlockUI();

  const searchQuery = useQuery(
    ["globalSearch", debouncedQuery],
    () => {
      if (!shouldQuery) return [];
      const query = encodeURIComponent(debouncedQuery);
      return axios
        .all([
          axios.get(`/api/v1/projects/search/?q=${query}&ordering=status_sort&page_size=5`),
          axios.get(`/api/v1/companies/search/?q=${query}&page_size=5`),
          axios.get(`/api/v1/contacts/search/?q=${query}&page_size=5`),
        ])
        .then(
          axios.spread((projects, companies, contacts) => {
            return [
              ...projects.data.results.map((project) => {
                project.group = "Projects";
                project.group_sort = 1;
                // project.url = startsWith(project.url, "/v2") ? `${project.url}` : `/v2${project.url}`;
                project.url = `${project.url}`;
                return project;
              }),
              ...companies.data.results.map((company) => {
                company.group = "Companies";
                company.group_sort = 2;
                return company;
              }),
              ...contacts.data.results.map((contact) => {
                contact.group = "Contacts";
                contact.group_sort = 3;
                return contact;
              }),
            ];
          })
        );
    },
    {
      initialData: [],
      retry: false,
      // staleTime: 60 * 1000,
      enabled: shouldQuery,
    }
  );
  const {data: options, isFetching} = searchQuery;
  return (
    <>
      <Autocomplete
        // id="global-search"
        classes={useGlobalSearchStyles()}
        options={options}
        // open={true}
        getOptionLabel={(option: any) => option.display || option.name || option.full_name}
        // inputValue={query}
        disabled={isDisabled}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Sentinel Search"
            variant="outlined"
            size="small"
            // {...textFieldProps}
          />
        )}
        groupBy={(option: any) => option.group}
        renderOption={(option: any) => {
          if (option.group === "Projects") {
            return (
              <Box display="flex">
                <Box flexGrow={1}>
                  <ProjectStatusIcon project={option} /> {option.display}
                </Box>

                {!option.user_has_access && (
                  <Box textAlign="right" flexGrow={0}>
                    <FontAwesomeIcon icon={faLock} />
                  </Box>
                )}
              </Box>
            );
          }
          if (option.group === "Companies") {
            return (
              <>
                <div>{option.name}</div>
                <div className={classes.secondaryText}>
                  {option.company_role_description} - {option.lookup_code}
                </div>
              </>
            );
          }
          if (option.group === "Contacts") {
            return (
              <>
                <div>{option.full_name}</div>
                <div className={classes.secondaryText}>{option.company?.name}</div>
              </>
            );
          }
          return <>Project {option.display}</>;
        }}
        onChange={(event, value: {url: string}) => {
          setQuery("");
          blockUI.blockUI();
          setIsDisabled(true);
          window.location.href = value.url;
        }}
        noOptionsText={
          isFetching
            ? "Searching..."
            : shouldQuery
              ? `No matches found for ${debouncedQuery}`
              : `Enter at least 3 characters...`
        }
        onInputChange={(event, value) => {
          setQuery(value);
        }}
        filterOptions={(options) => options}
        popupIcon={false}
        disableClearable
        {...props}
      />
    </>
  );
};

export default GlobalSearch;
