import {faCamera, faFileUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, FormLabel, Grid} from "@material-ui/core";
import moment from "moment";
import React from "react";
import {isMobile} from "react-device-detect";
import {Field, Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {UploadFileButton} from "../../components/Buttons";
import {ConfirmationDialog} from "../../components/Dialogs";
import {DatePickerMui, SimpleSelect, TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import certificationTypeChoices from "../../components/forms/choices/twilioContactCertificationTypes.json";

export const ExternalTwilioContactCertificationForm = (props: {
  onSubmit: (values: any) => void;
  onUploadThumbnail?: (file: File) => Promise<void>;
  onDelete?: () => void;
  isReadOnly?: boolean;
  thumbnails: any[];
  isSpanish?: boolean;
  certifications?: any[];
  thumbnailsFetched?: boolean;
  [rest: string]: any;
}) => {
  const {
    onSubmit,
    isReadOnly,
    thumbnails,
    onUploadThumbnail,
    isSpanish,
    certifications,
    thumbnailsFetched,
    onDelete,
    ...rest
  } = props;
  const [hasUploadedFile, setHasUploadedFile] = React.useState(false);
  const [submitConfirmationDialogIsOpen, setSubmitConfirmationDialogIsOpen] = React.useState(false);
  const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] = React.useState(false);
  const replacesCertificationChoices = certifications
    .filter((certification) => certification.uuid !== props.initialValues.uuid)
    .map((certification) => ({
      value: certification.uuid,
      label: `${certification.display_with_expiration}`,
    }));

  const includesThumbnail = thumbnailsFetched && (hasUploadedFile || thumbnails.length > 0);

  return (
    <FinalForm onSubmit={onSubmit} mutators={{validate: () => {}}} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, hasValidationErrors, errors, submitFailed} = props;
        React.useEffect(() => {
          form.mutators.validate();
        }, [includesThumbnail]);

        return (
          <>
            {/* <BeforeUnload block={!pristine} /> */}
            <form onSubmit={handleSubmit} autoComplete="off" noValidate={true}>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="twilio_contact.name" label={isSpanish ? "Nombre" : "Name"} disabled />
                  </Grid>
                  {values.created_by_other && (
                    <Grid item xs={12}>
                      <TextFieldMui
                        name="certification_created_by.name"
                        label={isSpanish ? "Certificación Creada Por" : "Certification Created By"}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SimpleSelect
                      name="certification_type"
                      label={isSpanish ? "Tipo de Certificación" : "Certification Type"}
                      options={certificationTypeChoices}
                      // disabled={isReadOnly}
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePickerMui
                      name="issue_date"
                      label={isSpanish ? "Fecha de Emisión" : "Issue Date"}
                      disabled={isReadOnly}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePickerMui
                      name="expiration_date"
                      label={isSpanish ? "Fecha de Expiración" : "Expiration Date"}
                      disablePast
                      disabled={isReadOnly}
                      helperText={
                        isSpanish
                          ? "Dejar en blanco si esta certificación no expira"
                          : "Leave blank if this certification does not expire"
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui
                      name="notes"
                      label={isSpanish ? "Notas" : "Notes"}
                      multiline
                      rows={4}
                      disabled={isReadOnly}
                    />
                  </Grid>
                  {replacesCertificationChoices.length > 0 && !isReadOnly && values.status !== "revise_resubmit" && (
                    <Grid item xs={12}>
                      <SimpleSelect
                        name="replaces_certification_uuid"
                        label={isSpanish ? "Reemplaza Certificación" : "Replaces Certification"}
                        options={replacesCertificationChoices}
                        disabled={isReadOnly}
                        helperText={
                          isSpanish
                            ? "Seleccione la certificación que ésta reemplaza (Opcional)"
                            : "Select the certification that this one replaces (Optional)"
                        }
                      />
                    </Grid>
                  )}

                  <Field
                    name="thumbnail_was_uploaded"
                    component="input"
                    type="hidden"
                    validate={(value, allValues, meta) => {
                      if (!includesThumbnail) {
                        return isSpanish
                          ? "Por favor, cargue una copia de la certificación"
                          : "Please Upload a Copy of the Certification";
                      }
                    }}
                  />

                  {thumbnails.map((thumbnail) => {
                    return (
                      <Grid item xs={6} key={thumbnail.id}>
                        <Thumbnail
                          thumbnail={thumbnail}
                          onClickThumbnail={() => window.open(thumbnail.download_url, "_blank")}
                        />
                      </Grid>
                    );
                  })}
                  {/* {!isReadOnly && (
                    <Grid item xs={12}>
                      <DropzoneMui
                        getUploadParams={() => {
                          return {
                            url: `.`,
                            // headers: {"X-CSRFToken": Cookies.get("csrftoken")},
                          };
                        }}
                        timeout={1000 * 60 * 5}
                        accept="image/*,application/pdf"
                        onChangeStatus={(event) => {
                          if (["done"].includes(event.meta.status)) {
                            setHasUploadedFile(true);
                            form.mutators.validate();
                          }
                        }}
                      />
                      {submitFailed && hasValidationErrors && !includesThumbnail && (
                        <FormLabel error={true}>{errors.thumbnail_was_uploaded}</FormLabel>
                      )}
                    </Grid>
                  )} */}

                  {!isReadOnly && isMobile && (
                    <Grid item xs={12}>
                      <UploadFileButton
                        accept="image/*"
                        onUpload={(fileList) => {
                          onUploadThumbnail(fileList).then(() => {
                            setHasUploadedFile(true);
                            form.mutators.validate();
                          });
                        }}
                        capture="environment"
                        name="camera_capture"
                        startIcon={<FontAwesomeIcon icon={faCamera} />}
                        fullWidth
                      >
                        {isSpanish ? "Capturar con la Cámara" : "Capture With Camera"}
                      </UploadFileButton>
                    </Grid>
                  )}
                  {!isReadOnly && (
                    <Grid item xs={12}>
                      <UploadFileButton
                        accept="image/*,application/pdf"
                        onUpload={(fileList) => {
                          onUploadThumbnail(fileList).then(() => {
                            setHasUploadedFile(true);
                            form.mutators.validate();
                          });
                        }}
                        name="quick_upload"
                        startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                        fullWidth
                      >
                        {isSpanish ? "Subir Archivo" : "Upload File"}
                      </UploadFileButton>
                    </Grid>
                  )}

                  {values.status === "pending" && !isReadOnly && (
                    <Grid item xs={12}>
                      <FormActions.DeleteButton
                        fullWidth
                        type="button"
                        onClick={() => {
                          setDeleteConfirmationDialogIsOpen(true);
                        }}
                      >
                        {isSpanish ? "Eliminar Certificación" : "Delete Certification"}
                      </FormActions.DeleteButton>
                    </Grid>
                  )}
                  {!isReadOnly && (
                    <>
                      {/* <Grid item xs={12}>
                        <FormActions.SaveAndEditButton
                          label={isSpanish ? "Guardar Progreso y Cerrar" : "Save Progress and Close"}
                          fullWidth
                          type="button"
                          // color="secondary"
                          onClick={() => {
                            form.pauseValidation();
                            values.submitMode = "save_progress";
                            handleSubmit();
                            form.resumeValidation();
                          }}
                        />
                      </Grid> */}

                      {submitFailed && hasValidationErrors && !includesThumbnail && (
                        <Grid item xs={12}>
                          <FormLabel error={true}>{errors.thumbnail_was_uploaded}</FormLabel>{" "}
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <FormActions.SaveButton
                          disabled={submitting || isReadOnly}
                          fullWidth
                          label={isSpanish ? "Enviar Certificación" : "Submit Certification"}
                          type="button"
                          onClick={() => {
                            if (hasValidationErrors) {
                              form.submit();
                            } else {
                              setSubmitConfirmationDialogIsOpen(true);
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </FinalFormKeyboardShortcuts>
            </form>
            <ConfirmationDialog
              title={isSpanish ? "Enviar Certificación" : "Submit Certification"}
              isOpen={submitConfirmationDialogIsOpen}
              onApprove={() => {
                handleSubmit();
                setSubmitConfirmationDialogIsOpen(false);
              }}
              onDeny={() => setSubmitConfirmationDialogIsOpen(false)}
            >
              {isSpanish ? (
                <>
                  Deseas enviar esta certificación a NOVO para su revisión. Al enviar, confirmas que:
                  <ul>
                    <li>El tipo de certificación es correcto</li>
                    {values.expiration_date && (
                      <li>
                        La fecha de vencimiento es correcta (
                        {Math.ceil(moment.duration(moment(values.expiration_date).diff(moment())).asDays())} días a
                        partir de ahora).
                      </li>
                    )}
                    <li>Has incluido una copia legible de la documentación relevante de la certificación</li>
                    <li>
                      Los documentos de certificación se emiten a <strong>{values.twilio_contact.name}</strong>.
                    </li>
                    {values.replaces_certification_uuid && (
                      <li>
                        Has indicado que esta certificación reemplaza a otra certificación. La certificación anterior
                        será marcada como expirada inmediatamente después de la presentación de esta certificación.
                      </li>
                    )}
                  </ul>
                </>
              ) : (
                <>
                  You want to submit this certification to NOVO for review. By submitting, you are confirming that:
                  <ul>
                    <li>The certification type is correct</li>
                    {values.expiration_date && (
                      <>
                        <li>
                          The expiration date is correct (
                          {Math.ceil(moment.duration(moment(values.expiration_date).diff(moment())).asDays())} days from
                          now).
                        </li>
                      </>
                    )}
                    <li>You have included a legible copy of the relevant certification paperwork</li>
                    <li>
                      The certification documents are issued to <strong>{values.twilio_contact.name}</strong>.
                    </li>
                    {values.replaces_certification_uuid && (
                      <li>
                        You have indicated that this certification replaces another certification. The previous
                        certification will be marked expired immediately upon submission of this certification.
                      </li>
                    )}
                  </ul>
                </>
              )}
            </ConfirmationDialog>
            <ConfirmationDialog
              title={isSpanish ? "Eliminar Certificación" : "Delete Certification"}
              isOpen={deleteConfirmationDialogIsOpen}
              onApprove={() => {
                setDeleteConfirmationDialogIsOpen(false);
                onDelete();
              }}
              onDeny={() => setDeleteConfirmationDialogIsOpen(false)}
            >
              {isSpanish ? (
                <>¿Estás seguro de que deseas eliminar esta certificación? Esta acción no se puede deshacer.</>
              ) : (
                <>Are you sure you want to delete this certification? This action cannot be undone.</>
              )}
            </ConfirmationDialog>
          </>
        );
      }}
    </FinalForm>
  );
};

const Thumbnail = (props) => {
  const {thumbnail, height = 100, width = 100, onClickThumbnail, ...rest} = props;
  return (
    <Box onClick={onClickThumbnail} style={{cursor: "pointer"}}>
      <Box mb={-1}>
        <img
          src={`data:image/png;base64, ${thumbnail.thumbnail_base64}`}
          height={height}
          width={width}
          style={{objectFit: "contain"}}
        />
      </Box>
    </Box>
  );
};
