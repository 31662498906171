import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Checkbox, Grid, Link, MenuItem, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {Alert, AlertTitle} from "@material-ui/lab";
import {groupBy, map, omit} from "lodash";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import ReactGridV2 from "../../../components/ReactGrid/ReactGridV2";
import {StatusCellTemplate} from "../../../components/ReactGrid/StatusCellTemplate";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {SubmittalItemUpdateDialogForm} from "../Submittals/SubmittalItemForms";

const ProcurementLog = (props) => {
  const {project, userContact} = props;
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [initialFocusField, setInitialFocusField] = React.useState(null);
  const [initialFetched, setInitialFetched] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(0);
  const [activeItem, setActiveItem] = React.useState({});
  const [updateDialogIsOpen, setUpdateDialogIsOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(500);
  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Hidden",
    "Status",
    "Search",
    "Ordering",
  ]);

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
    ordering: filterOptions.Ordering.value,
  };

  const {
    query: submittalItemQuery,
    update: submittalItemUpdate,
    rpc: submittalItemRPC,
    create: createSubmittalItem,
  } = useSentinelListAPI(
    `projects/${project.id}/submittals/pl-items/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {results: []},
      // disableOnSuccess: true,
      // disableOnMutate: true,
      keepPreviousData: true,
    }
  );
  const submittalItems = submittalItemQuery.data.results;

  React.useEffect(() => {
    if (!initialFetched && submittalItemQuery.isFetchedAfterMount) {
      setInitialFetched(true);
    }
  }, [submittalItemQuery.isFetchedAfterMount]);

  const {query: statusQuery} = useSentinelListAPI(`projects/${project.id}/submittals/items/status`, {
    initialData: [],
  });

  const statusOptions = statusQuery.data;

  const DATE_COL_WIDTH = 110;
  const dateFormatter = new Intl.DateTimeFormat("en-US", {year: "numeric", month: "2-digit", day: "2-digit"});

  const allCells = [
    {
      noHide: true,
      headerLabel: "Edit",
      columnId: "edit",
      width: 40,
      buildCell: (item) => {
        return {
          type: "text",
          text: "",
          renderer: (text) => (
            <Box justifyContent="center" display="flex" width="100%">
              <EditIcon
                onClick={() => {
                  setActiveItem(item);
                  setUpdateDialogIsOpen(true);
                }}
                style={{cursor: "pointer"}}
                fontSize="small"
              />
            </Box>
          ),
        };
      },
    },
    {
      noHide: true,
      headerLabel: "Package",
      columnId: "submittal_package.display",
      resizable: true,
      width: 180,
      buildCell: (item) => {
        return {
          nonEditable: true,
          type: "text",
          text: item?.submittal_package?.display ?? "",
          renderer: (text) =>
            item?.submittal_package ? (
              <Link
                href={`/projects/${project.id}/submittals/packages/${item?.submittal_package?.id}/`}
                target="_blank"
                underline="always"
              >
                {text}
              </Link>
            ) : (
              text
            ),
        };
      },
    },

    {
      noHide: true,
      headerLabel: "Description",
      columnId: "description",
      resizable: true,
      width: 500,
      buildCell: (item) => {
        return {
          type: "text",
          text: item.description,
          // validator: (text) => Boolean(text) && !moment(text).isValid(),
        };
      },
    },
    {
      // noHide: true,
      headerLabel: "#",
      headerTooltip: "Number In Project",
      columnId: "number_display",
      resizable: true,
      width: 25,

      buildCell: (item) => {
        return {
          type: "text",
          text: item.number_display,
          nonEditable: true,

          renderer: (text) => (
            <Link href={item?.url} target="_blank" underline="always">
              {text}
            </Link>
          ),
        };
      },
    },
    {
      // noHide: true,
      headerLabel: "# in Package",
      headerTooltip: "Number In Package",
      columnId: "item_number_display",
      resizable: true,
      width: 25,

      buildCell: (item) => {
        return {
          type: "text",
          text: item?.item_number_display ?? "",
          nonEditable: true,

          renderer: (text) => (
            <Link href={`${item?.submittal_package?.url}#tab=items`} target="_blank" underline="always">
              {text}
            </Link>
          ),
        };
      },
    },
    {
      headerLabel: "Spec Section",
      columnId: "spec_section_code",
      resizable: true,
      width: 80,
      buildCell: (item) => {
        return {
          type: "text",
          text: item.spec_section_code,
          cellPropertyType: "item",
          // validator: (data) => /^[0-9]{5}$/.test(data),
        };
      },
    },
    {
      headerLabel: "Status",
      columnId: "status",
      resizable: true,
      width: 50,
      buildCell: (item) => {
        return {
          type: "status",
          status: item.status,
          nonEditable: true,
          cellPropertyType: "item",
          statusType: "submittal",
        };
      },
    },

    {
      headerLabel: "Req From Sub",
      columnId: "requested_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          date: item?.requested_date ? moment(item?.requested_date).toDate() : null,
          format: dateFormatter,
        };
      },
    },
    {
      headerLabel: "Rec From Sub",
      columnId: "received_date",
      resizable: true,
      width: DATE_COL_WIDTH,

      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          format: dateFormatter,
          date: item?.received_date ? moment(item?.received_date).toDate() : null,
        };
      },
    },
    {
      headerLabel: "Pkg Sent To Arch",
      columnId: "submittal_package.submitted_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          date: item?.submittal_package?.submitted_date
            ? moment(item?.submittal_package?.submitted_date).toDate()
            : null,
          nonEditable: Boolean(!item?.submittal_package),
          format: dateFormatter,
        };
      },
    },
    {
      headerLabel: "Pkg Due From Arch",
      columnId: "submittal_package.due_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          type: "date",
          date: item?.submittal_package?.due_date ? moment(item?.submittal_package?.due_date).toDate() : null,
          nonEditable: Boolean(!item?.submittal_package),
          format: dateFormatter,
        };
      },
    },
    {
      headerLabel: "Pkg Rec'd From Arch",
      columnId: "submittal_package.returned_date",
      resizable: true,
      width: 102,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          date: item?.submittal_package?.returned_date ? moment(item?.submittal_package?.returned_date).toDate() : null,
          nonEditable: Boolean(!item?.submittal_package),
          format: dateFormatter,
        };
      },
    },
    {
      headerLabel: "DIR",
      headerTooltip: "Days in Review",
      columnId: "submittal_package.business_days_in_review",
      resizable: true,
      width: 8,
      buildCell: (item) => {
        return {
          type: "number",
          nonEditable: true,
          value: item?.submittal_package?.business_days_in_review ?? null,
        };
      },
    },
    {
      headerLabel: "Sent To Sub",
      columnId: "returned_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          date: item?.returned_date ? moment(item?.returned_date).toDate() : null,
          format: dateFormatter,
        };
      },
    },
    {
      headerLabel: "Lead Time (wks)",
      headerTooltip: "Lead Time (wks)",
      columnId: "lead_time",
      resizable: true,
      width: 8,
      buildCell: (item) => {
        return {
          type: "number",
          value: item?.lead_time,
          cellPropertyType: "item",
        };
      },
    },
    {
      headerLabel: "Sub",
      columnId: "responsible_company.name",
      resizable: true,
      width: 300,
      buildCell: (item) => {
        return {
          type: "text",
          nonEditable: true,
          text: item?.responsible_company?.name ?? "",
        };
      },
    },
    {
      headerLabel: "Needed Onsite",
      columnId: "needed_onsite_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          date: item?.needed_onsite_date ? moment(item?.needed_onsite_date).toDate() : null,
          format: dateFormatter,
        };
      },
    },

    {
      headerLabel: "Targ Delivery",
      columnId: "target_delivery_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          nonEditable: true,
          date: item?.target_delivery_date ? moment(item?.target_delivery_date).toDate() : null,
          format: dateFormatter,
        };
      },
    },

    {
      headerLabel: "Sched Delivery",
      columnId: "scheduled_delivery_date",
      resizable: true,
      width: DATE_COL_WIDTH,
      buildCell: (item) => {
        return {
          // type: "customDate",
          type: "date",
          date: item?.scheduled_delivery_date ? moment(item?.scheduled_delivery_date).toDate() : null,
          format: dateFormatter,
          // validator: (data) => {
          //   console.log("Checking", data);
          //   return moment(data).isValid();
          // },
        };
      },
    },
  ];

  // const hideableColumns = [
  //    {label: "Description", value: "description"},
  //   {label: "Status", value: "status"},
  // ];

  const hideableColumns = allCells
    .filter((cell) => !cell?.noHide)
    .map((cell) => ({label: cell.headerLabel, value: cell.columnId}))
    .sort((a, b) => a.label.localeCompare(b.label));

  const cellData = React.useMemo(
    () => allCells.filter((item) => !filterOptions.Hidden.value.includes(item.columnId)),
    [filterOptions.Hidden.value, openDropdown]
  );

  const getFilterValues = (value): [newValue: {}[], newLabel: string] => {
    const selected = value[0];
    const prevValue = filterOptions.Hidden.value || [];
    let newValue = [];

    if (prevValue.includes(selected)) {
      newValue = [...prevValue].filter((hidden) => hidden !== selected);
    } else {
      newValue = [...prevValue, selected];
    }
    const newLabel = newValue.map((value) => hideableColumns.find((option) => option.value === value).label).join(", ");
    return [newValue, newLabel];
  };

  const groupBeforeSave = (input) => {
    const groupedData = groupBy(input, (obj) => omit(obj, "id"));

    const output = map(groupedData, (group) => {
      const ids = map(group, "id");
      const update = omit(group[0], "id");
      return {ids, update};
    });

    return output;
  };

  const saveChanges = (newData) => {
    if (newData[0]?.submittal_package || newData.length > 1) {
      blockUI.blockUI("Saving...");
      if (newData.length > 1) {
        Promise.all(
          groupBeforeSave(newData).map((change) =>
            submittalItemRPC.mutateAsync({action: "update", data: change, method: "PUT"})
          )
        ).then(() => submittalItemQuery.refetch().then(() => blockUI.unblockUI()));
      } else {
        submittalItemUpdate
          .mutateAsync(newData[0])
          .then(() => submittalItemQuery.refetch().then(() => blockUI.unblockUI()));
      }
    } else {
      submittalItemUpdate.mutateAsync(newData[0]);
    }
  };

  if (!initialFetched) {
    return <BlockUI show={true} message="Loading..." />;
  }

  return (
    <>
      <Helmet title="Procurement Log" />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Procurement Log</Typography>
      </ProjectBreadcrumbs>
      <Box mt={3} mb={2}>
        <Alert severity="info">
          <AlertTitle>Welcome to the New Procurement Log</AlertTitle>We value your feedback, if you have an idea to make
          this page even better please click the Feedback button at the bottom of your screen. In the meantime, if you
          need to get to the old procurement log, click{" "}
          <Link href={`/projects/${project.id}/submittals/procurement-log/`} underline="always">
            here
          </Link>{" "}
          to go to the legacy page.
        </Alert>
      </Box>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Procurement Log ({submittalItemQuery.data.count})</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                Create Submittal Item
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFDropdownButton>
                {(setOpen) => (
                  <>
                    <MenuItem
                      component={Link}
                      target="_blank"
                      href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=simplified`}
                      onClick={() => setOpen(false)}
                    >
                      Simple
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      target="_blank"
                      href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=basic`}
                      onClick={() => setOpen(false)}
                    >
                      Basic
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      target="_blank"
                      href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=basic`}
                      onClick={() => setOpen(false)}
                    >
                      Detailed
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      target="_blank"
                      href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=basic`}
                      onClick={() => setOpen(false)}
                    >
                      Legacy
                    </MenuItem>
                  </>
                )}
              </PaperPanel.Header.PDFDropdownButton>
              <Box mx={1}>
                <PaperPanel.Header.BuildReportButton
                  href={`/reports2/projects/${project.id}/submittals/procurement-log/filter/`}
                />
              </Box>

              <PaperPanel.Header.Button
                href={`/reports2/projects/${project.id}/submittals/procurement-log/?export_type=excel`}
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
              >
                Get Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => submittalItemQuery.refetch()}
                isFetching={submittalItemQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusOptions}
                value={filterOptions.Status.value}
                showStatusIcon
                statusType="submittal"
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                options={hideableColumns}
                label="Visible Columns"
                name="hidden"
                onChange={(value, label) => {
                  const [newValue, newLabel] = getFilterValues(value);
                  if (newValue.length < 1) {
                    clearFilterOption("Hidden");
                  } else {
                    setFilterOption("Hidden", newValue, newLabel);
                  }
                }}
                multiple
                renderOption={(option) => (
                  <>
                    <Checkbox checked={!filterOptions.Hidden.value.includes(option.value)} size="small" />{" "}
                    {option.label}
                  </>
                )}
                allowNull={false}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                options={[
                  {label: "Description", value: "description"},
                  {label: "Number", value: "number"},
                  {label: "Package Number", value: "submittal_package__number"},
                ]}
                label="Ordering"
                name="ordering"
                value={filterOptions.Ordering.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Ordering", value, label);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  setPage(1);
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  setPage(1);
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box display={"flex"} justifyContent="flex-end">
                <Pagination count={submittalItemQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <Box style={{overflow: "scroll"}}>
            <ReactGridV2
              customCellTemplates={{
                status: new StatusCellTemplate(),
              }}
              enableRangeSelection
              enableFillHandle
              onChange={saveChanges}
              stickyLeftColumns={3}
              stickyTopRows={1}
              cellData={cellData}
              data={submittalItems}
            />
          </Box>
        </PaperPanel.Body>
        {(submittalItemQuery.data.total_pages > 1 || pageSize > 250) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={submittalItemQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/submittals/procurement-log/`} />
      <SubmittalItemUpdateDialogForm
        projectId={project.id}
        isOpen={updateDialogIsOpen}
        handleClose={() => setUpdateDialogIsOpen(false)}
        initialValues={activeItem}
        initialFocusField={initialFocusField}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          submittalItemUpdate.mutateAsync(values).then(() => {
            submittalItemQuery.refetch().then(() => {
              blockUI.unblockUI();
              setUpdateDialogIsOpen(false);
            });
          });
        }}
      />

      <SubmittalItemUpdateDialogForm
        projectId={project.id}
        isOpen={showCreateDialog}
        isNew
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={{
          number: submittalItemQuery?.data?.metadata?.next_number,
          date_created: moment().format("YYYY-MM-DD"),
          authored_by: userContact,
          quantity: 1,
          revision: 0,
        }}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;
          if (submitMode === "addAnother") {
            setInitialFocusField(null);
          }
          if (submitMode === "saveAndClose") {
            blockUI.blockUI("Creating...");

            createSubmittalItem.mutateAsync(values).then(() => {
              submittalItemQuery.refetch().then(() => {
                blockUI.unblockUI();
                setShowCreateDialog(false);
              });
            });
          }
          if (submitMode === "addAnother") {
            blockUI.blockUI("Creating...");

            createSubmittalItem.mutateAsync(values).then(() => {
              submittalItemQuery.refetch().then(() => {
                form.restart();
                setInitialFocusField("description");
                blockUI.unblockUI();
              });
            });
          }
        }}
        initialFocusField={initialFocusField}
      />
    </>
  );
};

export default ProcurementLog;
