import {blue, green, grey, red, yellow} from "@material-ui/core/colors";

export const colorSuccess = green[500];
export const colorSuccess2 = green[400];
export const colorInfo = blue[500];
export const colorInfo2 = blue[600];
export const colorWarning = yellow[700];
export const colorWarning2 = yellow[800];
export const colorError2 = red[400];
export const colorError = red[500];

export const colorMutedText = grey[400];
export const colorNovo = "#ffbf3f";

export const getStatusColor = (status: string, hint?: string) => {
  status = status ? status.toLowerCase() : "";
  let color: any = grey[500];
  if (
    [
      "approved",

      "complete",
      "completed",
      "in progress",
      "review complete",
      "reviewed",
      "submitted",
      "compliant",
      "finalized",
      "executed",
      "won",
      "active",
    ].includes(status)
  ) {
    color = colorSuccess;
  }
  if (["approved as noted", "approved by management"].includes(status)) {
    color = colorSuccess2;
  }
  if (
    [
      "pending",
      "pending approval",
      "pending - work on hold",
      "pending work on hold",
      "pending - work proceeding",
      "pending work proceeding",
      "pending - approved to proceed",
      "pending approved to proceed",
      "sent",
      "on hold",
    ].includes(status)
  ) {
    color = colorInfo;
  }
  if (
    [
      "created",
      "external response",
      "received external response",
      "revised",
      "noncompliant - corrected on site",
      "noncompliant - corrected",
      "caution",
    ].includes(status)
  ) {
    color = colorWarning;
  }
  if (["partial response", "in review"].includes(status)) {
    color = colorWarning2;
  }
  if (
    ["closed", "rejected", "declined", "deleted", "noncompliant - needs attention", "critical", "lost"].includes(status)
  ) {
    color = colorError;
  }
  if (["revise & resubmit", "revise resubmit"].includes(status)) {
    color = colorError2;
  }
  if (["submitted"].includes(status) && (hint === "submittalPackage" || hint === "submittal")) {
    color = colorInfo2;
  }

  if (["complete"].includes(status) && (hint === "projectSafetyReview" || hint === "projectSafetyForm")) {
    color = colorWarning;
  }
  if (["submitted"].includes(status) && hint == "projectSafetyReview") {
    color = green[200];
  }

  if (["submitted"].includes(status) && hint == "pretaskPlan") {
    color = colorSuccess2;
  }
  if (
    ["submitted"].includes(status) &&
    ["bonusAccountCloseoutRequest", "bonusAccountDistributionRequest"].includes(hint)
  ) {
    color = colorInfo;
  }
  if (["approved"].includes(status) && hint === "pcco") {
    color = colorSuccess2;
  }

  if (["new"].includes(status) && ["projectReview"].includes(hint)) {
    color = colorInfo;
  }

  if (["awaiting response"].includes(status)) {
    color = colorWarning;
  }

  if (["in compliance"].includes(status)) {
    color = colorSuccess;
  }

  if (["verified"].includes(status)) {
    color = colorSuccess;
  }

  if (["submitted"].includes(status) && hint === "twilioContactCertification") {
    color = colorWarning;
  }

  if (["expired", "revise_resubmit"].includes(status) && hint === "twilioContactCertification") {
    color = colorError;
  }

  return color;
};

export const getImportanceColor = (importance) => {
  importance = importance.toLowerCase();
  switch (importance) {
    case "low":
    case "routine":
    case "routine repeat":
      return colorSuccess;
    case "normal":
    case "medium":
    case "repeat":
      return colorInfo;
    case "high":
      return colorWarning;
    case "urgent":
    case "significant":
    case "significant repeat":
      return colorError;
    default:
      return "";
  }
};
