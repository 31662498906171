import {Link, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {RFPOnboardingForm} from "./RFPForms";

const RequestForPricingDetail = (props) => {
  const {tagOptions = [], isFetching, ...rest} = props;
  const {rfpId} = useParams();
  const blockUI = useBlockUI();
  const {
    query: rfpQuery,
    create: createRFP,
    update: updateRFP,
    delete: deleteRFP,
  } = useSentinelDetailAPI(`marketing/rfps/${rfpId}/`, {
    initialData: {},
  });

  // const pageDataQuery = useFetchCurrentPage({
  //   refetchOnWindowFocus: false,
  //   initialData: {},
  // });
  // const pageData = pageDataQuery.data;

  const rfp = rfpQuery.data;

  if (!rfpQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message={`Fetching RFP Details...`} />;
  }

  return (
    <>
      <Helmet title="Requests For Proposal" />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        {/* </Link> */}
        <Link href="/v2/marketing/rfps/">
          <Typography color="textSecondary">Requests For Proposal</Typography>
        </Link>
        <Typography color="textPrimary">{rfp.display}</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Request For Proposal - {rfp.display}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.BoxFilesButton href={`/v2/box/files/marketing/rfp/${rfpId}/`} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <RFPOnboardingForm
            onSubmit={(values) => {
              blockUI.blockUI("Saving...");
              updateRFP.mutateAsync(values).then(() => blockUI.unblockUI());
            }}
            initialValues={rfp}
            tagOptions={tagOptions.map((tag) => tag.label)}
            isFetching={isFetching}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default RequestForPricingDetail;
