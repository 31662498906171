import {Dialog, DialogTitle, DialogContent, DialogActions, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {DatePickerMui, SimpleSelect, TextFieldMui, TwilioContactAutoselectMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import certificationTypeChoices from "../../../components/forms/choices/twilioContactCertificationTypes.json";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {ConfirmationDialog} from "../../../components/Dialogs";
import React from "react";

export const CreateTwilioContactCertificationDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  projectId: number;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, projectId, ...rest} = props;

  const [submitConfirmationDialogIsOpen, setSubmitConfirmationDialogIsOpen] = React.useState(false);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => {
            const {query: certificationQuery} = useSentinelListAPI(
              `projects/${projectId}/safety/certifications/?page_size=10000&valid=true&certification_type=${values?.certification_type}&twilio_contact=${values?.twilio_contact?.id}`,
              {
                initialData: {
                  results: [],
                },
                enabled: Boolean(values.certification_type) && Boolean(values.twilio_contact),
              }
            );
            const currentCertifications = certificationQuery.data.results || [];

            const replacesCertificationChoices = currentCertifications
              .filter((certification) => certification.uuid !== values.uuid)
              .map((certification) => ({
                value: certification.uuid,
                label: `${certification.display_with_expiration}`,
              }));

            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Create Certification</DialogTitle>
                <DialogContent>
                  {/* <BeforeUnload block={!pristine} /> */}
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SimpleSelect
                          name="certification_type"
                          label="Certification Type"
                          options={certificationTypeChoices}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TwilioContactAutoselectMui
                          name="twilio_contact"
                          label="Tradesman Contact"
                          required
                          projectId={projectId}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <DatePickerMui name="issue_date" label="Issue Date" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui
                          name="expiration_date"
                          label="Expiration Date"
                          disablePast
                          helperText="Leave blank if this certification does not expire"
                        />
                      </Grid>

                      {replacesCertificationChoices.length > 0 && (
                        <Grid item xs={12}>
                          <SimpleSelect
                            name="replaces_certification_uuid"
                            label={"Replaces Certification"}
                            options={replacesCertificationChoices}
                            // disabled={isReadOnly}
                            helperText={"Select the certification that this one replaces (Optional)"}
                          />
                        </Grid>
                      )}

                      {/* <Grid item xs={12}>
                        <TextFieldMui name="notes" label="Notes" multiline rows={4} />
                      </Grid> */}
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton
                    disabled={submitting || (pristine && !isNew)}
                    type="button"
                    onClick={() => {
                      if (values.replaces_certification_uuid && !hasValidationErrors) {
                        setSubmitConfirmationDialogIsOpen(true);
                      } else {
                        handleSubmit();
                      }
                    }}
                  />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
                <ConfirmationDialog
                  title={"Submit Certification"}
                  isOpen={submitConfirmationDialogIsOpen}
                  onApprove={() => {
                    handleSubmit();
                    setSubmitConfirmationDialogIsOpen(false);
                  }}
                  onDeny={() => setSubmitConfirmationDialogIsOpen(false)}
                >
                  You have indicated that this certification replaces another certification. The previous certification
                  will be marked expired immediately upon creation of this new certification.
                </ConfirmationDialog>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
