import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import qs from "query-string";
import {Helmet} from "react-helmet";
import {VictoryContainer, VictoryPie, VictoryTheme} from "victory";
import {Currency, Percentage} from "../../components/Accounting";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterAutocomplete, FilterSearch, FilterSelect} from "../../components/Filters";
import officeChoices from "../../components/forms/choices/offices.json";
import {LegacyUILink, MuiNavLink} from "../../components/Links";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {saveExcel} from "../../utils/excel";

const SubcontractorRevenue = (props) => {
  const requiredFilters = ["Year"];
  const novoClasses = makeNovoClasses();
  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Year", "Cost Code", "Office", "Novo Contact", "Vendor Name", "Vendor Id"],
    {
      Year: {
        value: moment().year(),
        label: moment().year().toString(),
      },
    }
  );

  const subcontractorFilterParams = {
    year: filterOptions.Year.value,
    office: filterOptions.Office.value,
    vendor_name: filterOptions["Vendor Name"].value,
    vendor_id: filterOptions["Vendor Id"].value,
    cost_code: filterOptions["Cost Code"].value,
    contact_id: filterOptions["Novo Contact"].value,
  };

  const contactFilterParams = {
    company_id: 1547,
    page_size: 1000,
  };

  const {query: contactQuery} = useSentinelListAPI(`contacts/?${qs.stringify(contactFilterParams)}`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const {query: subcontractorQuery} = useSentinelListAPI(
    `data/subcontractors/revenue/?${qs.stringify(subcontractorFilterParams)}`,
    {
      initialData: [],
      keepPreviousData: true,
    }
  );

  const {query: subcontractorDetailQuery} = useSentinelDetailAPI(
    `data/subcontractors/revenue/${filterOptions["Vendor Id"].value}/?${qs.stringify(subcontractorFilterParams)}`,
    {
      initialData: {
        by_pm: [],
        by_px: [],
      },
      enabled: Boolean(filterOptions["Vendor Id"].value),
    }
  );

  const subcontractorDetailItems = subcontractorDetailQuery.data;

  const pmPieData = subcontractorDetailItems.by_pm.length
    ? subcontractorDetailItems.by_pm
        .sort((a, b) => b.amount - a.amount)
        .map((item) => {
          return {
            x: item.percent >= 8 ? item.project_manager : " ",
            y: item.amount,
          };
        })
    : [{x: "Loading...", y: 1}];

  const pxPieData = subcontractorDetailItems.by_px.length
    ? subcontractorDetailItems.by_px
        .sort((a, b) => b.amount - a.amount)
        .map((item) => {
          return {
            x: item.percent >= 8 ? item.project_executive : " ",
            y: item.amount,
          };
        })
    : [{x: "Loading...", y: 1}];

  const yearChoices = Array.from({length: moment().year() - 2010 + 1}, (_, index) => ({
    label: (2010 + index).toString(),
    value: 2010 + index,
  })).reverse();

  const commitmentItems = subcontractorQuery.data;
  const contactChoices = contactQuery.data.results;

  return (
    <>
      <Helmet title={`Subcontractor Revenue`} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" href="/v2/">
          Dashboard
        </MuiNavLink>

        <Typography color="textPrimary">Subcontractor Revenue</Typography>
      </Breadcrumbs>
      {/* <pre>{JSON.stringify(pmPieData, null, 2)}</pre> */}
      <PaperPanel>
        <PaperPanel.Header
          isLoading={
            subcontractorQuery.isFetching || (subcontractorDetailQuery.isFetched && subcontractorDetailQuery.isFetching)
          }
        >
          <PaperPanel.Header.Title>
            Subcontractor Revenue{" "}
            {filterOptions["Vendor Id"].value &&
              ` - ${commitmentItems.find((item) => item.vendor_id === filterOptions["Vendor Id"].value)?.vendor}`}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  subcontractorDetailQuery.isFetched && subcontractorDetailQuery.refetch();
                  subcontractorQuery.refetch();
                }}
                isFetching={
                  subcontractorQuery.isFetching ||
                  (subcontractorDetailQuery.isFetched && subcontractorDetailQuery.isFetching)
                }
              />
              <Box mr={-1} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Year"
                name="year"
                options={yearChoices}
                value={filterOptions.Year.value}
                onChange={(value, label) => {
                  //   setPage(1);
                  setFilterOption("Year", value, label);
                }}
                allowNull={false}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSelect
                label="Office"
                name="office"
                options={officeChoices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  //   setPage(1);
                  setFilterOption("Office", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSearch
                label="Cost Code"
                value={filterOptions["Cost Code"].value}
                name="Cost Code"
                onChange={(value) => {
                  //   setPage(1);
                  setFilterOption("Cost Code", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterAutocomplete
                label="Novo Contact"
                name="Novo Contact"
                options={contactChoices.map((contact) => ({
                  value: contact.id,
                  label: contact.full_name,
                  email: contact.email,
                }))}
                value={filterOptions["Novo Contact"].value}
                onChange={(value, label) => {
                  setFilterOption("Novo Contact", value, label);
                }}
                getOptionLabel={(option) =>
                  option?.full_name ?? contactChoices.find((choice) => choice.id === option)?.full_name ?? option.label
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterSearch
                label="Vendor Name"
                value={filterOptions["Vendor Name"].value}
                name="Vendor Name"
                onChange={(value) => {
                  //   setPage(1);
                  setFilterOption("Vendor Name", value, value);
                  //   clearFilterOption("Vendor Id");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  //   setPage(1);
                  if (!requiredFilters.includes(key)) clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  //   setPage(1);
                  //   clearAllFilterOptions();
                  Object.keys(filterOptions).map((key) => {
                    if (!requiredFilters.includes(key)) clearFilterOption(key);
                  });
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          {filterOptions["Vendor Id"].value && (
            <>
              <Box display="flex" flexWrap="noWrap" justifyContent="space-evenly">
                <Box width={500} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <VictoryPie
                    // animate={{
                    //   duration: 1000,
                    // }}
                    theme={VictoryTheme.material}
                    labelRadius={75}
                    style={{
                      labels: {
                        fontSize: 10,
                        fill: "black",
                      },
                    }}
                    containerComponent={<VictoryContainer title={`By Project Executive`} responsive={true} />}
                    data={pxPieData}
                  />
                  <Box textAlign="center" mt={-8} mb={1}>
                    <Typography variant="h5">By Project Executive</Typography>
                  </Box>
                </Box>

                <Box width={500} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <VictoryPie
                    // animate={{
                    //   duration: 1000,
                    // }}
                    theme={VictoryTheme.material}
                    labelRadius={75}
                    style={{
                      labels: {
                        fontSize: 10,
                        fill: "black",
                      },
                    }}
                    containerComponent={<VictoryContainer title={`By Project Manager`} responsive={true} />}
                    data={pmPieData}
                  />
                  <Box textAlign="center" mt={-8} mb={1}>
                    <Typography variant="h5">By Project Manager</Typography>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={1} />
      <PaperPanel>
        <PaperPanel.Header isLoading={subcontractorQuery.isFetching}>
          <PaperPanel.Header.Title>Commitment Items</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={subcontractorQuery.isFetching}
                onClick={(event) => {
                  saveExcel(commitmentItems, `Subcontractor Revenue - ${filterOptions.Year.value}`, [
                    {wch: 10},
                    {wch: 10},
                    {wch: 10},
                    {wch: 30},
                    {wch: 10},
                    {wch: 15},
                  ]);
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => subcontractorQuery.refetch()}
                isFetching={subcontractorQuery.isFetching}
              />
              <Box mr={-1} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["amount"]}
            columns={[
              {
                Header: "Year",
                accessor: "year",
              },
              {
                Header: "Code",
                accessor: "cost_code_prefix",
              },
              {
                Header: "Vendor",
                accessor: "vendor",
                Cell: ({value, row}) => (
                  <Link
                    underline="always"
                    onClick={() => setFilterOption("Vendor Id", row.original.vendor_id, row.original.vendor_id)}
                    style={{cursor: "pointer"}}
                  >
                    {value}
                  </Link>
                ),
              },
              {Header: "Amount", accessor: "amount", Cell: ({value, row}) => <Currency number={value} />},
            ]}
            initialState={{
              sortBy: [
                {
                  id: "amount",
                  desc: true,
                },
              ],
            }}
            data={commitmentItems}
          />
        </PaperPanel.Body>
      </PaperPanel>
      {filterOptions["Vendor Id"].value && (
        <>
          <Box mt={1} />

          <PaperPanel>
            <PaperPanel.Header isLoading={subcontractorDetailQuery.isFetching}>
              <PaperPanel.Header.Title>
                Subcontractor Breakout -{" "}
                {commitmentItems.find((item) => item.vendor_id === filterOptions["Vendor Id"].value)?.vendor}
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => subcontractorDetailQuery.refetch()}
                    isFetching={subcontractorDetailQuery.isFetching}
                  />
                  <Box mr={-1} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <PaperItem.HeaderLabel label="By PX" labelVariant="h6" />
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["amount", "percentage"]}
                columns={[
                  {
                    Header: "Year",
                    accessor: "year",
                  },
                  {
                    Header: "Name",
                    accessor: "project_executive",
                  },

                  {id: "amount", accessor: "amount", Cell: ({value, row}) => <Currency number={value} />},
                  {
                    id: "percentage",
                    accessor: "percent",
                    Cell: ({value, row}) => <Percentage number={value / 100} precision={2} />,
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "percentage",
                      desc: true,
                    },
                  ],
                }}
                data={subcontractorDetailQuery.data.by_px}
              />
              {/* <Box mt={1} /> */}
              <PaperItem.HeaderLabel label="By PM" labelVariant="h6" />
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["amount", "percentage"]}
                columns={[
                  {
                    Header: "Year",
                    accessor: "year",
                  },
                  {
                    Header: "Name",
                    accessor: "project_manager",
                  },

                  {id: "amount", accessor: "amount", Cell: ({value, row}) => <Currency number={value} />},
                  {
                    id: "percentage",
                    accessor: "percent",
                    Cell: ({value, row}) => <Percentage number={value / 100} precision={2} />,
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "percentage",
                      desc: true,
                    },
                  ],
                }}
                data={subcontractorDetailQuery.data.by_pm}
              />
            </PaperPanel.Body>
          </PaperPanel>
          <LegacyUILink href={`/executive-reports/subcontractors/revenue/`} mt={2} />
        </>
      )}
    </>
  );
};

export default SubcontractorRevenue;
