import {AppBar, Box, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSelect, FilterSwitch} from "../../../components/Filters";
import BlockUI from "../../../components/GlobalLoaders";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {TabCountChip} from "../../../components/Tabs";
import statusChoices from "../../../components/forms/choices/safetyReviewQuestionStatusChoices.json";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import usePermissions from "../../../hooks/usePermissions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {PSRTable} from "./SafetyReviewComponents";
import {CreateSafetyReviewDialogForm, RemediationDialogForm} from "./SafetyReviewForms";
import {SafetyReviewQuestionResponsePaperItem} from "./SafetyReviewPaperItems";

const ProjectSafetyReviews = (props) => {
  const {project, ...rest} = props;
  const permissions = usePermissions();
  const [selectedTab, setSelectedTab] = useQueryState("tab", "reviews");
  const [createReviewDialogIsOpen, setCreateReviewDialogIsOpen] = React.useState(false);
  const [showComments, setShowComments] = React.useState(false);
  const [activeQuestion, setActiveQuestion] = React.useState(undefined as any);
  const [remediationDialogIsOpen, setRemediationDialogIsOpen] = React.useState(false);
  const [remediationSaveConfirmationIsOpen, setRemediationSaveConfirmationIsOpen] = React.useState(false);
  const [remediationValues, setRemediationValues] = React.useState({} as FormData);
  const [responsePage, setResponsePage] = React.useState(1);
  const [responsePageSize, setResponsePageSize] = React.useState(100);
  const [responseFilterOptions, setResponseFilterOption, clearResponseFilterOption, clearAllResponseFilterOptions] =
    useFilterOptions(["Status", "Search"], {
      Status: {label: "Noncompliant - Needs Attention", value: "noncompliant_needs_attention"},
    });
  const responseFilterParams = {
    q: responseFilterOptions.Search.value,
    status: responseFilterOptions.Status.value,
  };

  const [reviewPage, setReviewPage] = React.useState(1);
  const [reviewPageSize, setReviewPageSize] = React.useState(100);
  const [reviewFilterOptions, setReviewFilterOption, clearReviewFilterOption, clearAllReviewFilterOptions] =
    useFilterOptions(["Status", "Search", "Created By"]);
  const reviewFilterParams = {
    q: reviewFilterOptions.Search.value,
    status: reviewFilterOptions.Status.value,
    created_by: reviewFilterOptions["Created By"].value,
  };

  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();

  const {query: safetyReviewQuery, create: createSafetyReview} = useSentinelListAPI(
    `projects/${
      project.id
    }/safety/project-safety-reviews/?page_size=${reviewPageSize}&page=${reviewPage}&${qs.stringify(
      reviewFilterParams
    )}`,
    {
      initialData: {results: []},
    }
  );

  const {query: createdByQuery} = useSentinelListAPI(
    `/projects/${project.id}/safety/project-safety-reviews/created-by/`,
    {initialData: []}
  );

  const createdBy = createdByQuery.data;

  const createdByFilterOptions = createdBy.map((user) => ({label: user.full_name, value: user.id}));

  const {query: responseQuery} = useSentinelListAPI(
    `/projects/${
      project.id
    }/safety/project-safety-reviews/questions/responses/?page_size=${responsePageSize}&page=${responsePage}&${qs.stringify(
      responseFilterParams
    )}`,
    {
      initialData: {results: []},
    }
  );

  const safetyReviews = safetyReviewQuery.data.results;

  const assignedQuestions = responseQuery.data.results;

  const createReviewFormRef = React.createRef() as React.RefObject<HTMLFormElement>;

  const refreshAll = () => Promise.all([safetyReviewQuery.refetch(), responseQuery.refetch()]);

  if (!safetyReviewQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Project Safety Review info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Project Safety Reviews`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Project Safety Reviews</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Reviews
                    <TabCountChip
                      isLoading={safetyReviewQuery.isFetching || !safetyReviewQuery.isFetchedAfterMount}
                      count={safetyReviewQuery.data?.count}
                    />
                  </Box>
                }
                value="reviews"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Responses
                    <TabCountChip isLoading={!responseQuery.isFetchedAfterMount} count={assignedQuestions.length} />
                  </Box>
                }
                value="responses"
                className={novoClasses.smallTab}
              />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="reviews">
            <PaperPanel.TabHeader isLoading={safetyReviewQuery.isFetching}>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  {permissions.can_change_safety_reviews && (
                    <PaperPanel.Header.CreateButton
                      onClick={() => {
                        setCreateReviewDialogIsOpen(true);
                      }}
                    >
                      Create PSR
                    </PaperPanel.Header.CreateButton>
                  )}
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border pr={-2}>
                  <PaperPanel.Header.RefreshButton
                    isFetching={safetyReviewQuery.isFetching || responseQuery.isFetching}
                    onClick={refreshAll}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.TabToolbar p={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FilterSelect
                    label="Status"
                    name="Status"
                    options={[
                      {label: "Pending", value: "pending"},
                      {label: "Complete", value: "complete"},
                    ]}
                    value={reviewFilterOptions["Status"].value}
                    onChange={(value, label) => {
                      setReviewPage(1);
                      setReviewFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterSelect
                    name="Created By"
                    label="Created By"
                    options={createdByFilterOptions}
                    value={reviewFilterOptions["Created By"].value}
                    onChange={(value, label) => {
                      setReviewPage(1);
                      setReviewFilterOption("Created By", value, label);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FilterSwitch
                    name="comments"
                    label="Show Comments"
                    checked={showComments}
                    onChange={(value, label) => {
                      setShowComments((show) => !show);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FilterOptionChips
                    filterOptions={reviewFilterOptions}
                    onDelete={(key) => {
                      clearReviewFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllReviewFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={responseQuery.data.total_pages}
                      page={reviewPage}
                      pageSize={responsePageSize}
                      setPage={setReviewPage}
                      setPageSize={setReviewPageSize}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.TabToolbar>
            <Box mx={-3} mb={1}>
              <PSRTable safetyReviews={safetyReviews} showComments={showComments} />
            </Box>
          </TabPanel>
          <TabPanel value="responses">
            <PaperPanel.TabHeader isLoading={responseQuery.isFetching}>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border pr={-2}>
                  <PaperPanel.Header.RefreshButton isFetching={responseQuery.isFetching} onClick={refreshAll} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.TabToolbar p={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FilterSelect
                    label="Status"
                    name="Status"
                    // options={statusChoices}
                    options={statusChoices.filter((option) =>
                      ["noncompliant_needs_attention", "corrected"].includes(option.value)
                    )}
                    value={responseFilterOptions["Status"].value}
                    onChange={(value, label) => {
                      setResponsePage(1);
                      setResponseFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FilterOptionChips
                    filterOptions={responseFilterOptions}
                    onDelete={(key) => {
                      clearResponseFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllResponseFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={responseQuery.data.total_pages}
                      page={responsePage}
                      pageSize={responsePageSize}
                      setPage={setResponsePage}
                      setPageSize={setResponsePageSize}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.TabToolbar>

            <Box mx={-3} mb={1}>
              {assignedQuestions.map((question) => {
                return (
                  <SafetyReviewQuestionResponsePaperItem
                    key={question.id}
                    question={question}
                    onClick={() => {
                      setActiveQuestion(question);
                      setRemediationDialogIsOpen(true);
                    }}
                  />
                );
              })}
            </Box>
            {responseQuery.data.total_pages < 1 && (
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={responseQuery.data.total_pages}
                  page={responsePage}
                  pageSize={responsePageSize}
                  setPage={setResponsePage}
                  setPageSize={setResponsePageSize}
                />
              </Box>
            )}
          </TabPanel>
        </PaperPanel>
      </TabContext>
      <CreateSafetyReviewDialogForm
        isOpen={createReviewDialogIsOpen}
        handleClose={() => setCreateReviewDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI();
          createSafetyReview.mutateAsync({...values}).then((response) => {
            window.location.href = `/v2/projects/${project.id}/safety/project-safety-reviews/${response.id}/`;
            // blockUI.unblockUI();
          });
        }}
        initialValues={{review_datetime: moment()}}
        ref={createReviewFormRef}
        keepDirtyOnReinitialize
      />

      <RemediationDialogForm
        isOpen={remediationDialogIsOpen}
        handleClose={() => setRemediationDialogIsOpen(false)}
        onSubmit={(values) => {
          setRemediationValues(values);
          setRemediationSaveConfirmationIsOpen(true);
        }}
        initialValues={activeQuestion}
        activeQuestion={activeQuestion}
        readOnly={!activeQuestion?.can_respond}
        projectId={project.id}
      />

      <ConfirmationDialog
        isOpen={remediationSaveConfirmationIsOpen}
        onDeny={() => setRemediationSaveConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");

          axiosAPI
            .post(
              `/projects/${project.id}/safety/project-safety-reviews/${activeQuestion.safety_review}/questions/${activeQuestion.id}/remediate/`,
              remediationValues
            )
            .then(() => {
              setRemediationDialogIsOpen(false);
              refreshAll().then(() => {
                setRemediationSaveConfirmationIsOpen(false);
                blockUI.unblockUI();
              });
            });
        }}
        title="Save Response?"
      >
        Do you want to submit this response? After submission, this review item will be marked compliant and you will
        not be able to make further changes.
      </ConfirmationDialog>
    </>
  );
};

export default ProjectSafetyReviews;
