import {useQuery} from "react-query";
import {usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import getSafetyFormComponentFromType from "../../../utils/getSafetyFormComponentFromType";
import {axiosAPI} from "../../../api";

const JobsiteSafetyForms = (props: {[rest: string]: any}) => {
  const {safetyForm, recentEquipmentCount, certifications, ...rest} = props;
  const postCurrentPage = usePostCurrentPage();
  const operatorQuery = useQuery(
    ["operator query"],
    () => {
      return axiosAPI
        .get(
          `projects/${safetyForm.project.uuid}/twilio-contact/${safetyForm.twilio_contact.uuid}/contact-name-autocomplete/`,
          {baseURL: "/"}
        )
        .then((res) => res.data);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: {results: []},
    }
  );

  const operators = operatorQuery.data.results;

  const operatorOptions = operators.map((operator) => ({
    label: operator,
    value: operator,
  }));

  if (operatorQuery.isFetchedAfterMount && operators.length === 0) {
    operatorOptions.push({label: safetyForm.twilio_contact.name, value: safetyForm.twilio_contact.name});
  }

  const Component = getSafetyFormComponentFromType(safetyForm.form_type);
  return (
    <>
      <Component
        safetyForm={safetyForm}
        recentEquipmentCount={recentEquipmentCount}
        postCurrentPage={postCurrentPage}
        operatorOptions={operatorOptions}
        certifications={certifications}
        certificationsEnabled={safetyForm.certifications_enabled}
      />
    </>
  );
};

export default JobsiteSafetyForms;
